/** @format */

/* eslint-disable no-useless-escape, no-prototype-builtins */
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { LogService } from './log.service';

@Injectable({
	providedIn: 'root'
})
export class CookieService {
	// Check for is Browser
	public documentIsAccessible = false;

	// eslint-disable-next-line no-unused-vars
	constructor(@Inject(DOCUMENT) private document: any, private logService: LogService, @Inject(PLATFORM_ID) protected platformId: any) {
		this.document = document;
		this.documentIsAccessible = isPlatformBrowser(this.platformId);
	}

	/**
	 * @param name - Cookie name
	 * @returns Whether the cookie by name is present
	 */
	public check(name): boolean {
		if (!this.documentIsAccessible) {
			return false;
		}
		name = encodeURIComponent(name);
		const regExp = this.getCookieRegExp(name);
		return regExp.test(this.document.cookie);
	}

	/**
	 * @param name - Cookie name
	 * @returns The cookie if document is accessible, else an empty string
	 */
	public get(name): any {
		if (this.documentIsAccessible && this.check(name)) {
			name = encodeURIComponent(name);
			const regExp = this.getCookieRegExp(name);
			const result = regExp.exec(this.document.cookie);
			return decodeURIComponent(result[1]);
		} else {
			return '';
		}
	}

	/**
	 * @returns An array of cookies if document is accessible
	 */
	public getAll() {
		if (!this.documentIsAccessible) {
			return {};
		}
		const cookies = {};
		const document = this.document;
		if (document.cookie && document.cookie !== '') {
			const split = document.cookie.split(';');
			for (const data of split) {
				const currentCookie = data.split('=');
				currentCookie[0] = currentCookie[0].replace(/^ /, '');
				cookies[decodeURIComponent(currentCookie[0])] = decodeURIComponent(currentCookie[1]);
			}
		}
		return cookies;
	}

	public getCookieValueFromString(cookieName: string, cookieString: string): string | undefined {
		cookieName = encodeURIComponent(cookieName);
		const regExp = this.getCookieRegExp(cookieName);
		const result = regExp.exec(cookieString);
		try {
			if (Array.isArray(result) && typeof result[1] !== 'undefined' && result[1] !== null) {
				return decodeURIComponent(result[1]);
			}
		} catch (error) {
			this.logService.error(
				`COOKIE_REGEX_PARSE_ERROR: Error parsing cookie by name "${cookieName}" (${cookieString}): ${JSON.stringify(error, [
					'status',
					'message',
					'arguments',
					'type',
					'name'
				])}`
			);
			return;
		}
	}

	/**
	 * @param name - Cookie name
	 * @param value - Cookie value
	 * @param [expires] - Number of days until the cookies expires or an actual `Date`
	 * @param [path] - Cookie path
	 * @param [domain] - Cookie domain
	 * @param [secure] - Secure flag
	 */
	public set(name, value, expires?, path?, domain?, secure?) {
		if (!this.documentIsAccessible) {
			return false;
		}

		let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;

		/* istanbul ignore else */
		if (expires) {
			/* istanbul ignore else */
			if (typeof expires === 'number') {
				const dateExpires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);
				cookieString += `expires=${dateExpires.toUTCString()};`;
			} else if (expires instanceof Date) {
				cookieString += `expires=${expires.toUTCString()};`;
			}
		}

		/* istanbul ignore else */
		if (path) {
			cookieString += `path=${path};`;
		}

		/* istanbul ignore else */
		if (domain) {
			cookieString += `domain=${domain};`;
		}

		/* istanbul ignore else */
		if (secure) {
			cookieString += 'secure;';
		}

		this.document.cookie = cookieString;
	}

	/**
	 * @param name - Cookie name
	 * @param path - Cookie path
	 * @param domain - Cookie domain
	 */
	public delete(name: string, path: string, domain: string) {
		if (!this.documentIsAccessible) {
			return false;
		}
		this.set(name, '', -1, path, domain, '');
	}

	/**
	 * @param name - Cookie name
	 */
	public deleteGeneric(name) {
		if (!this.documentIsAccessible) {
			return false;
		}
		this.set(name, '', -1, '/', this.document.domain, '');
	}

	/**
	 * @param name - Cookie name
	 */
	public deleteRegexIfPresent(name) {
		if (!this.documentIsAccessible) {
			return false;
		}
		const value = this.document.cookie.match(name);
		this.set(value, '', -1, '/', this.document.domain, '');
	}

	/**
	 * @param path - Cookie path
	 * @param domain - Cookie domain
	 */
	public deleteAll(path: string, domain: string) {
		if (!this.documentIsAccessible) {
			return false;
		}
		const cookies = this.getAll();
		for (const cookieName in cookies) {
			/* istanbul ignore else */
			if (cookies.hasOwnProperty(cookieName)) {
				this.delete(cookieName, path, domain);
			}
		}
	}

	/**
	 * @param name - Cookie name
	 */
	private getCookieRegExp(name: string) {
		const escapedName = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');
		return new RegExp(`(?:^${escapedName}|;\\s*${escapedName})=(.*?)(?:;|$)`, 'g');
	}
}
