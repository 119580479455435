/** @format */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, makeStateKey, StateKey, TransferState } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { CustomHttpHeaders, MediaType } from '@bsb/ui/core';

import { IUser } from '../model/user.interface';

@Injectable()
export class UserDao {
	// eslint-disable-next-line no-unused-vars
	constructor(private http: HttpClient, private state: TransferState) {}

	/**
	 * @description
	 * Creates a User and then returns the created User
	 */
	createUser$(user: IUser, isRegistration = true): Observable<IUser> {
		// URL
		let url = '/api/users/register';

		// HTTP Body
		const requestBody = {
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			password: user.password,
			confirmPassword: user.confirmPassword,
			roles: user.roles,
			username: user.username
		};

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Check to see if registration or admin
		if (isRegistration === false) {
			// Update the URL to the protected route
			url = '/api/users';

			// Add the custom HTTP Header for token
			httpHeaders = httpHeaders.set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');
		}

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.post(url, requestBody, httpOptions).pipe(
			map((response: IUser) => {
				return response;
			}),
			retry(0)
		);
	}

	/**
	 * @description
	 * Gets a list of users
	 */
	public getUsers$(retries = 0): Observable<IUser[]> {
		// Get unique key
		const STATE_KEY: StateKey<IUser[]> = makeStateKey<IUser[]>('users');

		// Attempt to get the state by key
		const stateData: IUser[] = this.state.get<IUser[]>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.get('/api/users', httpOptions).pipe(
			map((users: IUser[]) => {
				// Set the state
				this.state.set<IUser[]>(STATE_KEY, users);

				return users;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Gets a user by ID
	 */
	public getUserById$(userId: string, retries = 0): Observable<IUser> {
		// Get unique key
		const STATE_KEY: StateKey<IUser> = makeStateKey<IUser>(`user-${userId}`);

		// Attempt to get the state by key
		const stateData: IUser = this.state.get<IUser>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.get(`/api/users/${userId}`, httpOptions).pipe(
			map((user: IUser) => {
				// Set the state
				this.state.set<IUser>(STATE_KEY, user);

				return user;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Updates a User and then returns the updated User
	 */
	updateUser$(user: IUser): Observable<IUser> {
		// HTTP Body
		const requestBody = {
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			favorites: user.favorites,
			lists: user.lists,
			password: user.password,
			roles: user.roles,
			username: user.username
		};

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Add the custom HTTP Header for token
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.put(`/api/users/${user._id}`, requestBody, httpOptions).pipe(
			map((response: IUser) => {
				return response;
			}),
			retry(0)
		);
	}
}
