/** @format */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root'
})
export class SeoService {
	private renderer: Renderer2;

	// eslint-disable-next-line no-unused-vars
	constructor(
		@Inject(DOCUMENT) private document: HTMLDocument,
		private metaService: Meta,
		private rendererFactory: RendererFactory2,
		private titleService: Title
	) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	/**
	 * @param tag
	 * @param forceCreation
	 */
	public addTag(tag: MetaDefinition, forceCreation = false): HTMLMetaElement {
		return this.metaService.addTag(tag, forceCreation);
	}

	/**
	 * @param tags
	 * @param forceCreation
	 */
	public addTags(tags: MetaDefinition[], forceCreation = false): HTMLMetaElement[] {
		return this.metaService.addTags(tags, forceCreation);
	}

	/**
	 * @param attrSelector
	 */
	public getTag(attrSelector: string): HTMLMetaElement {
		return this.metaService.getTag(attrSelector);
	}

	/**
	 * @param attrSelector
	 */
	public getTags(attrSelector: string): HTMLMetaElement[] {
		return this.metaService.getTags(attrSelector);
	}

	/**
	 * @param url
	 */
	public setCanonicalLink(url: string): void {
		// Create link Element
		const canonicalLink: HTMLLinkElement = this.document.createElement('link');

		// Set the link relationship attribute
		this.renderer.setAttribute(canonicalLink, 'rel', 'canonical');

		// Set the link href attribute
		this.renderer.setAttribute(canonicalLink, 'href', `${url}`);

		// Append the Element to the head Element
		this.document.head.appendChild(canonicalLink);
	}

	/**
	 * @param title
	 */
	public setPageTitle(title: string): void {
		this.titleService.setTitle(title);
	}

	/**
	 * @param url
	 * @param asAttribute
	 * @param typeAttribute
	 */
	public setPreloadLink(url: string, asAttribute: string, typeAttribute: string): void {
		// Create link Element
		const preloadLink: HTMLLinkElement = this.document.createElement('link');

		// Set the link relationship attribute
		this.renderer.setAttribute(preloadLink, 'rel', 'preload');

		// Set the `as` attribute
		this.renderer.setAttribute(preloadLink, 'as', `${ asAttribute }`);

		// Set the link href attribute
		this.renderer.setAttribute(preloadLink, 'href', `${ url }`);

		// Set the `type` attribute
		this.renderer.setAttribute(preloadLink, 'type', `${ typeAttribute }`);

		// Append the Element to the head Element
		this.document.head.appendChild(preloadLink);
	}

	/**
	 * @param tag
	 * @param selector
	 */
	public updateTag(tag: MetaDefinition, selector?: string): HTMLMetaElement {
		return this.metaService.updateTag(tag, selector);
	}

	/**
	 * @param attrSelector
	 */
	public removeTag(attrSelector: string): void {
		return this.metaService.removeTag(attrSelector);
	}
}
