/** @format */
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class EventBusService {
	private subject$ = new Subject();

	/**
	 * @description
	 * Event listener for provided Events enum value.  Executes the callback provided
	 * when the Event is emitted
	 */
	public on(event: Events, action: any): Subscription {
		return this.subject$
			.pipe(
				filter((e: EmitEvent) => {
					return e.name === event;
				}),
				map((e: EmitEvent) => {
					return e.value;
				})
			)
			.subscribe(action);
	}

	/**
	 * @description
	 * Emits the provided Event
	 */
	public emit(event: EmitEvent) {
		this.subject$.next(event);
	}
}

/**
 * @description
 * EmitEvent
 */
export class EmitEvent {
	// eslint-disable-next-line no-unused-vars
	constructor(public name: any, public value?: any) {}
}

/**
 * @description
 * Events to be emitted and listened for
 */
export enum Events {
	// eslint-disable-next-line no-unused-vars
	AddToCart
}
