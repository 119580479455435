<!-- Content -->
<div id="content">
	<!-- Page Title -->
	<div class="page-title page-title-lg">
		<img class="object-fit-cover object-position-bottom" ngSrc="assets/img/Big-Shot-Bobs-Sign-cropped-1280x350-black-white.jpg" alt="The Original Big Shot Bob's House of Wings faux Iron City billboard" priority fill sizes="100vw" />
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h1 class="mb-0 text-center">Original BSBs Blog</h1>
				</div>
			</div>
		</div>
	</div>
	<router-outlet></router-outlet>
</div>
<!-- Content / End -->
