/** @format */
import { Inject, Injectable } from '@angular/core';

import { environment } from '@bsb/ui/env';

import { LogService } from './log.service';
import { WINDOW } from './window.service';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {
	constructor(private logService: LogService, @Inject(WINDOW) private window: Window) {}

	public triggerGoogleAnalyticsEvent(eventName: string, eventDetails: { event_category: string; event_label: string; value: string }): void {
		if (environment.thirdParty.googleAnalytics.enabled && typeof this.window.gtag !== 'undefined' && this.window.gtag !== null) {
			// Trigger Google Analytics event `wing_finder_search`
			this.window.gtag('event', eventName, eventDetails);
		} else {
			this.logService.warn(`Tried triggering GA event but window.gtag is not enabled or loaded: ${eventName}`);
		}
	}
}
