/** @format */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, pluck } from 'rxjs/operators';

import { IUser } from '@bsb/ui/auth';
import { ICart } from '@bsb/ui/core';

export interface IState {
	ALERTS: string[];
	CART: ICart;
	CURRENT_URL: string;
	LOGGED_IN: boolean;
	USER: IUser;
}

export enum AppStateProperties {
	ALERTS = 'ALERTS',
	CART = 'CART',
	CURRENT_URL = 'CURRENT_URL',
	LOGGED_IN = 'LOGGED_IN',
	USER = 'USER'
}

// Default state
const defaultState: IState = {
	ALERTS: undefined,
	CART: undefined,
	CURRENT_URL: undefined,
	LOGGED_IN: false,
	USER: undefined
};

@Injectable()
export class AppState {
	private subject = new BehaviorSubject<IState>(defaultState);

	private store = this.subject.asObservable().pipe(distinctUntilChanged());

	public get value() {
		return this.subject.value;
	}

	public select<T>(name: string, addUndefinedOrNullFilter = false): Observable<T> {
		const ops = [pluck(name)];

		if (addUndefinedOrNullFilter) {
			ops.push(
				filter((result: T) => {
					return result !== undefined && result !== null;
				})
			);
		}

		return this.store.pipe(pipe.apply(this, ops));
	}

	public selectDistinctKey<T>(name: string, addUndefinedOrNullFilter = false): Observable<T> {
		const ops = [distinctUntilKeyChanged(name), pluck(name)];

		if (addUndefinedOrNullFilter) {
			ops.push(
				filter((result: T) => {
					return result !== undefined && result !== null;
				})
			);
		}

		return this.store.pipe(pipe.apply(this, ops));
	}

	public set(name: string, state: any): void {
		this.subject.next({
			...this.value,
			[name]: state
		});
	}
}
