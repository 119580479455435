/** @format */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_ID, Inject, Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';

import { CustomHttpHeaders, LogLevel, MediaType } from '@bsb/shared/schema/enum';
import { ServerLogMessageInterface } from '@bsb/shared/schema/interface';
import { LogConfig } from '@bsb/shared/schema/model';

const DEFAULT_LOG_CONFIG: LogConfig = {
	clientLoggerLevel: LogLevel.ERROR,
	xhrLoggerLevel: LogLevel.OFF,
	xhrLoggerUrl: undefined
};

declare const BSB_BUILD_VERSION: string;

@Injectable({
	providedIn: 'root'
})
export class LogService {
	private BUILD_VERSION: string;

	private levels: string[] = ['TRACE', 'DEBUG', 'INFO', 'LOG', 'WARN', 'ERROR', 'OFF'];

	constructor(@Inject(APP_ID) private appId: string, private config: LogConfig = DEFAULT_LOG_CONFIG, private http: HttpClient) {
		// Check to see if this has been defined
		if (typeof BSB_BUILD_VERSION === 'string' && BSB_BUILD_VERSION.trim() !== '') {
			// Set the build version on the global window
			this.BUILD_VERSION = BSB_BUILD_VERSION;
		} else {
			// Development build runtime value
			this.BUILD_VERSION = new Date().toISOString();
		}
	}

	/**
	 * @description
	 * Accepts a logger level and returns the associated color for it
	 */
	public static getColor(level: LogLevel): string {
		switch (level) {
			case LogLevel.TRACE:
				return 'blue';
			case LogLevel.DEBUG:
				return 'teal';
			case LogLevel.INFO:
			case LogLevel.LOG:
				return 'gray';
			case LogLevel.WARN:
				return 'orange';
			case LogLevel.ERROR:
				return 'red';
			case LogLevel.OFF:
			default:
				return;
		}
	}

	/**
	 * @description
	 * Creates a new JavaScript Date and returns it as an ISO string
	 */
	public static getTimestamp(): string {
		return new Date().toISOString();
	}

	/**
	 * @description
	 * Logs a TRACE level message
	 */
	public trace(message: string | any, ...additional: any[]) {
		this._log(LogLevel.TRACE, true, message, additional);
	}

	/**
	 * @description
	 * Logs a DEBUG level message
	 */
	public debug(message: string | any, ...additional: any[]) {
		this._log(LogLevel.DEBUG, true, message, additional);
	}

	/**
	 * @description
	 * Logs a INFO level message
	 */
	public info(message: string | any, ...additional: any[]) {
		this._log(LogLevel.INFO, true, message, additional);
	}

	/**
	 * @description
	 * Logs a LOG level message
	 */
	public log(message: string | any, ...additional: any[]) {
		this._log(LogLevel.LOG, true, message, additional);
	}

	/**
	 * @description
	 * Logs a WARN level message
	 */
	public warn(message: string | any, ...additional: any[]) {
		this._log(LogLevel.WARN, true, message, additional);
	}

	/**
	 * @description
	 * Logs a ERROR level message
	 */
	public error(message: string | any, ...additional: any[]) {
		this._log(LogLevel.ERROR, true, message, additional);
	}

	/**
	 * @description
	 * Main log function that delegates to additional private methods as necessary
	 */
	private _log(level: LogLevel, logToServer: boolean, message: string | any, additional: any[] = []): void {
		// Check to see if a message has been supplied
		if (!message) {
			return;
		}

		// Allow logging on server even if client log level is off
		if (logToServer) {
			this._logToServer(level, message, additional);
		}

		// Check the log level of the client logger
		if (level < this.config.clientLoggerLevel) {
			return;
		}

		// Check the type of message to see whether it needs stringified
		if (typeof message === 'object') {
			message = JSON.stringify(message, null, 2);
		}

		this._logToConsole(level, `${message}`, additional);
	}

	/**
	 * @description
	 * Logs message to console
	 */
	private _logToConsole(level: LogLevel, message: string, additional: any[]): void {
		const consoleStyle = `color: ${LogService.getColor(level)}`;

		// Output the message / optional params to the console
		console.log(
			`%c ${JSON.stringify({
				timestamp: `${LogService.getTimestamp()}`,
				level: this.levels[level],
				message: message,
				additionalDetails: additional,
				buildVersion: this.BUILD_VERSION
			})}`,
			consoleStyle
		);
	}

	/**
	 * @description
	 * Logs a message to server
	 */
	private _logToServer(level: LogLevel, message: string | unknown, additional: unknown[]): void {
		// Check to see if the message should be sent server-side
		if (level < this.config.xhrLoggerLevel || typeof this.config.xhrLoggerUrl === 'undefined') {
			return;
		}

		// Define default HTTP Headers
		let httpHeaders: HttpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Set custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_LOG_REQUEST, 'true').set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_TIMEOUT, '1000');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Data to send to server
		const logMessage: ServerLogMessageInterface = {
			applicationId: this.appId,
			buildVersion: this.BUILD_VERSION,
			level: this.levels[level],
			message,
			timestamp: LogService.getTimestamp(),
			additionalDetails: additional
		};

		// Wrap in try / catch to prevent app from fatally failing for a log message
		try {
			// Make the request
			this.http
				.post(this.config.xhrLoggerUrl, logMessage, httpOptions)
				.pipe(retry(1))
				.subscribe({
					next: () => {
						return;
					},
					error: (error) => {
						return new Error(error);
					}
				});
		} catch (error) {
			this._log(LogLevel.ERROR, false, `LogService#logToServer -- Exception occurred during XHR Log Request: ${JSON.parse(error)}`);
		}
	}
}
