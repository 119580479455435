/** @format */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable sonarjs/no-duplicate-string */

import { BasePageType, IBSB_CONSTANTS } from '@bsb/shared/schema/interface';

export const BSB_CONSTANTS: IBSB_CONSTANTS = {
	restaurantId: '5ae136cb7bd1f6ef91db7f0d',
	alerts: {
		cookie: {
			name: 'bsb-cookie-alerts',
			path: '/',
			expiration: 30,
			secure: false,
			httpOnly: false
		},
		alert: {
			contact: {
				messages: ['kennedyHours']
			},
			menu: {
				messages: ['account', 'delivery', 'fish', 'prices']
			}
		}
	},
	errorHandling: {
		messagePrefixes: {
			global: {
				client: 'APPLICATION_ERROR:CLIENT_ERROR: ',
				data: 'APPLICATION_ERROR:DATA_ERROR: ',
				generic: 'APPLICATION_ERROR:GENERIC_ERROR: ',
				http: 'APPLICATION_ERROR:HTTP_ERROR: ',
				offline: 'APPLICATION_ERROR:OFFLINE_ERROR: ',
				timeout: 'TIMEOUT_ERROR: '
			}
		}
	},
	menuCategoryOrderMax: 15,
	menuItemOrderMax: 300,
	privacy: {
		cookies: {
			consentDisplay: {
				name: 'bsb-cookie-consent',
				path: '/',
				expiration: 30,
				secure: false,
				httpOnly: false
			}
		}
	},
	seo: {
		meta: {
			'default': {
				title: 'OG BSBs: Avalon | Kennedy Twp',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [
					'Big Shot Bobs',
					"Big Shot Bob's",
					"Original Big Shot Bob's",
					'Original bsb',
					'BSB',
					'bsb',
					'OGBSB',
					'OGBSBS',
					'OG BSB',
					'OG BSBS',
					'OG BSBs',
					'Wings',
					'House of Wings',
					'Avalon',
					'Kennedy',
					'Kennedy Twp',
					'Kennedy Township',
					'McKees Rocks',
					'Pittsburgh',
					'menu'
				],
				name: "Original Big Shot Bob's",
				secure: false, // Default to true in case any URLs we don't want indexed aren't caught in this list
				default: true
			},
			'/': {
				title: 'OG BSBs: Avalon | Kennedy Twp',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/about-us': {
				title: 'OG BSBs - About Us',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/account': {
				title: 'OG BSBs - Account',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: true
			},
			'/account/favorites': {
				title: 'OG BSBs - Account Favorites',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: true
			},
			'/account/lists': {
				title: 'OG BSBs - Account Scratch Lists',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: true
			},
			'/account/login': {
				title: 'OG BSBs - Account Login',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: true
			},
			'/account/profile': {
				title: 'OG BSBs - Account Profile',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: true
			},
			'/account/register': {
				title: 'OG BSBs - Account Profile',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: true
			},
			'/account/security': {
				title: 'OG BSBs - Account Profile',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: true
			},
			'/admin': {
				title: 'OG BSBs - Admin',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: true
			},
			'/contact': {
				title: 'OG BSBs - Contact Us',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/faq': {
				title: 'OG BSBs - Frequently Asked Questions (F.A.Q.)',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/legal/privacy': {
				title: 'OG BSBs - Privacy Policy',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/legal/cookies': {
				title: 'OG BSBs - Cookie Policy',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/legal/requests': {
				title: 'OG BSBs - Privacy Requests',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/legal/terms-of-use': {
				title: 'OG BSBs - Terms of Use',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/menu': {
				title: 'OG BSBs - Menu',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/password/reset': {
				title: 'OG BSBs - Password Reset',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: true
			},
			'/posts': {
				title: 'OG BSBs - News',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/sauce': {
				title: 'OG BSBs - Sauces',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/shop': {
				title: 'OG BSBs - Shop',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			},
			'/wing-finder': {
				title: 'OG BSBs - Wing Finder',
				description: "The Original Big Shot Bob's House of Wings (OG BSB, OG BSBs), serving the Pittsburgh area since 2007.  Locations in Avalon and Kennedy Twp.",
				keywords: [],
				name: "Original Big Shot Bob's",
				secure: false
			}
		}
	},
	server: {
		basePages: [
			{
				basePagePathPattern: '/about-us',
				basePagePathPrefix: '/about-us',
				basePageType: BasePageType.ABOUT_US,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/account',
				basePagePathPrefix: '/account',
				basePageType: BasePageType.ACCOUNT,
				cacheKeyParams: [],
				isCacheable: false
			},
			{
				basePagePathPattern: '/admin',
				basePagePathPrefix: '/admin',
				basePageType: BasePageType.ADMIN,
				cacheKeyParams: [],
				isCacheable: false
			},
			{
				basePagePathPattern: '/contact',
				basePagePathPrefix: '/contact',
				basePageType: BasePageType.CONTACT,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/faq',
				basePagePathPrefix: '/faq',
				basePageType: BasePageType.FAQ,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/menu',
				basePagePathPrefix: '/menu',
				basePageType: BasePageType.MENU,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/legal/cookies',
				basePagePathPrefix: '/legal/cookies',
				basePageType: BasePageType.LEGAL_COOKIES,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/legal/privacy',
				basePagePathPrefix: '/legal/privacy',
				basePageType: BasePageType.LEGAL_PRIVACY,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/legal/requests',
				basePagePathPrefix: '/legal/requests',
				basePageType: BasePageType.LEGAL_REQUESTS,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/legal/terms-of-use',
				basePagePathPrefix: '/legal/terms-of-use',
				basePageType: BasePageType.LEGAL_TERMS_OF_USE,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/password',
				basePagePathPrefix: '/password',
				basePageType: BasePageType.PASSWORD,
				cacheKeyParams: [],
				isCacheable: false
			},
			{
				basePagePathPattern: '/posts',
				basePagePathPrefix: '/posts',
				basePageType: BasePageType.BLOG,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/sauce',
				basePagePathPrefix: '/sauce',
				basePageType: BasePageType.SAUCE,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/shop',
				basePagePathPrefix: '/shop',
				basePageType: BasePageType.SHOP,
				cacheKeyParams: [],
				isCacheable: true
			},
			{
				basePagePathPattern: '/wing-finder',
				basePagePathPrefix: '/wing-finder',
				basePageType: BasePageType.WING_FINDER,
				cacheKeyParams: [],
				isCacheable: true
			},
			// NOTE: home MUST go last, otherwise it will match every path when it shouldn't
			{
				basePagePathPattern: '/',
				basePagePathPrefix: '/',
				basePageType: BasePageType.HOME,
				cacheKeyParams: [],
				isCacheable: true
			}
		]
	},
	shopify: {
		cartCookie: {
			name: 'bsb-cart',
			path: '/',
			expiration: 30,
			secure: false,
			httpOnly: false
		},
		shipping: {
			defaultCountry: 'United States',
			flatRateOptions: {
				default: {
					itemCountMax: 3,
					cost: 7.75
				},
				small: {
					itemCountMax: 3,
					cost: 8.4
				},
				medium: {
					itemCountMax: 6,
					cost: 15.05
				},
				large: {
					itemCountMax: 10,
					cost: 21.1
				}
			},
			minCartQuantity: 1,
			maxCartQuantity: 20
		}
	}
};
