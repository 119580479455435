/** @format */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IBlogPost } from '../blog.interface';
import { BlogService } from '../blog.service';

@Component({
	selector: 'app-blog-post',
	templateUrl: './blog-post.component.html',
	styleUrls: ['./blog-post.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BlogPostComponent implements OnInit {
	blogPost: IBlogPost;

	constructor(private activatedRoute: ActivatedRoute, private blogService: BlogService) {}

	ngOnInit() {
		this.activatedRoute.params.subscribe((params) => {
			if (params['postId']) {
				// Get the blog post by ID
				this.blogService.getBlogPost$(params['postId']).subscribe((blogPost: IBlogPost) => {
					// Set the blog post
					this.blogPost = blogPost;
				});
			}
		});
	}
}
