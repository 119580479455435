/** @format */

export enum DocumentType {
	BLOG = 'blog',
	BLOG_POST = 'blog post',
	LIST = 'list',
	MENU = 'menu',
	MENU_CATEGORY = 'menu category',
	MENU_ITEM = 'menu item',
	USER = 'user'
}
