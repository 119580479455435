/** @format */
import { Injectable } from '@angular/core';
import { AppState, AppStateProperties } from '@bsb/ui/state';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LocalStorageService } from '@bsb/ui/core';

import { UserDao } from '../dao/user.dao';
import { IUser } from '../model/user.interface';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(private localStorageService: LocalStorageService, private state: AppState, private userDao: UserDao) {}

	public createUser$(user: IUser, isRegistration = true): Observable<IUser> {
		return this.userDao.createUser$(user, isRegistration);
	}

	public getUsers$(): Observable<IUser[]> {
		return this.userDao.getUsers$();
	}

	public getUserById$(userId: string): Observable<IUser> {
		return this.userDao.getUserById$(userId);
	}

	public updateCurrentUser(user: IUser): Observable<IUser> {
		return this.userDao.updateUser$(user).pipe(
			tap((updatedUser: IUser) => {
				// Merge the updated User object with the existing
				updatedUser = Object.assign(user, updatedUser);

				// Set the updated user to app state
				this.state.set(AppStateProperties.USER, updatedUser);

				// Set the user to local storage
				this.localStorageService.set('USER', JSON.stringify(updatedUser));
			})
		);
	}

	public updateUser(user: IUser): Observable<IUser> {
		return this.userDao.updateUser$(user);
	}
}
