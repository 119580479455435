<!-- Header Desktop -->
<header id="header" [ngClass]="{ 'absolute transparent home': currentUrl === '/', light: currentUrl !== '/' }">
	<div class="container">
		<div class="row">
			<div class="col-md-2">
				<!-- Logo -->
				<div class="module module-logo transparent object-fit-contain-container" [ngClass]="{ 'vertical-align-center': currentUrl !== '/' }">
					<a class="d-inline-block float-left" href="/" routerLinkActive #logoLinkActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }">
						<img class="d-inline-block navbar-logo" [src]="currentUrl === '/' ? 'https://ogbsb.imgix.net/assets/img/bsb-logo-black-white-padded.jpg?auto=format&auto=compress' : 'https://ogbsb.imgix.net/assets/img/ogbsb-ribbon-black-white2.jpg?auto=format&auto=compress'" alt="The Original Big Shot Bob's House of Wings" loading="lazy" />
						<!--<img class="d-inline-block img-fluid navbar-logo" ngSrc="assets/img/bsb-logo-black-white-padded.jpg" alt="The Original Big Shot Bob's House of Wings crown logo" loading="lazy" fill />-->
					</a>
				</div>
			</div>
			<div class="col-md-7 col-xl-8">
				<!-- Navigation -->
				<nav class="module module-navigation d-flex justify-content-center">
					<ul id="nav-main" class="nav nav-main" [ngClass]="{ 'nav-main-shadow': currentUrl === '/' }">
						<li><a href="/">Home</a></li>
						<li><a href="about-us">About</a></li>
						<li><a href="menu">Menu</a></li>
						<li><a href="wing-finder">Wing Finder</a></li>
						<li><a href="sauce">Sauce</a></li>
						<li><a href="shop">Shop</a></li>
						<li><a href="posts">BSB Blog</a></li>
						<li><a href="faq">F.A.Q.</a></li>
						<li><a href="contact">Contact</a></li>
					</ul>
				</nav>
			</div>
			<div class="col-md-2 col-xl-1">
				<a href="#" class="module module-cart right" data-toggle="panel-cart">
					<span class="cart-icon">
						<i class="ti ti-shopping-cart"></i>
						<span class="notification">{{ cartQuantity }}</span>
					</span>
					<span class="cart-value">{{ cartTotal | currency }}</span>
				</a>
			</div>
			<div class="col-md-1">
				<a href="/account" class="module module-account right" aria-label="OGBSB Account & Profile">
					<span class="profile-icon">
						<i class="ti ti-user"></i>
					</span>
				</a>
			</div>
		</div>
	</div>
</header>
<!-- Header Desktop End -->

<!-- Header Mobile -->
<header id="header-mobile" class="light">
	<div class="module module-nav-toggle">
		<a href="#" id="nav-toggle" data-toggle="panel-mobile" aria-label="Navigation menu open button">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</a>
	</div>

	<div class="module module-logo">
		<a class="d-inline-block" href="/">
			<img class="d-inline-block img-fluid" src="https://ogbsb.imgix.net/assets/img/bsbavalon-crown.jpg?auto=format&auto=compress" alt="The Original Big Shot Bob's House of Wings crown logo" loading="lazy" />
		</a>
	</div>

	<a href="#" class="module module-cart" data-toggle="panel-cart">
		<i class="ti ti-shopping-cart"></i>
		<span class="notification">{{ cartQuantity }}</span>
	</a>

	<a href="/account" class="module module-account" aria-label="OGBSB Account & Profile">
		<i class="ti ti-user"></i>
	</a>
</header>
<!-- Header Mobile End -->

<!-- Component Placeholder -->
<router-outlet></router-outlet>
<div class="central-loader" *ngIf="loading">
	<div>
		<img class="page-loading-indicator" src="assets/img/spinner.svg" aria-hidden="true"/>
		<span>&nbsp; Loading...</span>
	</div>
</div>

<!-- Footer -->
<footer id="footer" class="bg-dark dark footer">
	<div class="container">
		<!-- Footer 1st Row -->
		<div class="footer-first-row row">
			<div class="col-lg-3 text-center mb-4">
				<a class="d-inline-block" href="/">
					<img class="d-inline-block img-fluid footer-logo object-fit-contain" ngSrc="assets/img/ogbsb-ribbon-white-charcoal.jpg" alt="The Original Big Shot Bob's House of Wings crown logo" loading="lazy"  height="157" width="250" />
				</a>
			</div>
			<div class="col-lg-4 col-md-6">
				<div class="footer-heading">Latest from the Kitchen</div>
				<ul class="list-posts">
					<li *ngFor="let post of blogPosts">
						<a href="posts/{{ post._id }}" class="title">
							{{ post.title }}
						</a>
						<span class="date">{{ post.createdAt | date : "mediumDate" }}</span>
					</li>
				</ul>
			</div>
			<div class="col-lg-5 col-md-6">
				<div class="footer-heading">Subscribe to our Newsletter!</div>
				<!-- MailChimp Form -->
				<form id="sign-up-form" class="sign-up-form validate-form mb-3" #emailSignupForm="ngForm">
					<div class="input-group">
						<input name="email" id="email" type="email" class="form-control" placeholder="Your e-mail..." required [pattern]="'^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'" [(ngModel)]="email" autocomplete="email" />
						<span class="input-group-btn">
							<button class="btn btn-primary btn-submit" (click)="onSignupClick(emailSignupForm)" [ngClass]="{ loading: signingUp, success: emailSignupSuccess }">
								<span class="description">Subscribe</span>
								<span class="success">
									<svg x="0px" y="0px" viewBox="0 0 32 32">
										<path
											stroke-dasharray="19.79 19.79"
											stroke-dashoffset="19.79"
											fill="none"
											stroke="#FFFFFF"
											stroke-width="2"
											stroke-linecap="square"
											stroke-miterlimit="10"
											d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
										/>
									</svg>
								</span>
								<span class="error">Try again...</span>
							</button>
						</span>
					</div>
					<div>
						<span class="d-block alert alert-success mt-3" *ngIf="emailSignupSuccess">
							Thank you for signing up!
						</span>
						<span class="d-block alert alert-danger mt-3" *ngIf="emailFormSubmitted && !emailSignupSuccess">
							Oops! Either your email is invalid or there was a problem, please try again.
						</span>
					</div>
				</form>
				<div class="footer-heading mb-3">Follow Us!</div>
				<div class="mb-3">
					<span class="mr-3">Avalon</span>
					<a href="https://www.facebook.com/bigshotbobs" class="icon icon-social icon-circle icon-sm icon-facebook mr-2" aria-label="Facebook link for Avalon OGBSB">
						<i class="fa fa-facebook"></i>
					</a>
					<a href="https://twitter.com/bigshotbobs" class="icon icon-social icon-circle icon-sm icon-twitter mr-2" aria-label="Twitter link for Avalon OGBSB">
						<i class="fa fa-twitter"></i>
					</a>
					<a href="https://www.instagram.com/bigshotbobs" class="icon icon-social icon-circle icon-sm icon-instagram" aria-label="Instagram link for Avalon OGBSB">
						<i class="fa fa-instagram"></i>
					</a>
				</div>
				<div>
					<span class="mr-3">Kennedy Twp</span>
					<a href="https://www.facebook.com/ogbsbsph" class="icon icon-social icon-circle icon-sm icon-facebook mr-2" aria-label="Facebook link for Kennedy Twp OGBSB">
						<i class="fa fa-facebook"></i>
					</a>
					<a href="https://www.instagram.com/ogbsbsph" class="icon icon-social icon-circle icon-sm icon-instagram" aria-label="Instagram link for Kennedy Twp OGBSB">
						<i class="fa fa-instagram"></i>
					</a>
				</div>
			</div>
		</div>
		<!-- Footer 2nd Row -->
		<div class="footer-second-row">
			<span class="text-center d-block">
				<a class="mr-4" href="/legal/terms-of-use">Terms of Use</a>
				<a class="mr-4" href="/legal/privacy">Privacy Policy</a>
				<a href="/legal/cookies">Cookie Policy</a>
			</span>
		</div>
		<!-- Footer 2nd Row -->
		<div class="footer-second-row">
			<div class="text-center">
				Copyright © {{ lastUpdatedDate | date : 'yyyy' }}. The Original Big Shot Bob's House of Wings. Website built by <a class="underline" href="https://www.linkedin.com/in/cmcmurray/" target="_blank">Craig McMurray</a>. Food photography by <a class="underline" href="https://www.brianleestudios.net" target="_blank">Brian Lee Studios</a>.
			</div>
			<div class="text-center" *ngIf="lastUpdatedDate"> Last updated: {{ lastUpdatedDate | date : 'longDate' }}</div>
		</div>
	</div>

	<!-- Back To Top -->
	<a href="#" id="back-to-top">
		<i class="ti ti-angle-up"></i>
	</a>
</footer>
<!-- Footer End -->

<!-- Panel Cart -->
<app-cart-panel></app-cart-panel>

<!-- Panel Mobile -->
<nav id="panel-mobile">
	<div class="module module-logo bg-dark dark">
		<a class="panel-mobile-logo" href="#">
			<img class="d-inline-block img-fluid" ngSrc="assets/img/White-Chicken-Blue-Circle-Transparent-400x400.png" alt="The Original Big Shot Bob's House of Wings Chicken Logo" priority="false" height="200" width="200"/>
		</a>
		<button class="close" data-toggle="panel-mobile">
			<i class="ti ti-close"></i>
		</button>
	</div>
	<nav class="module module-navigation"></nav>
	<nav class="module module-navigation-extras">
		<ul class="nav nav-main-mobile">
			<li>
				<a href="/account" class="" aria-label="OGBSB Account & Profile">Account</a>
			</li>
		</ul>
	</nav>
	<div class="module module-social">
		<h6 class="text-sm mb-3">Follow Us!</h6>
		<div class="mb-3">
			<span class="mr-3">Avalon</span>
			<a href="https://www.facebook.com/bigshotbobs" class="icon icon-social icon-circle icon-sm icon-facebook mr-2" aria-label="Facebook link for Avalon OGBSB">
				<i class="fa fa-facebook"></i>
			</a>
			<a href="https://twitter.com/bigshotbobs" class="icon icon-social icon-circle icon-sm icon-twitter mr-2" aria-label="Twitter link for Avalon OGBSB">
				<i class="fa fa-twitter"></i>
			</a>
			<a href="https://www.instagram.com/bigshotbobs" class="icon icon-social icon-circle icon-sm icon-instagram" aria-label="Instagram link for Avalon OGBSB">
				<i class="fa fa-instagram"></i>
			</a>
		</div>
		<div>
			<span class="mr-3">Kennedy Twp</span>
			<a href="https://www.facebook.com/ogbsbsph" class="icon icon-social icon-circle icon-sm icon-facebook mr-2" aria-label="Facebook link for Kennedy Twp OGBSB">
				<i class="fa fa-facebook"></i>
			</a>
			<a href="https://www.instagram.com/ogbsbsph" class="icon icon-social icon-circle icon-sm icon-instagram" aria-label="Instagram link for Kennedy Twp OGBSB">
				<i class="fa fa-instagram"></i>
			</a>
		</div>
	</div>
</nav>

<!-- Cookies Consent -->
<ng-template #cookieConsentContent let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="cookie-consent-title">Cookie Consent</h4>
	</div>
	<div class="modal-body">
		<p>
			Our website stores cookies on your device and discloses information in accordance with our Cookie Statement. We may collect certain aggregate and anonymized data from your browser independent of your cookie preferences.
			<a class="underline" href="/legal/cookies" target="_blank">Cookie Statement</a>
		</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="modal.close('Save')">
			<span>I Accept</span>
		</button>
	</div>
</ng-template>
