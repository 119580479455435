/** @format */

/*
 * NOTE: This Component uses OnPush Change Detection due to the nature of its setup. Since this component is hidden on page load, it does not get patched
 * by Zone and event handlers will not mark the component for check and trigger Change Detection, so it needs to be triggered manually. Additionally, since
 * we are operating outside of Zone, we need to wrap the call to detect changes in a setTimeout so that Zone picks it up.
 */

/* eslint-disable sonarjs/no-duplicate-string, sonarjs/no-identical-functions */
import { afterNextRender, AfterRenderPhase, ChangeDetectorRef, ChangeDetectionStrategy, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BSB_CONSTANTS, CookieService, EventBusService, Events, IAlertMessage, IATCEvent, IAttributeInput, ICart, ICheckoutLineItemInput, LogService, ShopService } from '@bsb/ui/core';
import { AppState, AppStateProperties } from '@bsb/ui/state';

@Component({
	selector: 'app-cart-panel',
	templateUrl: './cart-panel.component.html',
	styleUrls: ['./cart-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartPanelComponent implements OnInit {
	/**
	 * @description
	 * Cart actions
	 */
	public readonly CART_ACTIONS = {
		decrement: 'decrement',
		increment: 'increment'
	};

	/**
	 * @description
	 * Alert message
	 */
	public alertMessage$: BehaviorSubject<IAlertMessage> = new BehaviorSubject<IAlertMessage>(undefined);

	/**
	 * @description
	 * The current Cart
	 */
	public cart: ICart;

	/**
	 * @description
	 * The current Cart quantity
	 */
	public cartQuantity = 0;

	/**
	 * @description
	 * The Cart total including estimated shipping
	 */
	public cartTotal: number;

	/**
	 * @description
	 * The current checkout ID if there is one
	 */
	public checkoutID: string;

	/**
	 * @description
	 * Flat rate shipping (estimated)
	 */
	public estimatedShipping: number;

	/**
	 * @description
	 * Whether the cart is currently updating or not
	 */
	public isCartUpdateOccurring$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	/**
	 * @description
	 * Maximum number of items allowed in the Cart
	 */
	public maxQuantity = BSB_CONSTANTS.shopify.shipping.maxCartQuantity;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private cookieService: CookieService,
		private eventBusService: EventBusService,
		private logService: LogService,
		@Inject(PLATFORM_ID) protected platformId: any,
		private shopService: ShopService,
		private state: AppState
	) {
		afterNextRender(
			() => {
				this.doDynamicUpdatesAfterHydration();
			},
			{ phase: AfterRenderPhase.Read }
		);
	}

	ngOnInit() {
		// Subscribe to the AddToCart Event
		this.eventBusService.on(Events.AddToCart, (atcEventData: IATCEvent) => {
			// Set the alert message
			this.alertMessage$.next(atcEventData.message);
		});

		// Subscribe to the Cart in State
		this.state.select(AppStateProperties.CART, true).subscribe((cart: ICart) => {
			// Set the Cart on the Component
			this.cart = cart;

			// Check to see if the Checkout ID is still undefined
			if (typeof this.checkoutID === 'undefined') {
				// Set the Checkout ID for the first time
				this.checkoutID = `${cart.id}`;
			}

			// Get the updated estimated shipping cost
			this.estimatedShipping = this.shopService.getEstimatedShippingFromCart(cart);

			// Set the Cart Quantity
			this.cartQuantity = this.shopService.getQuantityFromCart(cart);

			// Get the updated total
			this.cartTotal = this.shopService.getTotalFromCart(cart);

			// Detect changes using setTimeout to force change detection
			setTimeout(() => {
				this.changeDetectorRef.detectChanges();
			});
		});

		// Subscribe to the alert message
		this.alertMessage$.asObservable().subscribe(() => {
			// Detect changes using setTimeout to force change detection
			setTimeout(() => {
				this.changeDetectorRef.detectChanges();
			});
		});

		// Subscribe to the cart updating flag
		this.isCartUpdateOccurring$.asObservable().subscribe(() => {
			// Detect changes using setTimeout to force change detection
			setTimeout(() => {
				this.changeDetectorRef.detectChanges();
			});
		});
	}

	/**
	 * @description
	 * Clears any alert messages
	 */
	public clearAlertMessage(): void {
		this.alertMessage$.next(undefined);
	}

	/**
	 * @description
	 * Decrements the quantity of the Product by 1
	 */
	public decrementLineItemQuantity(lineItem: ICheckoutLineItemInput): void {
		// Update the Cart
		this.updateLineItemInCart(lineItem, 1, this.CART_ACTIONS.decrement);
	}

	/**
	 * @description
	 * Increments the quantity of the Product by 1
	 */
	public incrementLineItemQuantity(lineItem: ICheckoutLineItemInput): void {
		// Update the Cart
		this.updateLineItemInCart(lineItem, 1, this.CART_ACTIONS.increment);
	}

	/**
	 * @description
	 * Removes a Product from Cart
	 */
	public removeLineItemFromCart(lineItemInput: ICheckoutLineItemInput | ICheckoutLineItemInput[], outOfStock = false): void {
		// Set the flag to true
		this.isCartUpdateOccurring$.next(true);

		// Line item IDs to remove
		const lineItemIdsToRemove: string[] = [];

		// Line item titles to remove
		const lineItemTitlesToRemove: string[] = [];

		// Check the input type
		if (!Array.isArray(lineItemInput)) {
			// Add the single line item ID to the array
			lineItemIdsToRemove.push(`${lineItemInput.id}`);

			// Product title
			let productTitle = `${lineItemInput.title}`;

			// Variant title
			const variantTitle = lineItemInput?.variant?.title !== '' && lineItemInput?.variant?.title?.toLowerCase() !== 'default title' ? lineItemInput?.variant?.title : '';

			// Check to see if the variant title exists and isn't a default title
			if (variantTitle !== '') {
				// Overwrite the product title string for display in the alert message
				productTitle = `"${productTitle} (${variantTitle})"`;
			}

			// Add the single line item title to the array
			lineItemTitlesToRemove.push(productTitle);
		} else if (Array.isArray(lineItemInput) && lineItemInput.length > 0) {
			// Iterate the line items and add each ID to the array
			for (let i = 0, length = lineItemInput.length; i < length; i += 1) {
				lineItemIdsToRemove.push(`${lineItemInput[i].id}`);

				// Product title
				let productTitle = `${lineItemInput[i].title}`;

				// Variant title
				const variantTitle = lineItemInput[i]?.variant?.title !== '' && lineItemInput[i]?.variant?.title?.toLowerCase() !== 'default title' ? lineItemInput[i]?.variant?.title : '';

				// Check to see if the variant title exists and isn't a default title
				if (variantTitle !== '') {
					// Overwrite the product title string for display in the alert message
					productTitle = `"${productTitle} (${variantTitle})"`;
				}

				// Add the single line item title to the array
				lineItemTitlesToRemove.push(productTitle);
			}
		}

		if (lineItemIdsToRemove.length > 0) {
			// Remove the line item(s) from Cart
			this.shopService
				.removeLineItemsFromCheckout$(this.checkoutID, lineItemIdsToRemove)
				.then((cart: ICart) => {
					// Check the message to display
					if (outOfStock) {
						// Show an error message
						this.alertMessage$.next({
							closable: true,
							messageTitle: 'Out of Stock',
							messageBody: `The following items are no longer available and have been removed from your Cart: ${lineItemTitlesToRemove.join(', ')}`,
							messageLevel: 'error'
						});
					} else {
						// Show a success message
						this.alertMessage$.next({
							closable: true,
							messageTitle: 'Cart Update',
							messageBody: `Successfully removed the following item(s) in your cart: ${lineItemTitlesToRemove.join(', ')}`,
							messageLevel: 'success'
						});
					}

					// Set the Cart in the State
					this.state.set(AppStateProperties.CART, cart);
				})
				.catch((error) => {
					this.logService.error('CART_REMOVE_ITEM_ERROR: Error removing item from cart.', error);

					// Show an error message
					this.alertMessage$.next({
						closable: true,
						messageTitle: 'Cart Update Error',
						messageBody: `There was an error removing the follow item(s) from your Cart: ${lineItemTitlesToRemove.join(', ')}`,
						messageLevel: 'error'
					});
				})
				.finally(() => {
					// Set the flag to false
					this.isCartUpdateOccurring$.next(false);
				});
		} else {
			// Display an error message
			this.alertMessage$.next({
				closable: true,
				messageTitle: 'Cart Update Error',
				messageBody: 'Oops! There was an issue trying to remove items from your Cart.',
				messageLevel: 'error'
			});

			// Set the flag to false
			this.isCartUpdateOccurring$.next(false);
		}
	}

	/**
	 * @description
	 * Updates a Product in the Cart
	 *
	 * @param lineItem
	 * @param quantity
	 * @param action
	 */
	public updateLineItemInCart(lineItem: ICheckoutLineItemInput, quantity: number, action: string): void {
		// Set the flag to true
		this.isCartUpdateOccurring$.next(true);

		// Cart quantity at this point in time
		const currentCartQuantity = this.cartQuantity;

		// Quantity exceeded flag
		let quantityExceededFlag = false;

		// Updated Quantity
		let updatedQuantity: number;

		// Check the action
		if (`${action}`.toLowerCase() === `${this.CART_ACTIONS.increment}`) {
			// Check to see if the added item quantity exceeds the threshold
			if (currentCartQuantity + quantity > this.maxQuantity) {
				// Set the flag to true
				quantityExceededFlag = true;
			} else {
				// Update the quantity
				updatedQuantity = lineItem.quantity + quantity;
			}
		} else if (`${action}`.toLowerCase() === `${this.CART_ACTIONS.decrement}`) {
			// Update the quantity
			updatedQuantity = lineItem.quantity - quantity;
		}

		// Check the max Cart quantity
		if (quantityExceededFlag) {
			// Show an error message
			this.alertMessage$.next({
				closable: true,
				messageTitle: 'Cart Update Error',
				messageBody: `You've reached the maximum items (${this.maxQuantity}) allowed in your cart.  Please try removing an item and try again.`,
				messageLevel: 'error'
			});

			// Set the flag to false
			this.isCartUpdateOccurring$.next(false);
		} else {
			// Build the IAttributeInput object
			const attributeInput: IAttributeInput[] = [
				{
					id: lineItem.id,
					quantity: updatedQuantity
				}
			];

			// Variant title
			let variantTitle = lineItem?.variant?.title !== '' && lineItem?.variant?.title?.toLowerCase() !== 'default title' ? lineItem?.variant?.title : '';

			// Check to see if the variant title exists and isn't a default title
			if (variantTitle !== '') {
				// Overwrite the variant title string for display in the alert message
				variantTitle = ` (${variantTitle})`;
			}

			// Update the Cart
			this.shopService
				.updateLineItemsInCheckout$(this.checkoutID, attributeInput)
				.then((cart: ICart) => {
					// Show an info message
					this.alertMessage$.next({
						closable: true,
						messageTitle: 'Cart Update',
						messageBody: `Successfully updated the item in your cart: "${lineItem.title}${variantTitle}"`,
						messageLevel: 'success'
					});

					// Set the Cart in the State
					this.state.set(AppStateProperties.CART, cart);
				})
				.catch((error) => {
					this.logService.error('CART_UPDATE_ITEM_ERROR: Unable to update line item in cart.', error);

					// Show an error message
					this.alertMessage$.next({
						closable: true,
						messageTitle: 'Cart Update Error',
						messageBody: `There was an issue updating the item in your cart with title "${lineItem.title}${variantTitle}".  Please refresh the page and try again.`,
						messageLevel: 'error'
					});
				})
				.finally(() => {
					// Set the flag to false
					this.isCartUpdateOccurring$.next(false);
				});
		}
	}

	private doDynamicUpdatesAfterHydration(): void {
		// Check to see that the Cart cookie exists in the browser
		if (this.cookieService.check(BSB_CONSTANTS.shopify.cartCookie.name)) {
			// Get the Cart ID from storage
			this.checkoutID = this.cookieService.get(BSB_CONSTANTS.shopify.cartCookie.name);
		}

		// Check to see that the Cart ID exists
		if (typeof this.checkoutID !== 'undefined') {
			// Get the Cart
			this.shopService.getCheckoutByID$(this.checkoutID).then((cart: ICart) => {
				/*
				 * Iterate Cart line items to check for availability
				 */
				// Ensure there are line items to iterate and current context is browser
				if (Array.isArray(cart?.lineItems) && cart?.lineItems.length > 0) {
					// Unavailable line items
					const unavailableLineItems: ICheckoutLineItemInput[] = [];

					// Iterate the line items
					for (let i = 0, length = cart.lineItems.length; i < length; i += 1) {
						// Check to see if the item is unavailable
						if (!cart.lineItems[i]?.variant?.available) {
							// Add the line item to the Array
							unavailableLineItems.push(cart.lineItems[i]);
						}
					}

					// Check to see if there are any line items to remove
					if (unavailableLineItems.length > 0) {
						// Remove the unavailable line items
						this.removeLineItemFromCart(unavailableLineItems, true);
					} else {
						// Everything is available, set the Cart in the State
						this.state.set(AppStateProperties.CART, cart);
					}
				} else {
					// Nothing is in the Cart or current context is server, set the Cart in the State
					this.state.set(AppStateProperties.CART, cart);
				}
			}).catch((error) => {
				this.logService.error('CART_GET_CART_ERROR: Unable to load cart from Shopify.', error);

				// Show an error message
				this.alertMessage$.next({
					closable: true,
					messageTitle: 'Cart Error',
					messageBody: 'There was an issue loading your cart.  Please refresh the page and try again.',
					messageLevel: 'error'
				});
			});
		}
	}

	@HostListener('document:cartPanelClose')
	private onPanelClose(): void {
		// Clear any messages
		this.clearAlertMessage();
	}
}
