/** @format */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppState } from '@bsb/ui/state';

import { AuthService } from '../service/auth.service';

@Injectable()
export class AdminAuthGuard {
	constructor(private _state: AppState, private authService: AuthService, private router: Router) {}

	public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// Set the flag if the session is valid
		const isSessionValid = this.authService.isSessionValid();

		// Check to see if logged in and if they are navigating to the login page
		if (isSessionValid && typeof state.url === 'string' && state.url.toLowerCase().indexOf('/admin/login') > -1) {
			// Navigate to admin dashboard page
			this.router.navigateByUrl('/admin');

			// Don't allow navigation back to login page if already logged in
			return false;
		} else if (!isSessionValid && typeof state.url === 'string' && state.url.toLowerCase().indexOf('/admin/login') > -1) {
			// Allow navigation to login page because session is not valid
			return true;
		} else if (isSessionValid) {
			// Check to see if they have the feature permissions
			if (state.url.toLowerCase() !== '/admin' && !this.authService.hasFeaturePermissions(state.url)) {
				// Navigate to admin home because they don't have the specific feature permissions
				this.router.navigateByUrl('/admin');

				// Don't allow navigation to the admin feature
				return false;
			}

			// Allow navigation
			return true;
		}

		// Navigate to login page
		this.router.navigate(['/admin/login'], {
			queryParams: { redirect: state.url }
		});

		// Don't allow navigation, need to log in first
		return false;
	}

	public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}
