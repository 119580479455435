/** @format */
import { Injectable, Optional } from '@angular/core';

import { LocalStorageConfig } from '../model/local-storage-config.interface';

export const DEFAULT_LOCAL_STORAGE_CONFIG: LocalStorageConfig = {
	allowNull: true,
	prefix: 'BSB_NGX_'
};

@Injectable()
export class LocalStorageService {
	private _isAvailable: boolean;
	private _prefix: string;
	private _allowNull: boolean;

	constructor(@Optional() config?: LocalStorageConfig) {
		// Set the availability flag
		this._isAvailable = typeof localStorage !== 'undefined';

		if (config) {
			this._prefix = typeof config.prefix !== 'undefined' && config.prefix !== null ? config.prefix : DEFAULT_LOCAL_STORAGE_CONFIG.prefix;
			this._allowNull = config.allowNull || DEFAULT_LOCAL_STORAGE_CONFIG.allowNull;
		} else {
			this._prefix = DEFAULT_LOCAL_STORAGE_CONFIG.prefix;
			this._allowNull = DEFAULT_LOCAL_STORAGE_CONFIG.allowNull;
		}
	}

	/**
	 * @description
	 * Gets the entry specified by the given key or null
	 *
	 * @param key - Key identifying the wanted entry
	 * @param prefix - Optional prefix to overwrite the configured one
	 * @returns The value for a given key/prefix
	 */
	public get(key: string, prefix?: string): string | null | undefined {
		// Check to see if localStorage is available
		if (this._isAvailable) {
			prefix = typeof prefix !== 'undefined' && prefix !== null ? prefix : this._prefix;

			try {
				return localStorage.getItem(`${prefix}${key}`);
			} catch (error) {
				throw new Error('Error getting key from localStorage');
			}
		}
	}

	/**
	 * @description
	 * Removes the entry specified by the given key
	 *
	 * @param key - Key identifying the entry to remove
	 * @param prefix - Optional prefix to overwrite the configured one
	 */
	public remove(key: string, prefix?: string): void {
		// Check to see if localStorage is available
		if (this._isAvailable) {
			prefix = typeof prefix !== 'undefined' && prefix !== null ? prefix : this._prefix;

			try {
				localStorage.removeItem(`${prefix}${key}`);
			} catch (error) {
				throw new Error('Error removing key from localStorage');
			}
		}
	}

	/**
	 * @description
	 * Clears all entries of the applications local storage
	 */
	public removeAll(): void {
		// Check to see if localStorage is available
		if (this._isAvailable) {
			try {
				localStorage.clear();
			} catch (error) {
				throw new Error('Error clearing localStorage');
			}
		}
	}

	/**
	 * @description
	 * Adds tha value with the given key or updates an existing entry
	 *
	 * @param key - Key to store
	 * @param value - Value to store
	 * @param prefix - Optional prefix to overwrite the configured one
	 */
	public set(key: string, value: string, prefix?: string): void {
		// Check to see if localStorage is available
		if (this._isAvailable) {
			prefix = typeof prefix !== 'undefined' && prefix !== null ? prefix : this._prefix;

			if (this._allowNull || (!this._allowNull && value !== 'null' && value !== null && value !== undefined)) {
				localStorage.setItem(`${prefix}${key}`, value);
			} else {
				this.remove(key, prefix);
			}
		}
	}
}
