<!-- Content -->
<div id="content">
	<!-- Page Title -->
	<div class="page-title page-title-lg cover-image">
		<picture>
			<img ngSrc="assets/img/Big-Shot-Bobs-Sign-cropped-1280x350-black-white.jpg" alt="The Original Big Shot Bob's House of Wings faux Iron City billboard" priority="true" fill />
		</picture>
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h1 class="mb-0 text-center">Error 404</h1>
					<h3 class="mb-0 text-center">
						Sorry! The page you are looking for cannot be found.
					</h3>
					<div>
						<a class="btn btn-primary mt-2" href="/"
							><span>Back to Home</span></a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Content / End -->
