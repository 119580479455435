/** @format */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '@bsb/ui/home';
import { NotFoundComponent } from '@bsb/ui/not-found';

import { HTTP_INTERCEPTOR_PROVIDERS } from './app.interceptors';

const ROUTES: Routes = [
	{ path: '', component: HomeComponent, pathMatch: 'full' },
	{
		path: 'about-us',
		loadChildren: async () => {
			const m = await import('@bsb/ui/about-us');
			return m.AboutUsModule;
		}
	},
	{
		path: 'account',
		loadChildren: async () => {
			const m = await import('@bsb/ui/account');
			return m.AccountModule;
		}
	},
	{
		path: 'admin',
		data: { breadcrumb: 'Admin Root' },
		loadChildren: async () => {
			const m = await import('@bsb/ui/admin');
			return m.AdminModule;
		}
	},
	{
		path: 'contact',
		loadChildren: async () => {
			const m = await import('@bsb/ui/contact-us');
			return m.ContactUsModule;
		}
	},
	{
		path: 'faq',
		loadChildren: async () => {
			const m = await import('@bsb/ui/faq');
			return m.FaqModule;
		}
	},
	{
		path: 'menu',
		loadChildren: async () => {
			const m = await import('@bsb/ui/menu');
			return m.MenuModule;
		}
	},
	{
		path: 'legal/privacy',
		loadChildren: async () => {
			const m = await import('@bsb/ui/privacy-policy');
			return m.PrivacyPolicyModule;
		}
	},
	{
		path: 'legal/cookies',
		loadChildren: async () => {
			const m = await import('@bsb/ui/cookie-policy');
			return m.CookiePolicyModule;
		}
	},
	{
		path: 'legal/requests',
		loadChildren: async () => {
			const m = await import('@bsb/ui/privacy-requests');
			return m.PrivacyRequestsModule;
		}
	},
	{
		path: 'legal/terms-of-use',
		loadChildren: async () => {
			const m = await import('@bsb/ui/terms-and-conditions');
			return m.TermsAndConditionsModule;
		}
	},
	{
		path: 'password',
		loadChildren: async () => {
			const m = await import('@bsb/ui/password-reset');
			return m.PasswordResetModule;
		}
	},
	{
		path: 'sauce',
		loadChildren: async () => {
			const m = await import('@bsb/ui/sauce');
			return m.SauceModule;
		}
	},
	{
		path: 'shop',
		loadChildren: async () => {
			const m = await import('@bsb/ui/shop');
			return m.ShopModule;
		}
	},
	{
		path: 'wing-finder',
		loadChildren: async () => {
			const m = await import('@bsb/ui/wing-picker');
			return m.WingPickerModule;
		}
	},
	{ path: '**', component: NotFoundComponent }
];

@NgModule({
	declarations: [],
	imports: [
		RouterModule.forRoot(ROUTES, {
			scrollPositionRestoration: 'enabled',
			initialNavigation: 'enabledNonBlocking',
			anchorScrolling: 'disabled',
			useHash: false
		})
	],
	exports: [RouterModule],
	providers: [...HTTP_INTERCEPTOR_PROVIDERS]
})
export class AppRoutingModule {}
