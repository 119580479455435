/** @format */
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { IEnvironmentClient } from '@bsb/shared/schema/interface';
import { DEVICE_CHANNELS, DeviceService, IMenu, IMenuCategory, IMenuCategoryDisplay, IMenuItem, MenuService } from '@bsb/ui/core';
import { environment } from '@bsb/ui/env';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	public bestSellersCategory: IMenuCategoryDisplay;

	public environment: IEnvironmentClient = environment;

	public isAtLeastOneMenuCategoryActive = false;

	public isDoneParsingMenuCategories = false;

	public isMobileBreakPoint = false;

	public menuCarouselImagesAreLoaded = false;

	public menuCategoryActiveCount = 0;

	public menuCategoryLoadedImageCount = 0;

	public menuCategoriesActive: IMenuCategory[];

	public weeklyPicksCategory: IMenuCategoryDisplay;

	constructor(private deviceService: DeviceService, private menuService: MenuService, @Inject(PLATFORM_ID) protected platformId: any) {}

	ngOnInit() {
		// Get the device breakpoint
		this.isMobileBreakPoint = this.deviceService.getDeviceChannelByBreakPoint() === DEVICE_CHANNELS.MOBILE;

		/*
		 * Main Menu
		 */
		this.loadMainFoodMenu();

		/*
		 * Best Sellers
		 */
		this.loadBestSellersFoodMenu();

		/*
		 * Weekly Picks
		 */
		this.loadWeeklyPicksFoodMenu();
	}

	public onCarouselImageLoad(): void {
		// Increment the counter
		this.menuCategoryLoadedImageCount += 1;

		// Compare the counts
		if (this.menuCategoryLoadedImageCount === this.menuCategoryActiveCount) {
			// Set the flag to true
			this.menuCarouselImagesAreLoaded = true;

			// Spawn to a new thread
			setTimeout(() => {
				// Initialize Slick carousel
				$('.menu-carousel').slick({
					dots: true,
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					responsive: [
						{
							breakpoint: 991,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 1
							}
						},
						{
							breakpoint: 690,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
						}
					]
				});
			}, 0);
		}
	}

	/**
	 * @description
	 * Loads the main food menu and builds the category carousel
	 */
	private loadMainFoodMenu(): void {
		this.menuService.getMenuByType$('food-main').subscribe((menu: IMenu) => {
			// Ensure we got a valid Menu
			if (typeof menu?._id === 'string') {
				// Get the menu categories
				this.menuService.getMenuCategoriesByMenuId$(menu._id).subscribe((menuCategories: IMenuCategory[]) => {
					// Set the active menu categories
					this.menuCategoriesActive = menuCategories.filter((menuCategory: IMenuCategory) => {
						// Check to see if the current menu category is active
						if (menuCategory.active) {
							// Set the flag to true
							this.isAtLeastOneMenuCategoryActive = true;

							// Increment the counter
							this.menuCategoryActiveCount += 1;

							// Return true
							return true;
						}

						// Return false
						return false;
					});

					// Set the flag to true
					this.isDoneParsingMenuCategories = true;
				});
			}
		});
	}

	/**
	 * @description
	 * Loads the bestsellers food menu
	 */
	private loadBestSellersFoodMenu(): void {
		this.menuService.getMenuByType$('food-bestseller').subscribe((menu) => {
			// Ensure we got a valid Menu
			if (typeof menu?._id === 'string') {
				// Get the menu categories
				this.menuService.getMenuCategoriesByMenuId$(menu._id).subscribe((menuCategories: IMenuCategory[]) => {
					// Ensure there are active categories
					if (Array.isArray(menuCategories) && menuCategories.length > 0) {
						// Set the menu category
						this.bestSellersCategory = Object.assign({ menuItems: undefined }, menuCategories[0]);

						// Get the menu items for the category
						this.menuService.getMenuItemsByCategoryId$(menuCategories[0]._id, true).subscribe((menuItems: IMenuItem[]) => {
							// Set the menu items
							this.bestSellersCategory.menuItems = Object.assign([], menuItems);
						});
					}
				});
			}
		});
	}

	/**
	 * @description
	 * Loads the weekly picks food menu
	 */
	private loadWeeklyPicksFoodMenu(): void {
		this.menuService.getMenuByType$('food-weekly').subscribe((menu) => {
			// Ensure we got a valid Menu
			if (typeof menu?._id === 'string') {
				// Get the menu categories
				this.menuService.getMenuCategoriesByMenuId$(menu._id).subscribe((menuCategories: IMenuCategory[]) => {
					// Ensure there are active categories
					if (Array.isArray(menuCategories) && menuCategories.length > 0) {
						// Set the menu category
						this.weeklyPicksCategory = Object.assign({ menuItems: undefined }, menuCategories[0]);

						// Get the menu items for the category
						this.menuService.getMenuItemsByCategoryId$(menuCategories[0]._id, true).subscribe((menuItems: IMenuItem[]) => {
							// Set the menu items
							this.weeklyPicksCategory.menuItems = Object.assign([], menuItems);
						});
					}
				});
			}
		});
	}
}
