/** @format */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../service/auth.service';

@Injectable()
export class AccountAuthGuard {
	constructor(private authService: AuthService, private router: Router) {}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// Set the flag if the session is valid
		const isSessionValid = this.authService.isSessionValid();

		// Check to see if logged in and where user is trying to navigate to
		if (
			isSessionValid &&
			typeof state.url === 'string' &&
			(state.url.toLowerCase().indexOf('/account/login') > -1 || state.url.toLowerCase().indexOf('/account/register') > -1)
		) {
			// Navigate to account dashboard page
			this.router.navigateByUrl('/account');

			// Don't allow navigation back to login or registration if already logged in
			return false;
		} else if (!isSessionValid && typeof state.url === 'string' && state.url.toLowerCase().indexOf('/account/login') > -1) {
			// Allow navigation
			return true;
		} else if (!isSessionValid && typeof state.url === 'string' && state.url.toLowerCase().indexOf('/account/register') > -1) {
			// Allow navigation
			return true;
		} else if (isSessionValid) {
			// Check to see if they have the feature permissions
			if (state.url.toLowerCase() !== '/account' && !this.authService.hasFeaturePermissions(state.url)) {
				// Navigate to account dashboard page
				this.router.navigateByUrl('/account');

				// Don't allow navigation back to login if already logged in
				return false;
			}

			// Allow navigation
			return true;
		}

		// Navigate to login page
		this.router.navigate(['/account/login'], {
			queryParams: { redirect: state.url }
		});

		// Don't allow navigation, need to log in first
		return false;
	}

	public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}
