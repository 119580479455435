/** @format */

import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';

/*
 * Menu
 */

export interface IMenu {
	active: boolean;
	createdAt?: string;
	description: string;
	restaurant: string;
	title: string;
	type: string;
	typeDisplay?: string;
	updatedAt?: string;
	__v?: number;
	_id?: string;
}

/*
 * Menu Category
 */

export interface IMenuCategory {
	active: boolean;
	createdAt?: string;
	description: string;
	homeImage: string;
	image: string;
	menuId: string;
	menuDisplay?: string;
	menuItemCount?: number;
	order: number;
	restaurant: string;
	title: string;
	updatedAt?: string;
	__v?: number;
	_id?: string;
}

export interface IMenuCategoryDisplay extends IMenuCategory {
	imageSmall?: string;
	menuItems: IMenuItem[];
}

export type MenuCategoryDisplayPanel<T> = {
	[K in keyof T]: IMenuCategoryDisplayPanelValue<T[K]>;
};

export interface IMenuCategoryDisplayPanelValue<T> {
	opened: boolean;
}

/*
 * Menu Item
 */

export interface IMenuItem {
	active: boolean;
	createdAt?: string;
	description: string;
	menuCategory: string | IMenuCategory;
	menuCategoryDisplay?: string;
	meta?: {
		icon?: string;
		flavors?: string[];
		heat?: string[];
		finish?: string[];
	};
	order: number;
	price?: number;
	restaurant: string;
	signature: boolean;
	favorite?: boolean;
	listed?: boolean;
	title: string;
	updatedAt?: string;
	__v?: number;
	_id?: string;
}

export const WING_OPTIONS_MULTI: { [key: string]: IMultiSelectOption[] } = {
	finish: [
		{
			name: 'Dry',
			id: 'dry'
		},
		{
			name: 'Wet',
			id: 'wet'
		},
		{
			name: 'Buttered',
			id: 'buttered'
		}
	],
	heat: [
		{
			name: 'Savory',
			id: 'savory'
		},
		{
			name: 'Sweet',
			id: 'sweet'
		},
		{
			name: 'Mild',
			id: 'mild'
		},
		{
			name: 'Medium',
			id: 'medium'
		},
		{
			name: 'Hot',
			id: 'hot'
		},
		{
			name: 'Insane',
			id: 'insane'
		}
	],
	flavors: [
		{
			name: 'Atomic Dust',
			id: 'atomic dust'
		},
		{
			name: 'Black & Gold',
			id: 'black & gold'
		},
		{
			name: 'Black Magic',
			id: 'black magic'
		},
		{
			name: 'BBQ',
			id: 'bbq'
		},
		{
			name: 'Bleu Cheese',
			id: 'bleu cheese'
		},
		{
			name: 'BSB Seasoning',
			id: 'bsb seasoning'
		},
		{
			name: 'Buffalo',
			id: 'buffalo'
		},
		{
			name: 'Butter',
			id: 'butter'
		},
		{
			name: 'Cajun',
			id: 'cajun'
		},
		{
			name: 'Cayenne',
			id: 'cayenne'
		},
		{
			name: 'Chili',
			id: 'chili'
		},
		{
			name: 'Chipotle',
			id: 'chipotle'
		},
		{
			name: 'Coffee',
			id: 'coffee'
		},
		{
			name: 'Dill',
			id: 'dill'
		},
		{
			name: 'Garlic',
			id: 'garlic'
		},
		{
			name: 'Honey',
			id: 'honey'
		},
		{
			name: 'Honey BBQ',
			id: 'honey bbq'
		},
		{
			name: 'Honey Mustard',
			id: 'honey mustard'
		},
		{
			name: 'Hot Sauce',
			id: 'hot sauce'
		},
		{
			name: "Frank's",
			id: 'franks'
		},
		{
			name: 'Jalapeno',
			id: 'jalapeno'
		},
		{
			name: 'Jerk',
			id: 'jerk'
		},
		{
			name: 'Lemmon Pepper',
			id: 'lemmon pepper'
		},
		{
			name: 'Lime',
			id: 'lime'
		},
		{
			name: "Lowry's",
			id: 'lowrys'
		},
		{
			name: 'Maple',
			id: 'maple'
		},
		{
			name: 'Molasses',
			id: 'molasses'
		},
		{
			name: 'Old Bay',
			id: 'old bay'
		},
		{
			name: 'Parmesan',
			id: 'parmesan'
		},
		{
			name: 'Pepper',
			id: 'pepper'
		},
		{
			name: 'Peppercorn',
			id: 'peppercorn'
		},
		{
			name: 'Ranch',
			id: 'ranch'
		},
		{
			name: 'Salt',
			id: 'salt'
		},
		{
			name: 'Teriyaki',
			id: 'teriyaki'
		},
		{
			name: 'Vinegar',
			id: 'vinegar'
		}
	],
	icons: [
		{
			name: 'Hot',
			id: 'hot'
		},
		{
			name: 'Award Winning',
			id: 'award-winning'
		},
		{
			name: 'Popular',
			id: 'popular'
		},
		{
			name: 'New',
			id: 'new'
		},
		{
			name: 'Sweet',
			id: 'sweet'
		},
		{
			name: 'Spicy',
			id: 'spicy'
		},
		{
			name: 'Sure Shots',
			id: 'sure-shots'
		},
		{
			name: 'Classic Flavor',
			id: 'classic-flavor'
		}
	]
};

export const WING_OPTIONS = {
	finish: [
		{
			value: 'Dry',
			key: 'dry'
		},
		{
			value: 'Wet',
			key: 'wet'
		},
		{
			name: 'Buttered',
			id: 'buttered'
		}
	],
	heat: [
		{
			value: 'Savory',
			key: 'savory'
		},
		{
			value: 'Sweet',
			key: 'sweet'
		},
		{
			value: 'Mild',
			key: 'mild'
		},
		{
			value: 'Medium',
			key: 'medium'
		},
		{
			value: 'Hot',
			key: 'hot'
		},
		{
			value: 'Insane',
			key: 'insane'
		}
	],
	flavors: [
		{
			value: 'Atomic Dust',
			key: 'atomic dust'
		},
		{
			value: 'Black & Gold',
			key: 'black & gold'
		},
		{
			value: 'Black Magic',
			key: 'black magic'
		},
		{
			value: 'BBQ',
			key: 'bbq'
		},
		{
			value: 'Bleu Cheese',
			key: 'bleu cheese'
		},
		{
			value: 'BSB Seasoning',
			key: 'bsb seasoning'
		},
		{
			value: 'Buffalo',
			key: 'buffalo'
		},
		{
			value: 'Butter',
			key: 'butter'
		},
		{
			value: 'Cajun',
			key: 'cajun'
		},
		{
			value: 'Cayenne',
			key: 'cayenne'
		},
		{
			value: 'Chili',
			key: 'chili'
		},
		{
			value: 'Chipotle',
			key: 'chipotle'
		},
		{
			value: 'Coffee',
			key: 'coffee'
		},
		{
			value: 'Dill',
			key: 'dill'
		},
		{
			value: 'Garlic',
			key: 'garlic'
		},
		{
			value: 'Honey',
			key: 'honey'
		},
		{
			value: 'Honey BBQ',
			key: 'honey bbq'
		},
		{
			value: 'Honey Mustard',
			key: 'honey mustard'
		},
		{
			value: 'Hot Sauce',
			key: 'hot sauce'
		},
		{
			value: "Frank's",
			key: 'franks'
		},
		{
			value: 'Jalapeno',
			key: 'jalapeno'
		},
		{
			value: 'Jerk',
			key: 'jerk'
		},
		{
			value: 'Lemmon Pepper',
			key: 'lemmon pepper'
		},
		{
			value: 'Lime',
			key: 'lime'
		},
		{
			value: "Lowry's",
			key: 'lowrys'
		},
		{
			value: 'Maple',
			key: 'maple'
		},
		{
			value: 'Molasses',
			key: 'molasses'
		},
		{
			value: 'Old Bay',
			key: 'old bay'
		},
		{
			value: 'Parmesan',
			key: 'parmesan'
		},
		{
			value: 'Pepper',
			key: 'pepper'
		},
		{
			value: 'Peppercorn',
			key: 'peppercorn'
		},
		{
			value: 'Ranch',
			key: 'ranch'
		},
		{
			value: 'Salt',
			key: 'salt'
		},
		{
			value: 'Teriyaki',
			key: 'teriyaki'
		},
		{
			value: 'Vinegar',
			key: 'vinegar'
		}
	],
	icons: [
		{
			value: 'Hot',
			key: 'hot'
		},
		{
			value: 'Award Winning',
			key: 'award-winning'
		},
		{
			value: 'Popular',
			key: 'popular'
		},
		{
			value: 'New',
			key: 'new'
		},
		{
			value: 'Sweet',
			key: 'sweet'
		},
		{
			value: 'Spicy',
			key: 'spicy'
		},
		{
			value: 'Sure Shots',
			key: 'sure-shots'
		},
		{
			value: 'Classic Flavor',
			key: 'classic-flavor'
		}
	]
};
