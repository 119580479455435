/** @format */
import { Component } from '@angular/core';

import { environment } from '@bsb/ui/env';

@Component({
	selector: 'app-chow-now',
	templateUrl: './chow-now.component.html',
	styleUrls: ['./chow-now.component.scss']
})
export class ChowNowComponent {
	/*
	 * Public properties
	 */
	public environment = environment;

	constructor() {}
}
