<div id="panel-cart">
	<div class="panel-cart-container">
		<div class="panel-cart-title">
			<h5 class="title">Your Cart</h5>
			<button class="close" data-toggle="panel-cart">
				<i class="ti ti-close"></i>
			</button>
		</div>
		<div class="panel-cart-content">
			<app-alert-message
				[messageBody]="alertMessage?.messageBody"
				[messageTitle]="alertMessage?.messageTitle"
				[messageLevel]="alertMessage?.messageLevel"
				[closable]="alertMessage?.closable"
				(alertClose)="clearAlertMessage()"
				*ngIf="alertMessage$ | async as alertMessage; else noAlertMessage"
			></app-alert-message>
			<div *ngIf="cart?.lineItems?.length > 0; else emptyCart">
				<table class="table-cart">
					<tr *ngFor="let lineItem of cart.lineItems">
						<td class="title">
							<span class="name">
								<a [href]="'/shop/' + lineItem?.variant?.product?.handle">
									{{ lineItem?.title }}
								</a>
							</span>
							<span class="caption" *ngIf="lineItem?.variant?.title !== '' && lineItem?.variant?.title?.toLowerCase() !== 'default title'">
								{{ lineItem?.variant?.title }}
							</span>
						</td>
						<td class="quantity">
							<button class="action-icon" (click)="decrementLineItemQuantity(lineItem)">
								<i class="ti ti-minus"></i>
							</button>
							<span class="value">
								{{ lineItem?.quantity }}
							</span>
							<button class="action-icon" (click)="incrementLineItemQuantity(lineItem)">
								<i class="ti ti-plus"></i>
							</button>
						</td>
						<td class="price">
							{{ lineItem?.variant?.price * lineItem?.quantity | currency }}
						</td>
						<td class="actions">
							<button class="action-icon" (click)="removeLineItemFromCart(lineItem)">
								<i class="ti ti-close"></i>
							</button>
						</td>
					</tr>
				</table>
				<div class="cart-summary">
					<div class="row">
						<div class="col-7 text-right">
							Order subtotal:
						</div>
						<div class="col-5">
							<strong>{{ (cart.subtotalPrice?.amount ? cart.subtotalPrice.amount : cart.subtotalPrice) | currency }}</strong>
						</div>
					</div>
					<div class="row" *ngIf="cart.requiresShipping">
						<div class="col-7 text-right">
							Estimated Shipping:
						</div>
						<div class="col-5">
							<strong>{{ estimatedShipping | currency }}</strong>
						</div>
					</div>
					<div class="row" *ngIf="!cart.taxExempt">
						<div class="col-7 text-right">Taxes:</div>
						<div class="col-5" *ngIf="cart.taxesIncluded">
							<strong>{{ cart.totalTax | currency }}</strong>
						</div>
						<div class="col-5" *ngIf="!cart.taxesIncluded">
							<strong>Calculated in checkout</strong>
						</div>
					</div>
					<hr class="hr-sm" />
					<div class="row text-lg">
						<div class="col-7 text-right">Order Total:</div>
						<div class="col-5">
							<strong>{{ cartTotal | currency }}</strong>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<a [href]="cart?.webUrl" class="panel-cart-action btn btn-secondary btn-block btn-lg" [ngClass]="{ disabled: isCartUpdateOccurring$ | async }" *ngIf="cart?.lineItems?.length > 0">
		<span>Go to checkout</span>
		<img class="page-loading-indicator" src="assets/img/spinner.svg" aria-hidden="true" *ngIf="isCartUpdateOccurring$ | async"/>
	</a>
</div>

<ng-template #emptyCart>
	<div class="alert alert-danger">
		<h4 class="mb-2">There are not any products in your cart.</h4>
		<div>
			Head over to the <a href="shop" class="underline">Shop</a> page!
		</div>
	</div>
</ng-template>

<ng-template #noAlertMessage><div></div></ng-template>
