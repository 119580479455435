/** @format */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, makeStateKey, StateKey, TransferState } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { CustomHttpHeaders, MediaType } from '@bsb/ui/core';

import { IBlogPost } from './blog.interface';

@Injectable()
export class BlogDao {
	/**
	 * @description
	 * State key for all Blog Posts
	 */
	readonly STATE_KEY_ALL_BLOG_POSTS = 'BLOG-POSTS-ALL';

	/**
	 * @description
	 * State key for all Blog Posts
	 */
	readonly STATE_KEY_BLOG_POST = 'BLOG-POST-';

	constructor(private http: HttpClient, private state: TransferState) {}

	/**
	 * @description
	 * Creates a new blogpost and returns the created document
	 */
	public createBlogPost$(blogPost: IBlogPost, retries = 0): Observable<IBlogPost> {
		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.post('/api/blog-posts', blogPost, httpOptions).pipe(
			map((response: IBlogPost) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Deletes a blogpost by ID
	 */
	public deleteBlogPost$(blogPostId: string, retries = 0): Observable<IBlogPost> {
		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.delete(`/api/blog-posts/${blogPostId}`, httpOptions).pipe(
			map((response: IBlogPost) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Gets the entire blogposts collection
	 * @param sortByNewestFirst
	 * @param activeFilter
	 * @param bypassState This is primarily used for admin actions due to this call being made by the root component for displaying the posts in the footer
	 * and that data being cached to state, so it does not get the latest data without a hard refresh to the server (e.g., navigating from CREATE to LIST). This
	 * lets that bypass state so that it will always get the latest data from the database.
	 * @param retries
	 */
	public getAllBlogPosts$(sortByNewestFirst = false, activeFilter = false, bypassState = false, retries = 0): Observable<IBlogPost[]> {
		// Get unique key
		const STATE_KEY: StateKey<IBlogPost[]> = makeStateKey<IBlogPost[]>(this.STATE_KEY_ALL_BLOG_POSTS);

		// Check to see if state should be bypassed
		if (!bypassState) {
			// Attempt to get the state by key
			const stateData: IBlogPost[] = this.state.get<IBlogPost[]>(STATE_KEY, null);

			// Check to see if state exists
			if (stateData) {
				// Return the existing state
				return new Observable((observer) => {
					observer.next(stateData);
					observer.complete();
				});
			}
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// Query String Parameters
		let httpParams = new HttpParams();

		// Check if they should be sorted by newest first (reverse order)
		if (sortByNewestFirst) {
			httpParams = httpParams.set('sortField', 'createdAt').set('sortValue', '-1');
		}

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams
		};

		// Make the HTTP Request
		return this.http.get('/api/blog-posts', httpOptions).pipe(
			map((response: IBlogPost[]) => {
				let documents: IBlogPost[] = response;

				// Check to see if we need to filter for active documents
				if (activeFilter) {
					// Filter out the active documents
					documents = documents.filter((document: IBlogPost) => {
						return document.active === true;
					});
				}

				// Check to see if state should be bypassed
				if (!bypassState) {
					// Set the state
					this.state.set<IBlogPost[]>(STATE_KEY, documents);
				}

				// Return the raw data
				return documents;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Gets the a blog post by ID
	 */
	public getBlogPost$(postId: string, retries = 0): Observable<IBlogPost> {
		// Get unique key
		const STATE_KEY: StateKey<IBlogPost> = makeStateKey<IBlogPost>(`${this.STATE_KEY_BLOG_POST}${postId}`);

		// Attempt to get the state by key
		const stateData: IBlogPost = this.state.get<IBlogPost>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.get(`/api/blog-posts/${postId}`, httpOptions).pipe(
			map((blogPosts: IBlogPost) => {
				// Set the state
				this.state.set<IBlogPost>(STATE_KEY, blogPosts);

				// Return the raw data
				return blogPosts;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Updates a blog post by ID
	 */
	public updateBlogPost$(blogPost: IBlogPost, retries = 0): Observable<IBlogPost> {
		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.put(`/api/blog-posts/${blogPost._id}`, blogPost, httpOptions).pipe(
			map((response: IBlogPost) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}
}
