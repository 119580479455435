/** @format */

export enum LogLevel {
	TRACE = 0,
	DEBUG,
	INFO,
	LOG,
	WARN,
	ERROR,
	OFF
}
