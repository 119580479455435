/** @format */
import { Injectable } from '@angular/core';
import { AppState, AppStateProperties } from '@bsb/ui/state';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LocalStorageService } from '@bsb/ui/core';

import { AuthDao } from '../dao/auth.dao';
import { IUser } from '../model/user.interface';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	// eslint-disable-next-line no-unused-vars
	constructor(private state: AppState, private authDao: AuthDao, private localStorageService: LocalStorageService) {}

	public initialize(): Promise<any> {
		// Session valid flag
		const isSessionValid = this.isSessionValid();

		// Check to see if currently logged in
		if (isSessionValid) {
			// Set the logged in status
			this.state.set(AppStateProperties.LOGGED_IN, true);

			// Get the user
			const user: IUser = this.getUserFromLocalStorage();

			// Set the user to app state
			this.state.set(AppStateProperties.USER, user);

			// Return a resolved Promise because there is nothing async to do
			return Promise.resolve();
		} else if (!isSessionValid && this.hadPreviousSession()) {
			// Login not valid, but had previous session. Get the user
			const user: IUser = this.getUserFromLocalStorage();

			// Renew the session
			return this.renew$(user?._id, user?.refreshToken);
		} else {
			// Return a resolved Promise because there is nothing async to do
			return Promise.resolve();
		}
	}

	/**
	 * @description
	 * Returns the auth token if a user is currently initialized
	 */
	public getAuthToken(): string | undefined {
		// Auth token
		let authToken;

		// Get the user from app state
		// const user: IUser = this.state.value.USER;
		// Get the user
		const user: IUser = this.getUserFromLocalStorage();

		// Check to see if the user has been initialized
		if (typeof user !== 'undefined' && user !== null) {
			// Set the auth token
			authToken = user.token;
		}

		// Return the token
		return authToken;
	}

	/**
	 * @description
	 * Returns the current User
	 */
	public getCurrentUser(): IUser | undefined {
		return this.getUserFromLocalStorage();
	}

	/**
	 * @description
	 * Determines whether the user had a previous session
	 */
	public hadPreviousSession(): boolean {
		// Return flag
		let returnFlag = false;

		// Get the user
		const user: IUser = this.getUserFromLocalStorage();

		// Check to see if there is a valid user
		if (typeof user !== 'undefined' && user !== null && typeof user._id === 'string' && user._id.trim() !== '') {
			// There is a user with an ID in localStorage, set the flag to true
			returnFlag = true;
		}

		// Return the value
		return returnFlag;
	}

	public hasFeaturePermissions(url: string): boolean {
		// Permissions flag
		let hasPermissions = false;

		// Get the user
		const user: IUser = this.getUserFromLocalStorage();

		// Ensure there is a logged-in user
		if (
			typeof user !== 'undefined' &&
			user !== null &&
			Array.isArray(user.roles) &&
			user.roles.length > 0 &&
			typeof url === 'string' &&
			url.trim() !== ''
		) {
			// Iterate through the assigned roles
			user.roles.forEach((role: string) => {
				// Check the current role to see if it exists in the path
				if (url.indexOf(role) > -1) {
					// User has permissions, set to true
					hasPermissions = true;
				}
			});
		}

		// Return the flag
		return hasPermissions;
	}

	/**
	 * @description
	 * Determines whether the user is currently logged in or not
	 */
	public isSessionValid(): boolean {
		// Return flag
		let returnFlag = false;

		// Get the user
		const user: IUser = this.getUserFromLocalStorage();

		// Check to see if there is a valid user
		if (typeof user !== 'undefined' && user !== null) {
			// Get the current date as epoch in seconds
			const now = Math.round(Date.now() / 1000);

			// Check to see if the expires Date is a future Date
			if (now < user.expires) {
				returnFlag = true;
			}
		}

		// Return the value
		return returnFlag;
	}

	/**
	 * @description
	 * Logs a user in and returns the user with a valid token.  If successful, updates app state
	 */
	public login$(username: string, password: string, retries = 0, role?: string): Observable<IUser> {
		return this.authDao.login$(username, password, retries, role).pipe(
			tap((user: IUser) => {
				// Set the user to app state
				this.state.set(AppStateProperties.USER, user);

				// Set the logged in status
				this.state.set(AppStateProperties.LOGGED_IN, true);

				// Set the user to local storage
				this.localStorageService.set('USER', JSON.stringify(user));
			})
		);
	}

	/**
	 * @description
	 * Logs a user out by deleting from local storage
	 */
	public logout$(): Observable<boolean> {
		// Return flag
		let returnFlag = false;

		try {
			// Attempt to get the user from local storage
			this.localStorageService.remove('USER');

			// Set the user to app state
			this.state.set(AppStateProperties.USER, undefined);

			// Set the logged in status
			this.state.set(AppStateProperties.LOGGED_IN, false);

			// Set the flag to true
			returnFlag = true;
		} catch (error) {
			throw new Error(`AuthService#logout$ -- ${error}`);
		}

		// Return Observable boolean
		return of(returnFlag);
	}

	/**
	 * @description
	 * Submits the updated password along with the reset token to attempt to update the password.  If the reset token
	 * has expired or a new reset request has been submitted, any previous reset tokens will be invalid.
	 *
	 * @param resetToken
	 * @param password
	 * @param confirmPassword
	 * @param retries
	 */
	public resetPasswordWithResetToken$(resetToken: string, password: string, confirmPassword: string, retries = 0): Observable<boolean> {
		return this.authDao.resetPasswordWithResetToken$(resetToken, password, confirmPassword, retries);
	}

	/**
	 * @description
	 * Submits a request to reset password
	 */
	public requestResetToken$(email: string, retries = 0): Observable<boolean> {
		return this.authDao.requestResetToken$(email, retries);
	}

	/**
	 * @description
	 * Validates a password reset token
	 */
	public validateResetToken$(resetToken: string, retries = 0): Observable<boolean> {
		return this.authDao.validateResetToken$(resetToken, retries);
	}

	/**
	 * @description
	 * Gets a user from local storage
	 */
	private getUserFromLocalStorage(): IUser | undefined {
		let user: IUser;

		try {
			// Attempt to get the user from local storage
			const userString = this.localStorageService.get('USER');

			// Check to see if anything was returned from local storage
			if (typeof userString === 'string' && userString.trim() !== '') {
				// User object
				user = JSON.parse(userString);
			}
		} catch (error) {
			throw new Error(`AuthService#getUserFromLocalStorage: ${error}`);
		}

		return user;
	}

	/**
	 * @description
	 * Renews a users JWT. If successful, updates app state
	 */
	private renew$(id: string, refreshToken: string, retries = 0): Promise<IUser | void> {
		return this.authDao
			.renew$(id, refreshToken, retries)
			.then((user: IUser) => {
				// Set the user to app state
				this.state.set(AppStateProperties.USER, user);

				// Set the logged in status
				this.state.set(AppStateProperties.LOGGED_IN, true);

				// Set the user to local storage
				this.localStorageService.set('USER', JSON.stringify(user));

				// Return the user
				return user;
			})
			.catch(() => {
				// Set the logged in status
				this.state.set(AppStateProperties.LOGGED_IN, false);
			});
	}
}
