/** @format */
import { Component, OnInit } from '@angular/core';

import { HttpService } from '@bsb/ui/core';

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
	// eslint-disable-next-line no-unused-vars
	constructor(private httpService: HttpService) {}

	ngOnInit() {
		this.httpService.setStatusCode(404);
	}
}
