/** @format */
import { IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig, NgOptimizedImage } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { QuillModule } from 'ngx-quill';
import { QuillToolbarConfig } from 'ngx-quill/config';

/*
 * App Modules
 */
import { LogConfig } from '@bsb/shared/schema/model';
import { AuthModule, AuthService } from '@bsb/ui/auth';
import { BlogModule } from '@bsb/ui/blog';
import { CartModule } from '@bsb/ui/cart';
import { BSBCommonModule } from '@bsb/ui/common';
import { CoreModule, GlobalErrorHandler, LocalStorageConfig } from '@bsb/ui/core';
import { HomeComponent } from '@bsb/ui/home';
import { MarketingModule } from '@bsb/ui/marketing';
import { NotFoundComponent } from '@bsb/ui/not-found';
import { ChowNowModule } from '@bsb/ui/order';

/*
 * App Environment
 */
import { environment } from '../environments';

/*
 * App Imports
 */
import { AppComponent } from './app.component';
import { AppRoutingModule as RoutingModule } from './app.routing.module';
import { AppState } from './app.state';

/*
 * App Configs
 */
const LOCAL_STORAGE_CONFIG: LocalStorageConfig = {
	prefix: 'BSB_NGX_'
};

const LOG_CONFIG: LogConfig = {
	clientLoggerLevel: environment.logging.config.clientLoggerLevel,
	xhrLoggerLevel: environment.logging.config.xhrLoggerLevel,
	xhrLoggerUrl: environment.logging.config.xhrLoggerUrl
};

const QUILL_TOOLBAR_OPTIONS: QuillToolbarConfig = [
	['bold', 'italic', 'underline', 'strike'], // toggled buttons
	['blockquote', 'code-block'],
	[{ list: 'ordered' }, { list: 'bullet' }],
	[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
	[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
	[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
	[{ header: [2, 3, 4, 5, 6, false] }],
	[{ align: [] }],
	['clean'] // remove formatting button
];

@NgModule({
	declarations: [AppComponent, HomeComponent, NotFoundComponent],
	imports: [
		BrowserModule,
		AuthModule.forRoot(),
		BlogModule.forRoot(),
		BSBCommonModule,
		ChowNowModule,
		CartModule,
		CoreModule.forRoot(LOCAL_STORAGE_CONFIG, LOG_CONFIG),
		FormsModule,
		MarketingModule.forRoot(),
		NgOptimizedImage,
		QuillModule.forRoot({
			debug: 'warn',
			modules: {
				toolbar: QUILL_TOOLBAR_OPTIONS
			},
			placeholder: 'Type something...',
			readOnly: false,
			theme: 'snow'
		}),
		RoutingModule, // Must go after all modules that export Router configs
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.serviceWorker,
			// Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		})
	],
	providers: [
		AppState,
		{
			provide: APP_INITIALIZER,
			useFactory: (authService: AuthService) => {
				return () => {
					return authService.initialize();
				};
			},
			deps: [AuthService],
			multi: true
		},
		{
			provide: APP_ID,
			useValue: 'bigshot-bobs-ui'
		},
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		{
			provide: IMAGE_CONFIG,
			useValue: {
				breakpoints: [128, 375, 576, 768, 992, 1200, 1500, 2000], // Default: [16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1920, 2048, 3840]
				placeholderResolution: 40
			}
		},
		{
			provide: IMAGE_LOADER,
			useValue: (config: ImageLoaderConfig) => {
				return `https://ogbsb.imgix.net/${config.src}?auto=format&auto=compress&w=${config.width}`;
			}
		},
		provideClientHydration(withNoHttpTransferCache()),
		provideHttpClient(withFetch(), withInterceptorsFromDi())
	],
	schemas: [NO_ERRORS_SCHEMA],
	bootstrap: [AppComponent]
})
export class AppModule {}
