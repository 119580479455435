/** @format */
import { HttpClient } from '@angular/common/http';
import { Injectable, makeStateKey, StateKey, TransferState } from '@angular/core';
import { buildClient, Client, Config } from 'shopify-buy';

import { environment } from '@bsb/ui/env';

import { IAttributeInput, ICart, ICheckout, ICheckoutLineItemInput, IProduct, IProductVariant, IShopifyProduct } from '../model/shop.model';
import { AsyncTaskProcessor } from '../service/async-api-call-helper.service';

@Injectable()
export class ShopDao {
	/**
	 * @description
	 * State key for all Products
	 */
	readonly STATE_KEY_ALL_PRODUCTS = 'PRODUCTS-ALL';

	/**
	 * @description
	 * State key for a single Product
	 */
	readonly STATE_KEY_PRODUCT = 'PRODUCT-';

	/**
	 * @description
	 * Shopify Client
	 */
	private client: Client;

	/**
	 * @description
	 * Shopify Client Config
	 */
	private clientConfig: Config = {
		domain: environment.shopify.domain,
		storefrontAccessToken: environment.shopify.storefrontAccessToken
	};

	// eslint-disable-next-line no-unused-vars
	constructor(private asyncTaskProcessor: AsyncTaskProcessor, private http: HttpClient, private state: TransferState) {
		this.client = buildClient(this.clientConfig);
	}

	/**
	 * @description
	 * Transforms a Shopify Product object into an IProduct object
	 *
	 * @param shopifyProduct
	 */
	// eslint-disable-next-line sonarjs/cognitive-complexity
	static transformShopifyProduct(shopifyProduct: IShopifyProduct): IProduct {
		// Return Product
		let returnProduct: IProduct;

		// Ensure the argument is valid
		if (typeof shopifyProduct !== 'undefined' && shopifyProduct !== null) {
			// Create the base Product
			returnProduct = {
				id: `${shopifyProduct.id}`,
				availableForSale: shopifyProduct.availableForSale,
				createdAt: new Date(shopifyProduct.createdAt),
				updatedAt: new Date(shopifyProduct.updatedAt),
				descriptionHtml: shopifyProduct.descriptionHtml,
				description: shopifyProduct.description,
				handle: shopifyProduct.handle,
				images: [],
				variants: [],
				price: undefined,
				productType: shopifyProduct.productType,
				title: shopifyProduct.title,
				vendor: shopifyProduct.vendor,
				publishedAt: new Date(shopifyProduct.publishedAt),
				onlineStoreUrl: shopifyProduct.onlineStoreUrl
			};

			// Check to see if the current Product has any images
			if (Array.isArray(shopifyProduct.images) && shopifyProduct.images.length > 0) {
				// Iterate the Product images
				for (let i = 0, length = shopifyProduct.images.length; i < length; i += 1) {
					// Current Image
					const currentImage = shopifyProduct.images[i];

					// Perform undefined/null check
					if (typeof currentImage !== 'undefined' && currentImage !== null) {
						// Converted Product image
						const image = {
							id: `${currentImage.id}`,
							src: currentImage.src,
							altText: currentImage.altText
						};

						// Check for the first Product image
						if (i === 0) {
							// Set the display variant as the first one
							returnProduct.displayImage = image;
						}

						// Add the current Product image to the Array
						returnProduct.images.push(image);
					}
				}
			}

			// Check to see if the current Product has any variants
			if (Array.isArray(shopifyProduct.variants) && shopifyProduct.variants.length > 0) {
				// Minimum Price
				let minPrice: number;

				// Maximum Price
				let maxPrice: number;

				// Iterate the Product variants
				for (let i = 0, length = shopifyProduct.variants.length; i < length; i += 1) {
					// Current Variant
					const currentVariant = shopifyProduct.variants[i];

					// Perform undefined/null check
					if (typeof currentVariant !== 'undefined' && currentVariant !== null) {
						// Current Variant price
						let currentVariantPrice: number;

						// Check Shopify response property type first for backwards compatibility after unannounced breaking change found on 05-19-2023
						if (typeof currentVariant.price === 'string') {
							// parsed to 2 decimal float
							currentVariantPrice = parseFloat(parseFloat(currentVariant.price).toFixed(2));
						} else {
							// parsed to 2 decimal float
							currentVariantPrice = parseFloat(parseFloat(currentVariant.price.amount).toFixed(2));
						}

						// Check minimum price
						if (typeof minPrice === 'undefined' || currentVariantPrice < minPrice) {
							minPrice = currentVariantPrice;
						}

						// Check maximum price
						if (typeof maxPrice === 'undefined' || currentVariantPrice > maxPrice) {
							maxPrice = currentVariantPrice;
						}

						// Converted Product Variant
						const variant: IProductVariant = {
							id: `${currentVariant.id}`,
							available: currentVariant.available,
							compareAtPrice: currentVariant.compareAtPrice,
							formattedPrice: currentVariant.formattedPrice,
							grams: currentVariant.grams,
							image: currentVariant.image,
							price: currentVariant.price,
							productId: returnProduct.id,
							productTitle: returnProduct.title,
							sku: currentVariant.sku,
							title: currentVariant.title,
							weight: currentVariant.weight,
							checkoutUrl: currentVariant.checkoutUrl
						};

						// Check for the first Product variant
						if (i === 0) {
							// Set the display variant as the first one
							returnProduct.displayVariant = variant;
						}

						// Add the current Product variant to the Array
						returnProduct.variants.push(variant);
					}
				}

				// Set the Product level price
				returnProduct.price = {
					minPrice,
					maxPrice
				};
			}
		}

		// Return the new Interface
		return returnProduct;
	}

	/**
	 * @description
	 * Adds a Line Item to the Checkout
	 *
	 * @param checkoutID
	 * @param lineItems
	 */
	public addLineItemsToCheckout$(checkoutID: string, lineItems: ICheckoutLineItemInput[]): Promise<ICart> {
		return new Promise<any>((resolve, reject) => {
			// @ts-ignore
			this.asyncTaskProcessor.doTask(this.client.checkout.addLineItems(checkoutID, lineItems)).subscribe({
				next: (response: ICart) => {
					// Resolve the outer Promise
					resolve(response);
				},
				error: (error) => {
					// Reject the outer Promise
					reject(error);
				}
			});
		});
	}

	/**
	 * @description
	 * Creates a Checkout
	 */
	public createCheckout$(): Promise<ICheckout> {
		return new Promise<any>((resolve, reject) => {
			this.asyncTaskProcessor.doTask(this.client.checkout.create()).subscribe({
				next: (response: ICheckout) => {
					// Resolve the outer Promise
					resolve(response);
				},
				error: (error) => {
					// Reject the outer Promise
					reject(error);
				}
			});
		});
	}

	/**
	 * @description
	 * Calls the Shopify API to get all Products in the Shop
	 */
	public getAllProducts$(): Promise<IProduct[]> {
		// Get unique key
		const STATE_KEY: StateKey<IProduct[]> = makeStateKey<IProduct[]>(`${this.STATE_KEY_ALL_PRODUCTS}`);

		// Attempt to get the state by key
		const stateData: IProduct[] = this.state.get<IProduct[]>(STATE_KEY, null);

		// Check to see if state exists
		if (typeof stateData !== 'undefined' && stateData !== null) {
			// Return the existing state
			return Promise.resolve(stateData);
		}

		return new Promise<IProduct[]>((resolve, reject) => {
			this.asyncTaskProcessor.doTask(this.client.product.fetchAll()).subscribe({
				next: (response: IShopifyProduct[]) => {
					// Return Products
					const products: IProduct[] = [];

					// Ensure there is something to iterate
					if (Array.isArray(response) && response.length > 0) {
						// Iterate the Products
						for (let i = 0, length = response.length; i < length; i += 1) {
							// Perform undefined/null check
							if (typeof response[i] !== 'undefined' && response[i] !== null) {
								// Add the current Product to the Array
								products.push(ShopDao.transformShopifyProduct(response[i]));
							}
						}
					}

					// Set the state
					this.state.set<IProduct[]>(STATE_KEY, products);

					// Resolve the outer Promise
					resolve(products);
				},
				error: (error) => {
					// Reject the outer Promise
					reject(error);
				}
			});
		});
	}

	/**
	 * @description
	 * Gets a checkout by ID
	 *
	 * @param checkoutID
	 */
	public getCheckoutByID$(checkoutID: string): Promise<ICart> {
		return new Promise<any>((resolve, reject) => {
			this.asyncTaskProcessor.doTask(this.client.checkout.fetch(checkoutID)).subscribe({
				next: (response: ICart) => {
					// Resolve the outer Promise
					resolve(response);
				},
				error: (error) => {
					// Reject the outer Promise
					reject(error);
				}
			});
		});
	}

	/**
	 * @description
	 * Calls the Shopify API to get a Product in the Shop by handle
	 *
	 * @param handle
	 */
	public getProductByHandle$(handle: string): Promise<IProduct> {
		// Get unique key
		const STATE_KEY: StateKey<IProduct> = makeStateKey<IProduct>(`${this.STATE_KEY_PRODUCT}`);

		// Attempt to get the state by key
		const stateData: IProduct = this.state.get<IProduct>(STATE_KEY, null);

		// Check to see if state exists
		if (typeof stateData !== 'undefined' && stateData !== null) {
			// Return the existing state
			return Promise.resolve(stateData);
		}

		return new Promise<IProduct>((resolve, reject) => {
			this.asyncTaskProcessor.doTask(this.client.product.fetchByHandle(handle)).subscribe({
				next: (response: IShopifyProduct) => {
					// Return Product
					const product: IProduct = ShopDao.transformShopifyProduct(response);

					// Set the state
					this.state.set<IProduct>(STATE_KEY, product);

					// Resolve the outer Promise
					resolve(product);
				},
				error: (error) => {
					// Reject the outer Promise
					reject(error);
				}
			});
		});
	}

	/**
	 * @description
	 * Removes Line Items from Checkout
	 *
	 * @param checkoutID
	 * @param lineItemIDs
	 */
	public removeLineItemsFromCheckout$(checkoutID: string, lineItemIDs: string[]): Promise<ICart> {
		return new Promise<any>((resolve, reject) => {
			// @ts-ignore
			this.asyncTaskProcessor.doTask(this.client.checkout.removeLineItems(checkoutID, lineItemIDs)).subscribe({
				next: (response: ICart) => {
					// Resolve the outer Promise
					resolve(response);
				},
				error: (error) => {
					// Reject the outer Promise
					reject(error);
				}
			});
		});
	}

	/**
	 * @description
	 * Updates Line Items in Checkout
	 *
	 * @param checkoutID
	 * @param lineItems
	 */
	public updateLineItemsInCheckout$(checkoutID: string, lineItems: IAttributeInput[]): Promise<ICheckout> {
		return new Promise<any>((resolve, reject) => {
			// @ts-ignore
			this.asyncTaskProcessor.doTask(this.client.checkout.updateLineItems(checkoutID, lineItems)).subscribe({
				next: (response: ICheckout) => {
					// Resolve the outer Promise
					resolve(response);
				},
				error: (error) => {
					// Reject the outer Promise
					reject(error);
				}
			});
		});
	}
}
