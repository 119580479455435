/** @format */

/* eslint-disable sonarjs/no-duplicated-branches, no-prototype-builtins */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 } from 'uuid';

import { IKeyValue } from '../model/key-value.interface';
import { WINDOW } from './window.service';

@Injectable()
export class UtilityService {
	private renderer: Renderer2;

	constructor(@Inject(DOCUMENT) private document: HTMLDocument, rendererFactory: RendererFactory2, @Inject(WINDOW) private window: Window) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	/**
	 * @returns The new Date
	 *
	 * @description
	 * Adds a specified number of days to a given Date and returns a new Date
	 *
	 * NOTE: MUST pass in a date created with timezone offset, using full UTC format
	 */
	public addDaysToDate(date: string | number | Date, days: number): Date {
		// Create a new Date based on the given Date
		const newDate = new Date(date);

		// Add the specified number of days
		newDate.setDate(newDate.getDate() + days);

		// Return the new Date
		return newDate;
	}

	/**
	 * @description
	 * Utility function to create and return a custom event
	 *
	 * @param eventName - The name of the Event to create
	 * @param eventData - The data to pass to the created Event
	 * @returns The created custom Event
	 */
	public createCustomEvent(eventName: string, eventData?: any): CustomEvent {
		let customEvent;

		if (this.window.CustomEvent) {
			// Create the Event
			customEvent = new CustomEvent(eventName, {
				detail: eventData
			});
		} else {
			// Create the Event
			customEvent = this.document.createEvent('CustomEvent');

			// Initialize the Event
			customEvent.initCustomEvent(eventName, true, true, eventData);
		}
		// Return the custom event
		return customEvent;
	}

	/**
	 * @description
	 * Accepts an argument and attempts to convert its value to a number
	 */
	public convertToNumber(number: string | number | boolean, radix = 10): number | undefined {
		// Return number
		let returnNumber;

		// Check to see if argument is a string
		if (typeof number === 'string' && number.trim() !== '') {
			// Attempt to parse the string
			try {
				const parsedNumber = parseInt(number, radix);

				// Ensure NaN did not occur
				if (!isNaN(parsedNumber)) {
					// Set the number
					returnNumber = parsedNumber;
				} else {
					// Return NaN
					returnNumber = NaN;
				}
			} catch (error) {
				// Swallow the error so undefined will be returned
			}
		} else if (typeof number === 'number') {
			returnNumber = number;
		} else if (typeof number === 'boolean' && number) {
			returnNumber = 1;
		} else if (typeof number === 'boolean' && !number) {
			returnNumber = 0;
		} else {
			// Return NaN because we don't know what to do with it
			returnNumber = NaN;
		}

		// Return the number
		return returnNumber;
	}

	public isFirstDateBeforeSecond(stringDate1: string, stringDate2: string): boolean {
		const date1 = new Date(`${stringDate1}`);
		const date2 = new Date(`${stringDate2}`);

		return date1 < date2;
	}

	/**
	 * @description
	 * Function that polls the global Window and checks when the Core Object is available for use
	 */
	public deferUntilCoreScriptLoads(): Observable<boolean> {
		// Return an Observable
		return new Observable((observer) => {
			// Set up an interval to poll the document.readyState value
			const interval = setInterval(() => {
				// Check to see if the document has completed loading
				if (typeof this.window.Core !== 'undefined') {
					// Document has completed loading, clear the polling interval
					clearInterval(interval);

					// Broadcast true
					observer.next(true);

					// Complete the Observable
					observer.complete();
				} /* else {
					// Broadcast false; NOTE: Temporarily commented now to observe performance without interval checking in various components
					observer.next(false);
				}*/
			}, 100);
		});
	}

	/**
	 * @description
	 * Method that generates a high-fidelity UUID/GUID. It uses an NPM module `uuid` to generate a RFC4122 v4
	 * UUID/GUID string.
	 */
	public generateUUID(): string {
		// Return UUID/GUID
		return v4();
	}

	/**
	 * @description
	 * Filters an array of string by a given input string
	 */
	public filterStringArrayByValue(filterText: string, list: string[]): string[] {
		// Return list
		const returnList: string[] = [];

		// Ensure there is something to filter
		if (Array.isArray(list) && list.length > 0) {
			// Check the filter text
			if (typeof filterText === 'string' && filterText.trim() !== '') {
				// Iterate the list
				for (let i = 0, length = list.length; i < length; i += 1) {
					// Check to see if the filter text exists in the current string
					if (typeof list[i] === 'string' && list[i].trim() !== '' && list[i].toLowerCase().trim().indexOf(filterText.toLowerCase().trim()) > -1) {
						// Add the current string to the list
						returnList.push(list[i]);
					}
				}
			} else {
				// Return the input list as-is
				return list;
			}
		}

		// Return the list
		return returnList;
	}

	/**
	 * @description
	 * Accepts a value to match against, an property to check against, and an Array of objects to check if the item exists.  If any items
	 * exist, they will be returned.  All occurrences of an item will be returned if duplicates are in the Array. If no matches
	 * are found, an empty Array will be returned.
	 */
	/* eslint-disable @typescript-eslint/array-type */
	public findObjectsFromListByProperty(propertyName: string, propertyValue: string | number | boolean, objectList: Array<any>): Array<any> {
		// Return array
		const returnObjectList: Array<any> = [];

		// Check that there are items to iterate
		if (Array.isArray(objectList) && objectList.length > 0) {
			// Iterate the list
			for (let i = 0, length = objectList.length; i < length; i += 1) {
				// Check to see if the property exists on the object by checking native values or stringified values
				if (
					(typeof objectList[i] !== 'undefined' && typeof objectList[i][propertyName] !== 'undefined' && objectList[i][propertyName] === propertyValue) ||
					`${objectList[i][propertyName]}`.toLowerCase().trim().indexOf(`${propertyValue}`.toLowerCase().trim()) > -1
				) {
					// Break to prevent unnecessary looping
					returnObjectList.push(objectList[i]);
				}
			}
		}

		// Return undefined by default
		return returnObjectList;
	}
	/* eslint-enable @typescript-eslint/array-type */

	/**
	 * @description
	 * Accepts a value to match against, an property to check against, and an Array of objects to check if the item exists.  If the item
	 * exists, it will be returned.  Only the first occurrence of an item will be returned if duplicates are in the Array. If no matches
	 * are found, undefined will be returned.
	 */
	/* eslint-disable @typescript-eslint/array-type */
	public findObjectFromListByProperty(propertyName: string, propertyValue: string | number | boolean, objectList: Array<any>): any {
		// Check that there are items to iterate
		if (Array.isArray(objectList) && objectList.length > 0) {
			// Iterate the list
			for (let i = 0, length = objectList.length; i < length; i += 1) {
				// Check to see if the property exists on the object
				if (typeof objectList[i] !== 'undefined' && typeof objectList[i][propertyName] !== 'undefined' && objectList[i][propertyName] === propertyValue) {
					// Break to prevent unnecessary looping
					return objectList[i];
				}
			}
		}

		// Return undefined by default
		return undefined;
	}
	/* eslint-enable @typescript-eslint/array-type */

	/**
	 * @description
	 * Accepts a value to match against, an property to check against, and an Array of objects to check if the item exists.  If the item
	 * exists, its index will be returned.  Only the first occurrence of an item will be returned if duplicates are in the Array. If no matches
	 * are found, -1 will be returned.
	 */
	/* eslint-disable @typescript-eslint/array-type */
	public findObjectIndexFromListByProperty(propertyName: string, propertyValue: string | number | boolean, objectList: Array<any>): number {
		// Default the return index to -1 meaning not found
		let returnIndex = -1;

		// Check that there are items to iterate
		if (Array.isArray(objectList) && objectList.length > 0) {
			// Iterate the list
			for (let i = 0, length = objectList.length; i < length; i += 1) {
				// Check to see if the property exists on the object
				if (typeof objectList[i] !== 'undefined' && typeof objectList[i][propertyName] !== 'undefined' && objectList[i][propertyName] === propertyValue) {
					// Set the index of matched object
					returnIndex = i;

					// Break to prevent unnecessary looping
					break;
				}
			}
		}

		// Return the index
		return returnIndex;
	}
	/* eslint-enable @typescript-eslint/array-type */

	/**
	 * @description
	 * Accepts an item to check and a list of items to check against and determines if the item is in the list or not
	 *
	 * @param itemToCheck
	 * @param itemsToCheckAgainst
	 */
	// eslint-disable-next-line
	public isItemInList(itemToCheck: string | number | boolean, itemsToCheckAgainst: string[] | number[] | boolean[] | any[]): boolean {
		// Ensure arguments are valid
		if (typeof itemToCheck !== 'undefined' && itemToCheck !== null && Array.isArray(itemsToCheckAgainst) && itemsToCheckAgainst.length > 0) {
			// Check if string
			if (typeof itemToCheck === 'string') {
				// Lowercase the string
				itemToCheck = itemToCheck.toLowerCase();
			}

			// Iterate the items to check against
			for (let index = 0, length = itemsToCheckAgainst.length; index < length; index += 1) {
				// Check if string
				if (typeof itemsToCheckAgainst[index] === 'string' && itemsToCheckAgainst[index].toLowerCase() === itemToCheck) {
					// Return match found
					return true;
				} else if (itemsToCheckAgainst[index] === itemToCheck) {
					// Return match found
					return true;
				}
			}
		}

		// There was not a match, return false
		return false;
	}

	/**
	 * @description
	 * Function that accepts an argument and determines if it is Truthy.  It returns false by default.
	 *
	 * @param inArgument - The argument to test if Truthy
	 */
	public isTruthy(inArgument: any): boolean {
		// Default flag to false
		let returnBoolean = false;

		// Test for String
		if (typeof inArgument === 'string') {
			// Normalize the String
			inArgument = inArgument.toLowerCase().trim();

			// Check the value
			if (inArgument === 'true') {
				returnBoolean = true;
			} else if (inArgument === 'yes') {
				returnBoolean = true;
			} else if (inArgument === 'y') {
				returnBoolean = true;
			} else if (inArgument === '1') {
				returnBoolean = true;
			}
		} else if (typeof inArgument === 'boolean') {
			// Check the value
			returnBoolean = inArgument === true;
		} else if (typeof inArgument === 'number') {
			// Check the value
			returnBoolean = inArgument === 1;
		}

		// Return flag
		return returnBoolean;
	}

	public insertStyleSheet(href: string, before?: HTMLElement, fontFiles?: string[], media = 'all') {
		// Get the reference element to insert before
		const referenceElement = before || this.document.getElementsByTagName('script')[0];

		// Create a stylesheet link element
		const styleSheetLink: HTMLLinkElement = this.document.createElement('link');

		// Set the `rel` attribute to 'stylesheet'
		this.renderer.setAttribute(styleSheetLink, 'rel', 'stylesheet');

		// Set the `href` attribute
		this.renderer.setAttribute(styleSheetLink, 'href', href);

		// temporarily, set the `media` attribute to something non-matching to ensure it'll fetch without blocking render
		this.renderer.setAttribute(styleSheetLink, 'media', media);

		// Create a stylesheet preload link element
		const styleSheetPreloadLink: HTMLLinkElement = this.document.createElement('link');

		// Set the `rel` attribute to 'preload'
		this.renderer.setAttribute(styleSheetPreloadLink, 'rel', 'preload');

		// Set the `href` attribute
		this.renderer.setAttribute(styleSheetPreloadLink, 'href', href);

		// Set the `as` attribute
		this.renderer.setAttribute(styleSheetPreloadLink, 'as', 'style');

		// Check to see if any font files were provided
		if (Array.isArray(fontFiles) && fontFiles.length > 0) {
			// Iterate through the font files
			fontFiles.forEach((fontFile) => {
				// Create a preload link element
				const preloadLink: HTMLLinkElement = this.document.createElement('link');

				// Set the `rel` attribute to 'preload'
				this.renderer.setAttribute(preloadLink, 'rel', 'preload');

				// Set the `href` attribute
				this.renderer.setAttribute(preloadLink, 'href', fontFile);

				// Set the `as` attribute
				this.renderer.setAttribute(preloadLink, 'as', 'font');

				// Insert the preload link before the reference
				referenceElement.parentNode.insertBefore(preloadLink, referenceElement);
			});
		}

		// Insert the style sheet preload before the reference
		referenceElement.parentNode.insertBefore(styleSheetPreloadLink, referenceElement);

		// Insert the style sheet before the reference
		referenceElement.parentNode.insertBefore(styleSheetLink, referenceElement);
	}

	/**
	 * @description
	 * Accepts an object and a delimiter as input, and as long as the object is defined and has keys it will iterate them
	 * and build a temporary array which we then use the `join` method on with the delimiter.
	 *
	 * @param inputObject
	 * @param delimeter
	 */
	public joinObjectAsArrayJoin(inputObject: { [key: string]: unknown }, delimeter: string): string {
		// Ensure the input is valid
		if (typeof inputObject === 'object' && Object.keys(inputObject).length > 0) {
			// Return array to be joined
			const tempArray = [];

			// Iterate the keys
			for (const key in inputObject) {
				tempArray.push(`${key}=${inputObject[key]}`);
			}

			return tempArray.join(`${delimeter}`);
		} else {
			// Return empty string
			return '';
		}
	}

	/**
	 * @description
	 * Loads a specified style sheet into the head of the DOM.  If a before argument is provided, it will insert before a
	 * specified node by ID otherwise will default to the first script element.  If a media argument is provided,
	 * it will add the media attribute of that type.
	 *
	 * @param href - The URL of the stylesheet
	 * @param [before] - The HTML Element to insert the stylesheet before
	 * @param [callback] - Callback function to execute once the stylesheet is defined
	 * @param [media] - The media type to apply the stylesheet for
	 */
	public loadStyleSheet(href: string, before?: HTMLElement, callback?: any, media?: string) {
		// Fallback
		// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
		callback =
			callback ||
			function () {
				// Empty callback
			};

		// Create a stylesheet link element
		const styleSheetLink = this.document.createElement('link');

		// Get the reference element to insert before
		const referenceElement = before || this.document.getElementsByTagName('script')[0];

		// Get all the style sheets
		const sheets = this.window.document.styleSheets;

		// Set the `rel` attribute to 'stylesheet'
		styleSheetLink.rel = 'stylesheet';

		// Set the `href` attribute
		styleSheetLink.href = href;

		// temporarily, set the `media` attribute to something non-matching to ensure it'll fetch without blocking render
		styleSheetLink.media = 'only x';

		// Insert the style sheet before the reference
		referenceElement.parentNode.insertBefore(styleSheetLink, referenceElement);

		/*
		 * This function sets the link's media back to `all` so that the stylesheet applies once it loads.
		 * It is designed to poll until document.styleSheets includes the new sheet.
		 */
		styleSheetLink.onloadcssdefined = (cb) => {
			// Flag to determine if the stylesheet is defined in the DOM
			let defined;

			// Iterate through the stylesheets
			for (let i = 0, length = sheets.length; i < length; i++) {
				// Check to see if the current stylesheet href matches ours
				if (sheets[i].href && sheets[i].href === styleSheetLink.href) {
					// Set the flag to true
					defined = true;
				}
			}

			// Check to see if the new stylesheet is defined in the DOM
			if (defined) {
				// Perform the callback
				cb();
			} else {
				// Set a small timeout
				setTimeout(() => {
					// Poll the stylesheets
					styleSheetLink.onloadcssdefined(cb);
				});
			}
		};

		// Call the onload function with a callback to set the media type so that the stylesheet applies
		styleSheetLink.onloadcssdefined(() => {
			// Set the `media` attribute to a matching value
			styleSheetLink.media = media || 'all';

			// Call the callback function
			callback();
		});

		// Return the style sheet
		return styleSheetLink;
	}

	/**
	 * @description
	 * Loads an external JavaScript file into the DOM and will then call an optionally supplied callback if provided
	 *
	 * @param url - URL of the script
	 * @param [attributes] - Optional attributes to set on the script Element
	 * @param [callback] - Optional callback function
	 */
	public loadExternalScript(url: string, attributes?: IKeyValue[], callback?: any): void {
		// Fallback
		// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
		callback =
			callback ||
			function () {
				/* Empty callback */
			};

		// Create a new <script> Element
		const script: HTMLScriptElement = this.document.createElement('script');

		// Set the `async` attribute
		this.renderer.setAttribute(script, 'async', '');

		// Set the `type` attribute
		this.renderer.setAttribute(script, 'type', 'text/javascript');

		// Check to see if there are any additional attributes to set
		if (Array.isArray(attributes) && attributes.length > 0) {
			attributes.forEach((attribute) => {
				// Ensure there is something to set to prevent bad attributes
				if (typeof attribute !== 'undefined') {
					// Set the attribute on the script Element
					this.renderer.setAttribute(script, `${attribute.key}`, `${attribute.value}`);
				}
			});
		}

		// Other browsers (NOT IE)
		script.onload = () => {
			// Call the callback
			callback();
		};

		// Set the `src` attribute
		this.renderer.setAttribute(script, 'src', url);

		// Append the Element to the bottom of the body
		this.document.body.appendChild(script);
	}

	/**
	 * @description
	 * Accepts a value to match against, an property to check against, and an Array of objects to check if the item exists.  If the item
	 * exists, it will be removed from the Array.  A new Array will always be returned.  Only the first occurrence of an item will be removed
	 * if duplicates are in the Array.
	 */
	/* eslint-disable @typescript-eslint/array-type */
	public removeObjectFromListByProperty(propertyName: string, propertyValue: string | number | boolean, objectList: Array<any>): Array<any> {
		const returnArray: Array<any> = Object.assign([], objectList);

		let matchedIndex: number;

		// Check that there are items to iterate
		if (Array.isArray(objectList) && objectList.length > 0) {
			// Iterate the list
			for (let i = 0, length = objectList.length; i < length; i += 1) {
				// Check to see if the property exists on the object
				if (typeof objectList[i] !== 'undefined' && typeof objectList[i][propertyName] !== 'undefined' && objectList[i][propertyName] === propertyValue) {
					// Set the current index
					matchedIndex = i;

					// Break to prevent unnecessary looping
					break;
				}
			}
		}

		// Check to see if a match was found
		if (typeof matchedIndex === 'number') {
			// Remove the item from the Array IN PLACE
			returnArray.splice(matchedIndex, 1);
		}

		return returnArray;
	}
	/* eslint-enable @typescript-eslint/array-type */

	/**
	 * @description
	 * Accepts a value to match against and an Array to check if the value exists.  If the value
	 * exists, it will be removed from the Array.  A new Array will always be returned.  Only the first occurrence of a value will be removed
	 * if duplicates are in the Array.
	 */
	/* eslint-disable @typescript-eslint/array-type */
	public removeValueFromList(value: string | number | boolean, list: Array<string | number | boolean>): Array<any> {
		const returnArray: Array<any> = Object.assign([], list);

		let matchedIndex: number;

		// Check that there are items to iterate
		if (Array.isArray(list) && list.length > 0) {
			// Iterate the list
			for (let i = 0, length = list.length; i < length; i += 1) {
				// Check to see if the property exists on the object
				if (list[i] === value) {
					// Set the current index
					matchedIndex = i;

					// Break to prevent unnecessary looping
					break;
				}
			}
		}

		// Check to see if a match was found
		if (typeof matchedIndex === 'number') {
			// Remove the item from the Array IN PLACE
			returnArray.splice(matchedIndex, 1);
		}

		return returnArray;
	}
	/* eslint-enable @typescript-eslint/array-type */

	/**
	 * @description
	 * takes an element and calls scrollIntoView with options
	 */
	public scrollToElement(element: HTMLElement, options?: ScrollIntoViewOptions): boolean {
		const defaults = {
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest'
		} as ScrollIntoViewOptions;

		options = options || defaults;

		if (typeof element !== 'undefined' && element !== null) {
			element.scrollIntoView({
				behavior: options.behavior,
				block: options.block,
				inline: options.inline
			});

			return true;
		}

		return false;
	}

	/**
	 * @description
	 * Accepts a property to sort against and an Array of objects to sort.  If the property is undefined or does not exist on the objects,
	 * then the provided Array will be returned
	 */
	public sortObjectsInListByProperty(propertyName: string, objectList: object[]): object[] {
		// Check to see if the property is valid and exists on the object or if the list is valid
		if (!Array.isArray(objectList) || objectList.length === 0) {
			// Return an empty Array
			return [];
		} else if (typeof propertyName !== 'string' || propertyName.trim() === '' || !objectList[0].hasOwnProperty(propertyName)) {
			// Return input Array
			return objectList;
		}

		// Return the sorted Array
		return objectList.sort((a, b) => {
			return a[propertyName] > b[propertyName] ? 1 : -1;
		});
	}
}
