/** @format */
import { ModuleWithProviders, NgModule } from '@angular/core';

import { LogConfig } from '@bsb/shared/schema/model';

import { MenuDao } from './dao/menu.dao';
import { ShopDao } from './dao/shop.dao';
import { LocalStorageConfig } from './model/local-storage-config.interface';
import { AsyncTaskProcessor } from './service/async-api-call-helper.service';
import { CookieService } from './service/cookie.service';
import { DeviceService } from './service/device.service';
import { EventBusService } from './service/event-bus.service';
import { HttpService } from './service/http.service';
import { ListService } from './service/list.service';
import { LocalStorageService } from './service/local-storage.service';
import { LogService } from './service/log.service';
import { MenuService } from './service/menu.service';
import { SeoService } from './service/seo.service';
import { ShopService } from './service/shop.service';
import { UtilityService } from './service/utility.service';
import { WINDOW_PROVIDERS } from './service/window.service';

export function localStorageServiceFactory(localStorageConfig: LocalStorageConfig) {
	return new LocalStorageService(localStorageConfig);
}

@NgModule({
	providers: [MenuDao, ShopDao]
})
export class CoreModule {
	static forRoot(localStorageConfig: LocalStorageConfig | undefined, logConfig: LogConfig | undefined): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				HttpService,
				CookieService,
				DeviceService,
				EventBusService,
				ListService,
				{
					provide: LocalStorageConfig,
					useValue: localStorageConfig || undefined
				},
				{
					provide: LocalStorageService,
					useFactory: localStorageServiceFactory,
					deps: [LocalStorageConfig]
				},
				{
					provide: LogConfig,
					useValue: logConfig || undefined
				},
				LogService,
				MenuService,
				SeoService,
				ShopService,
				AsyncTaskProcessor,
				UtilityService,
				WINDOW_PROVIDERS
			]
		};
	}
}
