/** @format */
import { Injectable, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/internal/Observable';

import { IUser, UserService } from '@bsb/ui/auth';

import { IMenuItem } from '../model/menu.interface';
import { IScratchList } from '../model/scratch-list.interface';
import { UpdateActionResult } from '../model/update-action.enum';
import { LogService } from './log.service';
import { UtilityService } from './utility.service';

@Injectable({
	providedIn: 'root'
})
export class ListService {
	constructor(private logService: LogService, private modalService: NgbModal, private userService: UserService, private utilityService: UtilityService) {}

	/**
	 * @description
	 * Updates a user's scratch list and then updates the user
	 *
	 * @param menuItem
	 * @param targetListId
	 * @param user
	 */
	public addMenuItemToUserList(menuItem: IMenuItem, targetListId: string, user: IUser): Observable<UpdateActionResult> {
		// Return an Observable
		return new Observable((observer) => {
			// Copy the user so that we don't modify the reference in memory on the component
			const userCopy: IUser = Object.assign({}, user);

			// Get the lists from the user
			const lists: IScratchList[] = userCopy.lists || [];

			// Find the selected list index from the master list
			const listIndex = lists.findIndex((list: IScratchList) => {
				return list._id === targetListId;
			});

			// Check to see if the item is already in the list
			if (Array.isArray(lists[listIndex].items) && lists[listIndex].items.indexOf(menuItem._id) === -1) {
				/*
				 * Item is not yet in the target list
				 */
				// Add it to the list
				lists[listIndex].items.push(menuItem._id);

				// Update the lists on the user profile
				userCopy.lists = lists;

				// Save the user profile
				this.userService.updateCurrentUser(userCopy).subscribe({
					next: () => {
						// Complete the Observable with true
						observer.next(UpdateActionResult.SUCCESS);
						observer.complete();
					},
					error: (error) => {
						this.logService.error('ListService#addMenuItemToUserList -- Error adding item to user list', error);

						// Complete the Observable with failure
						observer.next(UpdateActionResult.FAILURE);
						observer.complete();
					}
				});
			} else {
				/*
				 * Item is already in list
				 */
				// TODO -- Do we need to do anything if the item is already in the list? Technically not an error, do we need to alert anything?
				// Complete the Observable with cancel
				observer.next(UpdateActionResult.CANCEL);
				observer.complete();
			}
		});
	}

	/**
	 * @description
	 * Iterates arrays of menu items, favorites, and lists and determines if the
	 * menu item is in either the favorites and / or lists arrays and sets the appropriate
	 * properties based on the results
	 *
	 * @param menuItems
	 * @param user
	 */
	public decorateMenuItemsFromUserList(menuItems: IMenuItem[], user: IUser): IMenuItem[] {
		const favorites: string[] = user?.favorites || [];

		const lists: IScratchList[] = user?.lists || [];

		// Clone the menu items
		const returnMenuItems: IMenuItem[] = Object.assign([], menuItems);

		// Iterate the menu items
		returnMenuItems.forEach((menuItem: IMenuItem) => {
			// Set the favorite flag
			menuItem.favorite = Array.isArray(favorites) && favorites.indexOf(menuItem._id) > -1;

			// Check to see if there are any lists
			if (Array.isArray(lists) && lists.length > 0) {
				// Iterate each list to see if this menu item has been added to ANY list
				for (let i = 0, length = lists.length; i < length; i += 1) {
					// Check to see if the list has any items in it
					if (Array.isArray(lists[i].items) && lists[i].items.length > 0) {
						// Check against the current list items
						const matchFound = lists[i].items.indexOf(menuItem._id) > -1;

						// Check for a match
						if (matchFound) {
							// A match has been found, set the flag to true and break to prevent unnecessary iterations
							menuItem.listed = true;

							break;
						}
					}
				}
			}
		});

		// Return the decorated menu items
		return returnMenuItems;
	}

	/**
	 * @description
	 * Opens the Add to List modal template and returns the result
	 *
	 * @param textOrTemplateRef
	 */
	public openAddItemToListModal(textOrTemplateRef: string | TemplateRef<any>) {
		// Return an Observable
		return new Observable((observer) => {
			// Open the add to list modal
			this.modalService
				.open(textOrTemplateRef, {
					ariaLabelledBy: 'add-to-list-title'
				})
				.result.then(
					(addToListForm: NgForm) => {
						// Ensure the form is valid
						if (addToListForm.valid) {
							// Complete the Observable with true
							observer.next(UpdateActionResult.SUCCESS);
							observer.complete();
						} else {
							// Complete the Observable with failure
							observer.next(UpdateActionResult.FAILURE);
							observer.complete();
						}
					},
					() => {
						// Complete the Observable with cancel
						observer.next(UpdateActionResult.CANCEL);
						observer.complete();
					}
				);
		});
	}

	/**
	 * @description
	 * Updates a user's favorite list and then updates the user
	 *
	 * @param menuItem
	 * @param user
	 */
	public updateUserFavoriteList(menuItem: IMenuItem, user: IUser): Observable<UpdateActionResult> {
		// Return an Observable
		return new Observable<UpdateActionResult>((observer) => {
			if (typeof user !== 'undefined' && user !== null) {
				// Copy the user so that we don't modify the reference in memory on the component
				const userCopy: IUser = Object.assign({}, user);

				// Check to see if the current menu item is in the favorites list
				if (Array.isArray(userCopy.favorites) && userCopy.favorites.indexOf(menuItem._id) > -1) {
					// Remove from favorites
					userCopy.favorites = this.utilityService.removeValueFromList(menuItem._id, userCopy.favorites);
				} else {
					// Initialize if not existing
					userCopy.favorites = userCopy.favorites || [];

					// Add the menu item ID to the list
					userCopy.favorites.push(menuItem._id);
				}

				// Update the user to save changes to favorites
				return this.userService.updateCurrentUser(userCopy).subscribe({
					next: () => {
						// Complete the Observable with success
						observer.next(UpdateActionResult.SUCCESS);
						observer.complete();
					},
					error: (error) => {
						this.logService.error('ListService#updateUserFavoriteList -- Error adding item to user favorites', error);

						// Complete the Observable with failure
						observer.next(UpdateActionResult.FAILURE);
						observer.complete();
					}
				});
			} else {
				// Complete the Observable with failure
				observer.next(UpdateActionResult.FAILURE);
				observer.complete();
			}
		});
	}
}
