/** @format */
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';

import { HttpService } from './http.service';
import { LogService } from './log.service';
import { WINDOW } from './window.service';

@Injectable({
	providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
	public readonly ERROR_CONSTANTS = {
		errorHandling: {
			messagePrefixes: {
				global: {
					client: 'APPLICATION_ERROR:CLIENT_ERROR: ',
					data: 'APPLICATION_ERROR:DATA_ERROR: ',
					generic: 'APPLICATION_ERROR:GENERIC_ERROR: ',
					http: 'APPLICATION_ERROR:HTTP_ERROR: ',
					offline: 'APPLICATION_ERROR:OFFLINE_ERROR: ',
					timeout: 'TIMEOUT_ERROR: '
				}
			}
		}
	};

	private get httpService(): HttpService {
		return this.injector.get(HttpService);
	}

	constructor(@Inject(Injector) private readonly injector: Injector, @Inject(PLATFORM_ID) protected platformId: any, private logService: LogService, @Inject(WINDOW) private window: Window) {}

	handleError(error: Error | HttpErrorResponse) {
		// Error message
		let errorMessage: string;

		// Error details
		let errorDetails: object = {};

		if (error instanceof HttpErrorResponse) {
			// Server or connection error happened
			if (isPlatformBrowser(this.platformId) && !this.window.navigator.onLine) {
				/*
				 * Handle offline error
				 */
				// Set the error message
				errorMessage = `${this.ERROR_CONSTANTS.errorHandling.messagePrefixes.global.offline}${error.message}`;
			} else {
				/*
				 * Handle Http Error (error.status === 403, 404...)
				 */
				// Set the error message
				errorMessage = `${this.ERROR_CONSTANTS.errorHandling.messagePrefixes.global.http}${error.message}`;
			}

			try {
				// Set the error details
				errorDetails = {
					'X-ERROR-STATUS': `${error.status}`,
					'X-ERROR-ERROR': typeof error.error === 'object' ? JSON.stringify(error.error) : `${error.error}`,
					'X-ERROR-HEADERS': typeof error.headers === 'object' ? JSON.stringify(error.headers) : `${error.headers}`,
					'X-ERROR-MESSAGE': `${error.message}`,
					'X-ERROR-TYPE': `${error.type}`,
					'X-ERROR-NAME': `${error.name}`
				};
			} catch (error) {
				// Swallow the JSON stringify error
			}
		} else {
			/*
			 * Handle Client Error (Angular Error, ReferenceError...)
			 */
			// Set the generic error message
			errorMessage = `${this.ERROR_CONSTANTS.errorHandling.messagePrefixes.global.client}${error.message}`;

			// Set the error details
			errorDetails = {
				'X-ERROR-MESSAGE': `${error.message}`,
				'X-ERROR-NAME': `${error.name}`,
				'X-ERROR-STACK': `${error.stack}`
			};
		}

		// Set the URL
		errorDetails['X-HREF'] = `${this.httpService.getUrlProtocol()}://${this.httpService.getHostName()}${this.httpService.getUrlPath()}`;

		// Log the error
		// console.error(`${errorMessage}`, errorDetails);
		this.logService.error(`${errorMessage}`, { errorDetails });
	}
}
