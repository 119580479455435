/** @format */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { CustomHttpHeaders, MediaType } from '@bsb/ui/core';

@Injectable()
export class MarketingDao {
	// eslint-disable-next-line no-unused-vars
	constructor(private http: HttpClient) {}

	/**
	 * @description
	 * Signs up a user
	 */
	signUpForAudience$(email: string, retries = 0): Observable<boolean> {
		// HTTP Body
		const requestBody = {
			email
		};

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.post('/api/marketing/signup', requestBody, httpOptions).pipe(
			map((response: any) => {
				return true;
			}),
			retry(retries)
		);
	}
}
