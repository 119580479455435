/** @format */
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { BSBCommonModule } from '@bsb/ui/common';

import { AuthDao } from './dao/auth.dao';
import { UserDao } from './dao/user.dao';
import { AccountAuthGuard } from './guard/account-auth.guard';
import { AdminAuthGuard } from './guard/admin-auth.guard';
import { PasswordTokenGuard } from './guard/password-token.guard';
import { TokenInterceptor } from './httpinterceptor/token.interceptor';
import { AuthService } from './service/auth.service';

@NgModule({
	imports: [BSBCommonModule, CommonModule, FormsModule],
	providers: []
})
export class AuthModule {
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [
				AuthDao,
				AccountAuthGuard,
				AdminAuthGuard,
				AuthService,
				{
					provide: HTTP_INTERCEPTORS,
					useClass: TokenInterceptor,
					multi: true
				},
				PasswordTokenGuard,
				UserDao
			]
		};
	}
}
