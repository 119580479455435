/** @format */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BSBCommonModule } from '../../common.module';

import { AlertMessageComponent } from './alert-message.component';

@NgModule({
	declarations: [AlertMessageComponent],
	imports: [BSBCommonModule, CommonModule],
	exports: [AlertMessageComponent]
})
export class AlertMessageModule {}
