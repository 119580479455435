/** @format */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MenuDao } from '../dao/menu.dao';
import { IMenu, IMenuCategory, IMenuItem } from '../model/menu.interface';

@Injectable()
export class MenuService {
	// eslint-disable-next-line no-unused-vars
	constructor(private menuDao: MenuDao) {}

	public createMenu$(menu: IMenu, retries = 0): Observable<IMenu> {
		return this.menuDao.createMenu$(menu, retries);
	}

	public createMenuCategory$(menuCategory: IMenuCategory, retries = 0): Observable<IMenuCategory> {
		return this.menuDao.createMenuCategory$(menuCategory, retries);
	}

	public createMenuItem$(menuItem: IMenuItem, retries = 0): Observable<IMenuItem> {
		return this.menuDao.createMenuItem$(menuItem, retries);
	}

	public deleteMenuById$(menuId: string): Observable<IMenu> {
		return this.menuDao.deleteMenuById$(menuId);
	}

	public deleteMenuCategoryById$(menuCategoryId: string): Observable<IMenuCategory> {
		return this.menuDao.deleteMenuCategoryById$(menuCategoryId);
	}

	public deleteMenuItemById$(menuItemId: string): Observable<IMenuItem> {
		return this.menuDao.deleteMenuItemById$(menuItemId);
	}

	public getMenus$(): Observable<IMenu[]> {
		return this.menuDao.getMenus$();
	}

	public getMenusByRestaurantId$(restaurantId: string): Observable<IMenu[]> {
		return this.menuDao.getMenusByRestaurantId$(restaurantId);
	}

	public getMenuById$(menuId: string): Observable<IMenu> {
		return this.menuDao.getMenuById$(menuId);
	}

	public getMenuByType$(type: string): Observable<IMenu> {
		return this.menuDao.getMenuByType$(type);
	}

	public getMenuCategoriesByRestaurantId$(restaurantId: string): Observable<IMenuCategory[]> {
		return this.menuDao.getMenuCategoriesByRestaurantId$(restaurantId);
	}

	public getMenuCategoriesByMenuId$(menuId?: string): Observable<IMenuCategory[]> {
		return this.menuDao.getMenuCategoriesByMenuId$(menuId);
	}

	public getMenuCategoryById$(categoryId: string): Observable<IMenuCategory> {
		return this.menuDao.getMenuCategoryById$(categoryId);
	}

	public getMenuItemsByCategoryId$(categoryId?: string, activeFilter = false, join = false): Observable<IMenuItem[]> {
		return this.menuDao.getMenuItemsByCategoryId$(categoryId, activeFilter, join);
	}

	public getMenuItemById$(categoryId: string): Observable<IMenuItem> {
		return this.menuDao.getMenuItemById$(categoryId);
	}

	public updateMenu$(menu: IMenu, retries = 0): Observable<IMenu> {
		return this.menuDao.updateMenu$(menu, retries);
	}

	public updateMenuCategory$(menuCategory: IMenuCategory, retries = 0): Observable<IMenuCategory> {
		return this.menuDao.updateMenuCategory$(menuCategory, retries);
	}

	public updateMenuItem$(menuItem: IMenuItem, retries = 0): Observable<IMenuItem> {
		return this.menuDao.updateMenuItem$(menuItem, retries);
	}
}
