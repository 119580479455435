/** @format */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlertMessageModule } from '@bsb/ui/common';

import { CartPanelComponent } from './cart-panel/cart-panel.component';

@NgModule({
	imports: [AlertMessageModule, CommonModule],
	declarations: [CartPanelComponent],
	exports: [CartPanelComponent],
	providers: []
})
export class CartModule {}
