/** @format */
export enum MenuType {
	FOOD_MAIN = 'food-main',
	FOOD_BESTSELLERS = 'food-bestseller',
	FOOD_WEEKLY = 'food-weekly'
}

export const MenuTypeDisplay = {
	'food-main': 'Main',
	'food-bestseller': 'Best Sellers',
	'food-weekly': 'Weekly Picks'
};
