/** @format */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { CustomHttpHeaders, MediaType } from '@bsb/shared/schema/enum';

import { IUser } from '../model/user.interface';

@Injectable()
export class AuthDao {
	// eslint-disable-next-line no-unused-vars
	constructor(private http: HttpClient) {}

	/**
	 * @description
	 * Logs a user in and returns the user with a valid token
	 */
	public login$(username: string, password: string, retries = 0, role?: string): Observable<IUser> {
		// HTTP Body
		const requestBody = {
			username,
			password,
			role
		};

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.post('/api/auth/login', requestBody, httpOptions).pipe(
			map((response: any) => {
				// Set the user from the response
				const returnUser: IUser = response.user;

				// Set the token from the response
				returnUser.token = response.token;

				// Set the refresh token from the response
				returnUser.refreshToken = response.refreshToken;

				// Decode the token
				const decoded: any = jwt_decode(response.token);

				// Set the expires Date
				returnUser.expires = decoded.exp;

				return returnUser;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Renews session for a given user ID and returns the user with a valid token
	 */
	public renew$(id: string, refreshToken: string, retries = 0): Promise<IUser> {
		// HTTP Body
		const requestBody = {
			id,
			refreshToken
		};

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_TIMEOUT, '20000');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http
			.post('/api/auth/renew', requestBody, httpOptions)
			.pipe(
				map((response: any) => {
					// Set the user from the response
					const returnUser: IUser = response.user;

					// Set the token from the response
					returnUser.token = response.token;

					// Re-add the refresh token to the user
					returnUser.refreshToken = refreshToken;

					// Decode the token
					const decoded: any = jwt_decode(response.token);

					// Set the expires Date
					returnUser.expires = decoded.exp;

					return returnUser;
				}),
				retry(retries)
			)
			.toPromise();
	}

	/**
	 * @description
	 * Submits the updated password along with the reset token to attempt to update the password.  If the reset token
	 * has expired or a new reset request has been submitted, any previous reset tokens will be invalid.
	 *
	 * @param resetToken
	 * @param password
	 * @param confirmPassword
	 * @param retries
	 */
	public resetPasswordWithResetToken$(resetToken: string, password: string, confirmPassword: string, retries = 0): Observable<boolean> {
		// HTTP Body
		const requestBody = {
			resetToken,
			password,
			confirmPassword
		};

		// Standard HTTP Headers
		const httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.post('/api/auth/reset-password', requestBody, httpOptions).pipe(
			map((response: any) => {
				return response?.success === true;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Submits a request to reset password
	 */
	public requestResetToken$(email: string, retries = 0): Observable<boolean> {
		// HTTP Body
		const requestBody = {
			email
		};

		// Standard HTTP Headers
		const httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.post('/api/auth/reset-password-request', requestBody, httpOptions).pipe(
			map((response: any) => {
				return response?.success === true;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Validates a password reset token
	 */
	public validateResetToken$(resetToken: string, retries = 0): Observable<boolean> {
		// HTTP Body
		const requestBody = {
			resetToken
		};

		// Standard HTTP Headers
		const httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.post('/api/auth/reset-password-token-validate', requestBody, httpOptions).pipe(
			map((response: any) => {
				return response?.success === true;
			}),
			retry(retries)
		);
	}
}
