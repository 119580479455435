<!-- Page Content -->
<div class="page-content bg-light">
	<div class="container">
		<div class="row no-gutters">
			<div class="col-lg-10 push-lg-1">
				<!-- Post / Item -->
				<article class="post post-wide" *ngFor="let post of blogPosts">
					<div class="post-content">
						<ul class="post-meta">
							<li>{{ post.date | date : "mediumDate" }}</li>
							<li>{{ post.author }}</li>
						</ul>
						<p class="post-title">
							<a class="underline" href="posts/{{ post._id }}">{{ post.title }}</a>
						</p>
						<div class="post-content-text" [innerHTML]="post.content | sanitizeHtml"></div>
					</div>
				</article>
			</div>
		</div>
	</div>
</div>
