/** @format */
import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, PLATFORM_ID, Output } from '@angular/core';

import { BSB_CONSTANTS, CookieService, HttpService } from '@bsb/ui/core';

@Component({
	selector: 'app-alert-message',
	templateUrl: './alert-message.component.html',
	styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
	/**
	 * @description
	 * Flag which specifies if the message is closable
	 */
	@Input()
	public closable: boolean;

	/**
	 * @description
	 * Flag which specifies if the message is deletable
	 */
	@Input()
	public deletable = false;

	/**
	 * @description
	 * Flag which determines if the message is displayable, defaulted to false
	 */
	public displayable = false;

	/**
	 * @description
	 * The body of the message
	 */
	@Input()
	public messageBody: string;

	/**
	 * @description
	 * The level of the message, e.g. error, warning, info, success, etc.
	 */
	@Input()
	public messageLevel: string;

	/**
	 * @description
	 * The title of the message
	 */
	@Input()
	public messageTitle: string;

	/**
	 * @description
	 * Flag for if the message state should be remembered, e.g. if closed then don't display
	 */
	@Input()
	public rememberState: boolean;

	/**
	 * @description
	 * Optional "learn more" link
	 */
	@Input()
	public supplementalLink: string;

	/**
	 * @description
	 * Optional "learn more" link target
	 */
	@Input()
	public supplementalLinkTarget: string;

	/**
	 * @description
	 * Optional "learn more" link text
	 */
	@Input()
	public supplementalLinkText: string;

	/**
	 * @description
	 * Emits an output Event when the alert message close button is clicked
	 */
	@Output()
	public alertClose: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * @description
	 * The name of the icon to display
	 */
	public messageIcon: string;

	/**
	 * @description
	 * Remembered alerts
	 *
	 * @private
	 */
	private alertName: string;

	/**
	 * @description
	 * Remembered alerts
	 *
	 * @private
	 */
	private rememberedAlerts: string[];

	constructor(private cookieService: CookieService, private elementRef: ElementRef, private httpService: HttpService, @Inject(PLATFORM_ID) protected platformId: any) {}

	ngOnInit(): void {
		// Check to see which icon should be displayed.  NOTE: info level does NOT have an icon, so do not set anything
		if (typeof this.messageLevel === 'string' && this.messageLevel.toLowerCase() === 'error') {
			this.messageIcon = 'exclamation';
		} else if (typeof this.messageLevel === 'string' && this.messageLevel.toLowerCase() === 'success') {
			this.messageIcon = 'check';
		} else if (typeof this.messageLevel === 'string' && this.messageLevel.toLowerCase() === 'warn') {
			this.messageIcon = 'warning';
		} else if (typeof this.messageLevel === 'string' && this.messageLevel.toLowerCase() === 'info') {
			this.messageIcon = 'info';
		}

		// Set the alert name
		this.alertName = this.elementRef?.nativeElement?.getAttribute('alertName');

		// Only do this on the browser
		if (isPlatformBrowser(this.platformId)) {
			this.checkRememberedStatus();
		}
	}

	public checkRememberedStatus(): void {
		// Check to see if the alert state should be remembered
		if (this.rememberState === true && this.cookieService.check(BSB_CONSTANTS.alerts.cookie.name)) {
			// State should be remembered, get the alert cookie value
			const alertCookieValue = this.cookieService.get(BSB_CONSTANTS.alerts.cookie.name);

			// Ensure there is not an empty string
			if (typeof alertCookieValue === 'string' && alertCookieValue.trim() !== '') {
				// Split the string into an array
				this.rememberedAlerts = alertCookieValue.split(',');

				// Check to see if the current alert was remembered
				if (Array.isArray(this.rememberedAlerts) && this.rememberedAlerts.indexOf(this.alertName) === -1) {
					// Alert was not remembered, display it
					this.displayable = true;
				}
			} else if (typeof alertCookieValue === 'string' && alertCookieValue.trim() === '') {
				// Alert cookie value got junked, set alert to display and try again
				this.displayable = true;
			}
		} else {
			// State should not be remembered, display the alert regardless
			this.displayable = true;
		}
	}

	public onCloseButtonClick(): void {
		// Ensure the native Element exists and is functional
		if (typeof this.elementRef?.nativeElement !== 'undefined') {
			// Check to see if the Element should be deleted from the DOM
			if (this.deletable) {
				// Delete the Element from the DOM
				this.elementRef.nativeElement.remove();
			}

			// Check to see if the alert state should be remembered
			// eslint-disable-next-line sonarjs/no-collapsible-if
			if (this.rememberState === true) {
				let alertCookieValue: string;

				// State should be remembered, check for the alert cookie value
				if (this.cookieService.check(BSB_CONSTANTS.alerts.cookie.name)) {
					// Get the alert cookie value
					alertCookieValue = this.cookieService.get(BSB_CONSTANTS.alerts.cookie.name);
				}

				// Ensure there is not an empty string
				if (typeof alertCookieValue === 'string' && alertCookieValue.trim() !== '') {
					// Split the string into an array
					this.rememberedAlerts = alertCookieValue.split(',');
				}

				// Make sure the array is initialized
				this.rememberedAlerts = this.rememberedAlerts || [];

				// Alert state should be remembered, add the alert name to the list if it is not already there
				if (Array.isArray(this.rememberedAlerts) && this.rememberedAlerts.indexOf(this.alertName) === -1) {
					this.rememberedAlerts.push(this.alertName);

					// Update the cookie
					this.cookieService.set(
						BSB_CONSTANTS.alerts.cookie.name,
						this.rememberedAlerts.join(','),
						BSB_CONSTANTS.alerts.cookie.expiration,
						BSB_CONSTANTS.alerts.cookie.path,
						this.httpService.getHostName(),
						BSB_CONSTANTS.alerts.cookie.secure
					);
				}
			}
		}

		// Emit the close event up
		this.alertClose.emit(true);
	}
}
