/** @format */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BlogDao } from './blog.dao';
import { IBlogPost } from './blog.interface';

@Injectable()
export class BlogService {
	constructor(private blogDao: BlogDao) {}

	public convertTitleToSeoString(title: string): string {
		let seoString = '';

		// Ensure there is a valid string
		if (typeof title === 'string' && title.trim() !== '') {
			seoString = title.toLowerCase().replace(/[ ]/g, '-');
		}

		// Return the converted string
		return seoString;
	}

	public createBlogPost$(blogPost: IBlogPost, retries = 0): Observable<IBlogPost> {
		return this.blogDao.createBlogPost$(blogPost, retries);
	}

	public deleteBlogPost$(blogPostId: string, retries = 0): Observable<IBlogPost> {
		return this.blogDao.deleteBlogPost$(blogPostId, retries);
	}

	public getAllBlogPosts$(sortByNewestFirst = false, activeFilter = false, bypassState = false, retries = 0): Observable<IBlogPost[]> {
		return this.blogDao.getAllBlogPosts$(sortByNewestFirst, activeFilter, bypassState, retries);
	}

	public getBlogPost$(postId: string, retries = 0): Observable<IBlogPost> {
		return this.blogDao.getBlogPost$(postId, retries);
	}

	public updateBlogPost$(blogPost: IBlogPost, retries = 0): Observable<IBlogPost> {
		return this.blogDao.updateBlogPost$(blogPost, retries);
	}
}
