<div>
	<!-- Avalon -->
	<div class="d-flex justify-content-center mb-3" *ngIf="environment.thirdParty.chowNow.locations.avalon.enabled">
		<a class="btn btn-primary btn-lg d-inline-block" [href]="'https://ordering.chownow.com/order/' + environment.thirdParty.chowNow.companyId + '/locations/' + environment.thirdParty.chowNow.locations.avalon.locationId" target="_blank">
			<span>Avalon Online Ordering</span>
		</a>
	</div>

	<!-- Kennedy -->
	<div class="d-flex justify-content-center mb-3" *ngIf="environment.thirdParty.chowNow.locations.kennedy.enabled">
		<a class="btn btn-primary btn-lg d-inline-block" [href]="'https://ordering.chownow.com/order/' + environment.thirdParty.chowNow.companyId + '/locations/' + environment.thirdParty.chowNow.locations.kennedy.locationId" target="_blank">
			<span>Kennedy Twp Online Ordering</span>
		</a>
	</div>
</div>
