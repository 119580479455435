/** @format */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChowNowComponent } from './chow-now.component';

@NgModule({
	imports: [CommonModule],
	declarations: [ChowNowComponent],
	exports: [ChowNowComponent],
	providers: []
})
export class ChowNowModule {}
