/** @format */

/* eslint-disable sonarjs/no-duplicate-string, sonarjs/no-identical-functions */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, makeStateKey, StateKey, TransferState } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { CustomHttpHeaders, MediaType } from '@bsb/shared/schema/enum';
import { IMenu, IMenuCategory, IMenuItem } from '../model/menu.interface';

@Injectable()
export class MenuDao {
	// eslint-disable-next-line no-unused-vars
	constructor(private http: HttpClient, private state: TransferState) {}

	getMenus$(retries = 0): Observable<IMenu[]> {
		// Get unique key
		const STATE_KEY: StateKey<IMenu[]> = makeStateKey<IMenu[]>('menus');

		// Attempt to get the state by key
		const stateData: IMenu[] = this.state.get<IMenu[]>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.get('/api/menus', httpOptions).pipe(
			map((menus: IMenu[]) => {
				// Set the state
				this.state.set<IMenu[]>(STATE_KEY, menus);

				return menus;
			}),
			retry(retries)
		);
	}

	getMenusByRestaurantId$(restaurantId: string, retries = 0): Observable<IMenu[]> {
		// Get unique key
		const STATE_KEY: StateKey<IMenu[]> = makeStateKey<IMenu[]>(`menus-restaurant-${restaurantId}`);

		// Attempt to get the state by key
		const stateData: IMenu[] = this.state.get<IMenu[]>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Query String Parameters
		let httpParams = new HttpParams();

		// Check to see if a menu ID was provided
		if (typeof restaurantId === 'string' && restaurantId.trim() !== '') {
			httpParams = httpParams.set('searchField', 'restaurant').set('searchTerm', restaurantId.toLowerCase()).set('sortField', 'order').set('sortValue', '1');
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams
		};

		// Make the HTTP Request
		return this.http.get('/api/menus', httpOptions).pipe(
			map((menus: IMenu[]) => {
				// Set the state
				this.state.set<IMenu[]>(STATE_KEY, menus);

				return menus;
			}),
			retry(retries)
		);
	}

	getMenuById$(menuId: string, retries = 0): Observable<IMenu> {
		// Get unique key
		const STATE_KEY: StateKey<IMenu> = makeStateKey<IMenu>(`menu-${menuId}`);

		// Attempt to get the state by key
		const stateData: IMenu = this.state.get<IMenu>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.get(`/api/menus/${menuId}`, httpOptions).pipe(
			map((response: IMenu) => {
				// Set the state
				this.state.set<IMenu>(STATE_KEY, response);

				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	getMenuByType$(type: string, retries = 0): Observable<IMenu> {
		// Get unique key
		const STATE_KEY: StateKey<IMenu> = makeStateKey<IMenu>(`menu-type-${type}`);

		// Attempt to get the state by key
		const stateData: IMenu = this.state.get<IMenu>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Query String Parameters
		const httpParams = new HttpParams().set('searchField', 'type').set('searchTerm', type.toLowerCase());

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams
		};

		// Make the HTTP Request
		return this.http.get('/api/menus', httpOptions).pipe(
			map((menu: any) => {
				let returnMenu: IMenu;

				if (Array.isArray(menu) && menu.length > 0) {
					// Set the state
					this.state.set(STATE_KEY, menu[0]);

					returnMenu = menu[0];
				} else {
					// Set the state
					this.state.set(STATE_KEY, menu);

					returnMenu = menu;
				}

				return returnMenu;
			}),
			retry(retries)
		);
	}

	getMenuCategoriesByRestaurantId$(restaurantId: string, retries = 0): Observable<IMenuCategory[]> {
		// Get unique key
		const STATE_KEY: StateKey<IMenuCategory[]> = makeStateKey<IMenuCategory[]>('menu-categories');

		// Attempt to get the state by key
		const stateData: IMenuCategory[] = this.state.get<IMenuCategory[]>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Query String Parameters
		let httpParams = new HttpParams();

		// Check to see if a menu ID was provided
		if (typeof restaurantId === 'string' && restaurantId.trim() !== '') {
			httpParams = httpParams.set('searchField', 'restaurant').set('searchTerm', restaurantId.toLowerCase()).set('sortField', 'order').set('sortValue', '1');
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams
		};

		// Make the HTTP Request
		return this.http.get('/api/restaurant-menu-categories', httpOptions).pipe(
			map((response: IMenuCategory[]) => {
				// Set the state
				this.state.set<IMenuCategory[]>(STATE_KEY, response);

				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	getMenuCategoriesByMenuId$(menuId: string, retries = 0): Observable<IMenuCategory[]> {
		// Get unique key
		const STATE_KEY: StateKey<IMenuCategory[]> = makeStateKey<IMenuCategory[]>(`menu-categories-${menuId}`);

		// Attempt to get the state by key
		const stateData: IMenuCategory[] = this.state.get<IMenuCategory[]>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Query String Parameters
		let httpParams = new HttpParams();

		// Check to see if a menu ID was provided
		if (typeof menuId !== 'undefined' && menuId !== null) {
			httpParams = httpParams.set('searchField', 'menuId').set('searchTerm', menuId.toLowerCase()).set('sortField', 'order').set('sortValue', '1');
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams
		};

		// Make the HTTP Request
		return this.http.get('/api/restaurant-menu-categories', httpOptions).pipe(
			map((response: IMenuCategory[]) => {
				// Set the state
				this.state.set<IMenuCategory[]>(STATE_KEY, response);

				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	getMenuCategoryById$(categoryId: string, retries = 0): Observable<IMenuCategory> {
		// Get unique key
		const STATE_KEY: StateKey<IMenuCategory> = makeStateKey<IMenuCategory>(`menu-category-${categoryId}`);

		// Attempt to get the state by key
		const stateData: IMenuCategory = this.state.get<IMenuCategory>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.get(`/api/restaurant-menu-categories/${categoryId}`, httpOptions).pipe(
			map((response: IMenuCategory) => {
				// Set the state
				this.state.set<IMenuCategory>(STATE_KEY, response);

				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	getMenuItemsByCategoryId$(categoryId: string, activeFilter = false, join = false, retries = 0): Observable<IMenuItem[]> {
		// Get unique key
		const STATE_KEY: StateKey<IMenuItem[]> = makeStateKey<IMenuItem[]>(`menu-items-${typeof categoryId === 'string' ? categoryId : 'all'}`);

		// Attempt to get the state by key
		const stateData: IMenuItem[] = this.state.get<IMenuItem[]>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Query String Parameters
		let httpParams = new HttpParams();

		// Check to see if a menu ID was provided
		if (typeof categoryId !== 'undefined' && categoryId !== null) {
			httpParams = httpParams.set('searchField', 'menuCategory').set('searchTerm', categoryId.toLowerCase()).set('sortField', 'order').set('sortValue', '1');
		}

		// Check to see if join should be performed
		if (join) {
			httpParams = httpParams.set('join', 'true');
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams
		};

		// Make the HTTP Request
		return this.http.get('/api/restaurant-menu-items', httpOptions).pipe(
			map((response: IMenuItem[]) => {
				let menuItems: IMenuItem[] = response;

				// Check to see if we need to filter for active documents
				if (activeFilter) {
					// Filter out the active documents
					menuItems = menuItems.filter((menuItem: IMenuItem) => {
						return menuItem.active === true;
					});
				}

				// Sort the documents by signature status and then alphabetically
				menuItems.sort((a: IMenuItem, b: IMenuItem) => {
					if (a.signature === b.signature) {
						return a.title < b.title ? -1 : 1;
					} else {
						return a.signature < b.signature ? -1 : 1;
					}
				});

				// Set the state
				this.state.set<IMenuItem[]>(STATE_KEY, menuItems);

				// Return the raw Response
				return menuItems;
			}),
			retry(retries)
		);
	}

	getMenuItemById$(menuItemId: string, retries = 0): Observable<IMenuItem> {
		// Get unique key
		const STATE_KEY: StateKey<IMenuItem> = makeStateKey<IMenuItem>(`menu-item-${menuItemId}`);

		// Attempt to get the state by key
		const stateData: IMenuItem = this.state.get<IMenuItem>(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.get(`/api/restaurant-menu-items/${menuItemId}`, httpOptions).pipe(
			map((response: IMenuItem) => {
				// Set the state
				this.state.set<IMenuItem>(STATE_KEY, response);

				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	createMenu$(menu: IMenu, retries = 0): Observable<IMenu> {
		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.post('/api/menus', menu, httpOptions).pipe(
			map((response: IMenu) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	createMenuCategory$(menuCategory: IMenuCategory, retries = 0): Observable<IMenuCategory> {
		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.post('/api/restaurant-menu-categories', menuCategory, httpOptions).pipe(
			map((response: IMenuCategory) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	createMenuItem$(menuItem: IMenuItem, retries = 0): Observable<IMenuItem> {
		// Check to ensure the menu item flavors are defined
		if (typeof menuItem !== 'undefined' && typeof menuItem.meta !== 'undefined' && Array.isArray(menuItem.meta.flavors)) {
			// Iterate through the flavors
			for (let i = 0, length = menuItem.meta.flavors.length; i < length; i += 1) {
				if (typeof menuItem.meta.flavors[i] === 'string') {
					menuItem.meta.flavors[i] = menuItem.meta.flavors[i].replace("'", '');
				}
			}
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.post('/api/restaurant-menu-items', menuItem, httpOptions).pipe(
			map((response: IMenuItem) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	deleteMenuById$(menuId: string, retries = 0): Observable<IMenu> {
		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.delete(`/api/menus/${menuId}`, httpOptions).pipe(
			map((response: IMenu) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	deleteMenuCategoryById$(menuCategoryId: string, retries = 0): Observable<IMenuCategory> {
		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.delete(`/api/restaurant-menu-categories/${menuCategoryId}`, httpOptions).pipe(
			map((response: IMenuCategory) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	deleteMenuItemById$(menuItemId: string, retries = 0): Observable<IMenuItem> {
		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.delete(`/api/restaurant-menu-items/${menuItemId}`, httpOptions).pipe(
			map((response: IMenuItem) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	updateMenu$(menu: IMenu, retries = 0): Observable<IMenu> {
		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.put(`/api/menus/${menu._id}`, menu, httpOptions).pipe(
			map((response: IMenu) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	updateMenuCategory$(menuCategory: IMenuCategory, retries = 0): Observable<IMenuCategory> {
		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.put(`/api/restaurant-menu-categories/${menuCategory._id}`, menuCategory, httpOptions).pipe(
			map((response: IMenuCategory) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}

	updateMenuItem$(menuItem: IMenuItem, retries = 0): Observable<IMenuItem> {
		// Check to ensure the menu item flavors are defined
		if (typeof menuItem !== 'undefined' && typeof menuItem.meta !== 'undefined' && Array.isArray(menuItem.meta.flavors)) {
			// Iterate through the flavors
			for (let i = 0, length = menuItem.meta.flavors.length; i < length; i += 1) {
				if (typeof menuItem.meta.flavors[i] === 'string') {
					menuItem.meta.flavors[i] = menuItem.meta.flavors[i].replace("'", '');
				}
			}
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true').set(CustomHttpHeaders.X_AUTH_REQUIRED, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http.put(`/api/restaurant-menu-items/${menuItem._id}`, menuItem, httpOptions).pipe(
			map((response: IMenuItem) => {
				// Return the raw Response
				return response;
			}),
			retry(retries)
		);
	}
}
