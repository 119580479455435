/** @format */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { CommonInterceptor, LogInterceptor } from '@bsb/ui/common';

/* Http interceptor providers in outside-in order */
export const HTTP_INTERCEPTOR_PROVIDERS = [
	{ provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true }
];
