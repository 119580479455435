/** @format */
import { NgModule } from '@angular/core';

import { AnchorScrollDirective } from './directive/anchor-scroll.directive';
import { AntiEmailValidator } from './directive/anti-email-validator.directive';
import { EqualValidator } from './directive/equal-validator.directive';
import { SmoothScrollDirective } from './directive/smooth-scroll.directive';
import { BindUrlPipe } from './pipes/bind-url.pipe';
import { HtmlSanitizerPipe } from './pipes/safe-html.pipe';
import { TruncateWordsPipe } from './pipes/truncate-words.pipe';

@NgModule({
	declarations: [
		AnchorScrollDirective,
		AntiEmailValidator,
		BindUrlPipe,
		EqualValidator,
		HtmlSanitizerPipe,
		SmoothScrollDirective,
		TruncateWordsPipe
	],
	exports: [AnchorScrollDirective, AntiEmailValidator, BindUrlPipe, EqualValidator, HtmlSanitizerPipe, SmoothScrollDirective, TruncateWordsPipe]
})
export class BSBCommonModule {}
