/** @format */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { IBlogPost } from '../blog.interface';
import { BlogService } from '../blog.service';

@Component({
	selector: 'app-blog-posts',
	templateUrl: './blog-posts.component.html',
	styleUrls: ['./blog-posts.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BlogPostsComponent implements OnInit {
	public blogPosts: IBlogPost[];

	constructor(private blogService: BlogService) {}

	ngOnInit() {
		// Get the blog posts to display
		this.blogService.getAllBlogPosts$(true, true).subscribe((blogPosts: IBlogPost[]) => {
			// Set the blog posts on the component for display in the view
			this.blogPosts = blogPosts;
		});
	}
}
