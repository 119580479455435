/** @format */
import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments';

// Enable production mode check
if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then((module) => {
		if (!environment.production) {
			const applicationRef = module.injector.get(ApplicationRef);
			const appComponent = applicationRef.components[0];
			enableDebugTools(appComponent);
		}
	})
	.catch((error) => {
		return console.error(`APPLICATION_ERROR:BOOTSTRAP_ERROR: ${JSON.stringify(error, ['message', 'arguments', 'type', 'name'])}`);
	});
