/** @format */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { BreakPoint } from '../model/breakpoint.enum';
import * as Constants from '../model/device.constants';
import { IDeviceInfo } from '../model/device.constants';
import { HttpService } from './http.service';
import { ReTree } from './retree';
import { WINDOW } from './window.service';

@Injectable({
	providedIn: 'root'
})
export class DeviceService {
	ua = '';
	userAgent = '';
	os = '';
	browser = '';
	device = '';
	os_version = '';
	browser_version = '';
	device_viewport_dimensions;

	constructor(@Inject(DOCUMENT) private document: HTMLDocument, private httpService: HttpService, @Inject(WINDOW) private window: Window) {
		this.ua = this.httpService.getUserAgent();

		this._setDeviceInfo();
	}

	/**
	 * @description
	 * Sets the initial value of the device when the service is initiated.
	 *
	 * This value is later accessible for usage
	 */
	private _setDeviceInfo() {
		const reTree = new ReTree();
		const ua = this.ua;
		this.userAgent = ua;
		const mappings = [
			{ const: 'OS', prop: 'os' },
			{ const: 'BROWSERS', prop: 'browser' },
			{ const: 'DEVICES', prop: 'device' },
			{ const: 'OS_VERSIONS', prop: 'os_version' }
		];

		mappings.forEach((mapping) => {
			this[mapping.prop] = Object.keys(Constants[mapping.const]).reduce((obj: any, item: any) => {
				obj[Constants[mapping.const][item]] = reTree.test(ua, Constants[`${mapping.const}_RE`][item]);
				return obj;
			}, {});
		});

		mappings.forEach((mapping) => {
			this[mapping.prop] = Object.keys(Constants[mapping.const])
				.map((key) => {
					return Constants[mapping.const][key];
				})
				.reduce((previousValue, currentValue) => {
					return previousValue === Constants[mapping.const].UNKNOWN && this[mapping.prop][currentValue] ? currentValue : previousValue;
				}, Constants[mapping.const].UNKNOWN);
		});

		this.browser_version = '0';

		/* istanbul ignore else */
		if (this.browser !== Constants.BROWSERS.UNKNOWN) {
			const re = Constants.BROWSER_VERSIONS_RE[this.browser];
			const res = reTree.exec(ua, re);

			/* istanbul ignore else */
			if (!!res) {
				this.browser_version = res[1];
			}
		}
	}

	/**
	 * @description
	 * Returns the device breakpoint
	 *
	 * @returns The breakpoint for the current viewport width
	 */
	public getDeviceBreakPoint(forceCalculate = false): BreakPoint {
		// Get the width
		const width = this.getDeviceViewportDimensions(forceCalculate).width;

		if (width < 576) {
			return BreakPoint.EXTRASMALL;
		}

		if (width < 768) {
			return BreakPoint.SMALL;
		}

		if (width < 992) {
			return BreakPoint.MEDIUM;
		}

		if (width < 1200) {
			return BreakPoint.LARGE;
		}

		// Default to XL
		return BreakPoint.EXTRALARGE;
	}

	/**
	 * @description
	 * Returns the channel of the device based on physical characteristics and NOT viewport size / breakpoint
	 *
	 * @returns the device channel
	 */
	public getDeviceChannel(): string {
		if (this.isMobile()) {
			return Constants.DEVICE_CHANNELS.MOBILE;
		} else if (this.isTablet()) {
			return Constants.DEVICE_CHANNELS.TABLET;
		}

		return Constants.DEVICE_CHANNELS.DESKTOP;
	}

	/**
	 * @description
	 * Returns the channel of the device based on viewport size / breakpoint
	 *
	 * @returns the device channel
	 */
	public getDeviceChannelByBreakPoint(): string {
		if (this.isMobileBreakpoint()) {
			return Constants.DEVICE_CHANNELS.MOBILE;
		} else if (this.isTabletBreakpoint()) {
			return Constants.DEVICE_CHANNELS.TABLET;
		}

		return Constants.DEVICE_CHANNELS.DESKTOP;
	}

	/**
	 * @description
	 * Returns the device information
	 *
	 * @returns the device information object.
	 */
	public getDeviceInfo(): IDeviceInfo {
		return {
			userAgent: this.userAgent,
			os: this.os,
			browser: this.browser,
			device: this.device,
			osVersion: this.os_version,
			browserVersion: this.browser_version
		};
	}

	/**
	 * @description
	 * Returns the height and width of the device viewport
	 */
	public getDeviceViewportDimensions(forceCalculate = false): { height: number; width: number } {
		// Check to see if the device viewport dimensions are defined
		if (typeof this.device_viewport_dimensions === 'undefined' || forceCalculate) {
			this.device_viewport_dimensions = {
				height: Math.max(this.document.documentElement.clientHeight, this.window.innerHeight || 0),
				width: Math.max(this.document.documentElement.clientWidth, this.window.innerWidth || 0)
			};
		}

		return this.device_viewport_dimensions;
	}

	/**
	 * @description
	 * Compares the current device info with the mobile devices to check
	 * if the current device is a mobile.
	 *
	 * @returns whether the current device is a mobile
	 */
	public isMobile(): boolean {
		return [
			Constants.DEVICES.ANDROID,
			Constants.DEVICES.IPHONE,
			Constants.DEVICES.I_POD,
			Constants.DEVICES.BLACKBERRY,
			Constants.DEVICES.FIREFOX_OS,
			Constants.DEVICES.WINDOWS_PHONE,
			Constants.DEVICES.VITA
		].some((item) => {
			return this.device === item;
		});
	}

	/**
	 * @description
	 * Gets the current breakpoint and determines if it is a mobile sized breakpoint
	 *
	 * @returns whether the current device is a mobile breakpoint
	 */
	public isMobileBreakpoint(): boolean {
		const breakpoint: BreakPoint = this.getDeviceBreakPoint();

		return breakpoint === BreakPoint.EXTRASMALL || breakpoint === BreakPoint.SMALL;
	}

	/**
	 * @description
	 * Compares the current device info with the tablet devices to check
	 * if the current device is a tablet.
	 *
	 * @returns whether the current device is a tablet
	 */
	public isTablet() {
		return [Constants.DEVICES.I_PAD, Constants.DEVICES.FIREFOX_OS].some((item) => {
			return this.device === item;
		});
	}

	/**
	 * @description
	 * Gets the current breakpoint and determines if it is a tablet sized breakpoint
	 *
	 * @returns whether the current device is a mobile breakpoint
	 */
	public isTabletBreakpoint(): boolean {
		const breakpoint: BreakPoint = this.getDeviceBreakPoint();

		return breakpoint === BreakPoint.MEDIUM || breakpoint === BreakPoint.LARGE;
	}

	/**
	 * @description
	 * Compares the current device info with the desktop devices to check
	 * if the current device is a desktop device.
	 *
	 * @returns whether the current device is a desktop device
	 */
	public isDesktop() {
		return [Constants.DEVICES.PS4, Constants.DEVICES.CHROME_BOOK, Constants.DEVICES.UNKNOWN].some((item) => {
			return this.device === item;
		});
	}

	/**
	 * @description
	 * Gets the current breakpoint and determines if it is a desktop sized breakpoint
	 *
	 * @returns whether the current device is a mobile breakpoint
	 */
	public isDesktopBreakpoint(): boolean {
		const breakpoint: BreakPoint = this.getDeviceBreakPoint();

		return breakpoint === BreakPoint.EXTRALARGE;
	}
}
