/** @format */
import { APP_BASE_HREF } from '@angular/common';
import { InjectionToken, Type } from '@angular/core';
import { CommonEngine, CommonEngineOptions } from '@angular/ssr';
import { Request } from 'express-serve-static-core';
import { IncomingMessage } from 'http';
import pino, { Logger, LoggerOptions } from 'pino';
import { IParseOptions, IStringifyOptions } from 'qs';

import { MediaType } from '../enum/media-type.enum';
import { IBSB_CONSTANTS } from './constants-ui.interface';
import { IEnvironmentClient } from './environment.interface';

enum ExpressAppSettings {
	APP_LOG_LEVEL = 'APP_LOG_LEVEL',
	BASE_PAGE_PATH_PATTERNS = 'BASE_PAGE_PATH_PATTERNS',
	BASE_PAGE_PATH_PREFIXES = 'BASE_PAGE_PATH_PREFIXES',
	BASE_PAGE_PATHS = 'BASE_PAGE_PATHS',
	BROWSER_FOLDER = 'BROWSER_FOLDER',
	DEPLOY_URL_FOLDER = 'DEPLOY_URL_FOLDER',
	DIST_FOLDER = 'DIST_FOLDER',
	EXPRESS_APP_OPTIONS = 'EXPRESS_APP_OPTIONS',
	EXPRESS_LOG_LEVEL = 'EXPRESS_LOG_LEVEL',
	HOST_NAME = 'HOST_NAME',
	HTML_MINIFICATION_ENABLED = 'HTML_MINIFICATION_ENABLED',
	HTTPS_ENABLED = 'HTTPS_ENABLED',
	INDEX_TEMPLATE_PATH = 'INDEX_TEMPLATE_PATH',
	INDEX_TEMPLATE_STRING = 'INDEX_TEMPLATE_STRING',
	LOGGER = 'LOGGER',
	MEMORY_CACHING_ENABLED = 'MEMORY_CACHING_ENABLED',
	NAME = 'name',
	PORT = 'PORT',
	PROXY_ENABLED = 'PROXY_ENABLED',
	PROXY_LOG_LEVEL = 'PROXY_LOG_LEVEL',
	PROXY_PATH_PREFIXES = 'PROXY_PATH_PREFIXES',
	PROXIES = 'PROXIES',
	QUERY_PARSER = 'query parser',
	REDIS_CACHE_PREFIX = 'REDIS_CACHE_PREFIX',
	REDIS_CACHING_AVAILABLE = 'REDIS_CACHING_AVAILABLE',
	REDIS_CACHING_ENABLED = 'REDIS_CACHING_ENABLED',
	REDIS_CACHING_RHF_ENABLED = 'REDIS_CACHING_RHF_ENABLED',
	REDIS_CACHING_TTL = 'REDIS_CACHING_TTL',
	REDIS_CONNECT_RETRY_LIMIT = 'REDIS_CONNECT_RETRY_LIMIT',
	REDIS_INSTANCE = 'REDIS_INSTANCE',
	REDIS_SERVICE_NAME = 'REDIS_SERVICE_NAME',
	REQUEST_TIMING_ENABLED = 'REQUEST_TIMING_ENABLED',
	SERVER_FOLDER = 'SERVER_FOLDER',
	VIEW_CACHE = 'view cache',
	VIEW_ENGINE = 'view engine',
	VIEWS = 'views'
}

/**
 * @description
 * Extends the Logger interface so that our custom log levels can be called on the typed Logger
 */
interface IBsbLogger extends Logger {
	advice: pino.LogFn;
	client: pino.LogFn;
	detail: pino.LogFn;
	log: pino.LogFn;
}

interface IBsbLoggerOptions extends LoggerOptions {
	prettyPrint: boolean;
	useLevelLabels: boolean;
}

/**
 * @description
 * Extends http.IncomingMessage interface to allow for additional properties based on typings
 */
interface IBsbProxyIncomingMessage extends IncomingMessage {
	req: IBsbRequest;
}

/**
 * @description
 * Extends the Express.Request interface to allow for additional properties
 */
interface IBsbRequest extends Request {
	basePage: IBasePage;
	cacheKey: string;
	isSafeBot: boolean;
	isKiosk: boolean;
	isBasePageRequest: boolean;
	isCacheable: boolean;
	isPreviewRequest: boolean;
	isProxyRequest: boolean;
	log: IBsbLogger;
	requestStartTime: number;
	shouldPerformServerSideRender: boolean;
	shouldPerformTransferState: boolean;
	shouldRenderReactHeaderFooter: boolean;
	vhIdentifier: string;
	userAgent: string;
}

/**
 * @description
 * Extends the QueryString interface because there are options on the docs page
 * that do not exist in the type definitions
 */
interface IBsbParseOptions extends IParseOptions {
	allowSparse?: boolean;
	indices?: boolean;
	skipNulls?: boolean;
	strictNullHandling?: boolean;
}

/**
 * @description
 * Extends the QueryString interface because there are options on the docs page
 * that do not exist in the type definitions
 */
interface IBsbStringifyOptions extends IStringifyOptions {
	allowSparse?: boolean;
	indices?: boolean;
	skipNulls?: boolean;
	strictNullHandling?: boolean;
}

/**
 * @description
 * Options that are required to create an Express Application for SSR utilizing
 * the CommonEngine
 */
interface IExpressAppOptions {
	appServerModule: Type<{}>; // eslint-disable-line @typescript-eslint/ban-types
	environment: IEnvironmentClient | Partial<IEnvironmentClient>;
	uiConstants: IBSB_CONSTANTS;
	universalAppProviders: {
		APP_BASE_HREF?: typeof APP_BASE_HREF;
		HOST_NAME: InjectionToken<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
		REQUEST: InjectionToken<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
		RESPONSE: InjectionToken<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
		SERVER_PORT: InjectionToken<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
	};
	universalRenderingEngine: typeof CommonEngine;
	universalRenderingOptions: CommonEngineOptions;
}

/**
 * @description
 * Interface describing what a base page configuration looks like
 */
interface IBasePage {
	basePagePathPattern: string | string[];
	basePagePathPrefix: string | string[];
	basePageType: BasePageType;
	cacheKeyParams: string[];
	isCacheable: boolean;
}

/**
 * @description
 * Object describing the page types for comparing values
 */
enum BasePageType {
	HOME = 'home',
	ABOUT_US = 'about-us',
	ACCOUNT = 'account',
	ADMIN = 'admin',
	BLOG = 'blog',
	CONTACT = 'contact',
	FAQ = 'faq',
	MENU = 'menu',
	LEGAL_COOKIES = 'legal-cookies',
	LEGAL_PRIVACY = 'legal-privacy',
	LEGAL_REQUESTS = 'legal-requests',
	LEGAL_TERMS_OF_USE = 'legal-terms-of-use',
	PASSWORD = 'password',
	SAUCE = 'sauce',
	SHOP = 'shop',
	WING_FINDER = 'wing-finder'
}

/**
 * @description
 * Interface describing what a server proxy configuration looks like. A proxy does not
 * need to have its path rewritten and the boolean "stripPath" specifies that behavior.
 * If stripPath is false, then the path will not be rewritten and the request to the target
 * will be made as-is.  If stripPath is true and pathSubstringToStrip is not defined, then the path
 * will be rewritten and the proxy part stripped. If stripPath is true and pathSubstringToStrip is
 * defined and not an empty string, then the path will be rewritten and the proxy part
 * will be replaced by the pathSubstringToStrip.
 */
interface IServerProxy {
	headersToForward?: string[];
	path: string;
	pathContainsFileExtension?: boolean;
	pathSubstringToStrip?: string;
	responseContentType?: string;
	response?: {
		contentType?: MediaType;
		parseFunction?: (...args: unknown[]) => unknown;
	};
	stripPath: boolean;
	target: string;
}

export { BasePageType, ExpressAppSettings, IBasePage, IBsbLogger, IBsbLoggerOptions, IBsbProxyIncomingMessage, IBsbRequest, IBsbParseOptions, IBsbStringifyOptions, IExpressAppOptions, IServerProxy };
