/** @format */
import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { CustomHttpHeaders } from '@bsb/ui/core';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
	constructor(@Inject(PLATFORM_ID) private platformId) {}

	/**
	 * @description
	 * Function that acts as an Interceptor for all HTTP Requests.  It provides
	 * any common required Headers.
	 */
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Default timeout (10s)
		const DEFAULT_TIMEOUT = 10000;

		// Custom timeout
		let customTimeout: number;

		// Defaulted cloned request, in case nothing is modified
		let clonedRequest = request.clone();

		// Cloned headers
		let headers = clonedRequest.headers;

		/*
		 * TODO: HACK UNTIL HTTPCLIENT OPTIONS ARE IMPLEMENTED -- SEE `https://github.com/angular/angular/issues/18155`
		 */
		// Check to see if we need to ignore the `X-TIMEOUT` header
		if (headers.has(CustomHttpHeaders.X_TIMEOUT)) {
			// Set the custom timeout
			customTimeout = parseInt(headers.get(CustomHttpHeaders.X_TIMEOUT), 10);

			// Modify the headers accordingly
			headers = headers.delete(CustomHttpHeaders.X_TIMEOUT);
		}

		/*
		 * TODO: HACK UNTIL HTTPCLIENT OPTIONS ARE IMPLEMENTED -- SEE `https://github.com/angular/angular/issues/18155`
		 */
		// Check to see if we need to ignore the `X-PROXY-REQUEST` header, but only in the browser since there is a universal proxy for SSR handling (this is a client-side fallback)
		if (headers.has(CustomHttpHeaders.X_PROXY_REQUEST) && isPlatformBrowser(this.platformId)) {
			// Modify the headers accordingly
			headers = headers.delete(CustomHttpHeaders.X_PROXY_REQUEST);
		}

		// Clone the Request with new headers
		clonedRequest = clonedRequest.clone({
			headers
		});

		// Send the newly created request
		return next.handle(clonedRequest).pipe(
			timeout(customTimeout || DEFAULT_TIMEOUT),
			catchError((error) => {
				// Check to see if the error is a TimeoutError
				if (typeof error.name === 'string' && error.name.toLowerCase() === 'timeouterror') {
					// Return a timeout specific error with the URL
					return throwError(`TIMEOUT_ERROR: A timeout error has occurred for the URL "${clonedRequest.url}"`);
				} else {
					// Return standard error
					return throwError(error);
				}
			})
		);
	}
}
