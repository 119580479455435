/** @format */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MarketingDao } from '../dao/marketing.dao';

@Injectable()
export class MarketingService {
	// eslint-disable-next-line no-unused-vars
	constructor(private marketingDao: MarketingDao) {}

	public signUpForAudience$(email: string, retries?: number): Observable<boolean> {
		return this.marketingDao.signUpForAudience$(email, retries);
	}
}
