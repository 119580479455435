/** @format */
import { LogLevel } from '@bsb/shared/schema/enum';
import { IEnvironmentClient } from '@bsb/shared/schema/interface';

export const environment: IEnvironmentClient = {
	api: {
		node: {
			baseUrl: 'http://localhost:4040/api/',
			proxy: {
				browser: '/api/',
				server: {
					protocol: 'http://',
					host: 'localhost',
					port: '4040',
					path: '/api/'
				},
				timeout: '1000'
			}
		}
	},
	logging: {
		config: {
			clientLoggerLevel: LogLevel.ERROR,
			xhrLoggerLevel: LogLevel.WARN,
			xhrLoggerUrl: '/util/log/publish'
		},
		http: {
			enabled: false
		}
	},
	production: true,
	server: {
		basePageCacheEnabled: true,
		htmlMinificationEnabled: true,
		logging: {
			application: 'log',
			express: 'client',
			proxy: 'error'
		},
		renderer: {
			performanceProfilerEnabled: false,
			inlineCriticalCssEnabled: true
		},
		requestTimingEnabled: false,
		proxies: {
			bsbApi: {
				path: '/api/',
				target: 'http://localhost:4040',
				headersToForward: undefined,
				pathContainsFileExtension: false,
				pathSubstringToStrip: undefined,
				stripPath: false
			}
		},
		security: {
			cspDirectives: {
				defaultSrc: [
					"'self'",
					'https://ogbsb.imgix.net',
					'https://cdn.shopify.com',
					'https://the-original-big-shot-bobs.myshopify.com',
					'https://fonts.googleapis.com',
					'https://maps.googleapis.com',
					'https://*.google.com',
					'https://*.google-analytics.com',
					'https://*.googletagmanager.com',
					'https://*.googleusercontent.com',
					'https://*.gstatic.com',
					'data:',
					'blob:'
				],
				objectSrc: ["'none'"],
				styleSrc: ["'self'", "'unsafe-inline'", 'https://fonts.googleapis.com', 'https://maps.googleapis.com'],
				scriptSrc: ["'self'", "'unsafe-inline'", 'https://maps.googleapis.com', 'https://*.googletagmanager.com', 'https://*.google-analytics.com']
			},
			featurePolicy: {
				camera: ['self'],
				fullscreen: ['self'],
				microphone: ['self'],
				payment: ['self'],
				syncXhr: ['self']
			}
		}
	},
	serviceWorker: true,
	shopify: {
		domain: 'the-original-big-shot-bobs.myshopify.com',
		storefrontAccessToken: 'fd3b79dacda90eb41cc23d9432552987'
	},
	thirdParty: {
		chowNow: {
			enabled: true,
			companyId: 34187,
			locations: {
				avalon: {
					enabled: true,
					locationId: 52780
				},
				kennedy: {
					enabled: true,
					locationId: 51419
				}
			},
			script: {
				baseUrl: 'https://cf.chownowcdn.com/latest/static/integrations/ordering-modal.min.js'
			}
		},
		googleAnalytics: {
			enabled: true,
			property: 'G-70SFRDBV4M',
			script: {
				baseUrl: 'https://www.googletagmanager.com/gtag/js'
			}
		},
		googleMaps: {
			clientKey: 'AIzaSyAOQeGb0nPzP0D06m35_1-xcn3C1RTvhOE',
			scriptUrl: 'https://maps.googleapis.com/maps/api/js'
		}
	}
};
