/** @format */
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { BSBCommonModule } from '@bsb/ui/common';

import { BlogPostComponent } from './blog-post/blog-post.component';
import { BlogPostsComponent } from './blog-posts/blog-posts.component';
import { BlogRoutingModule } from './blog-routing.module';
import { BlogComponent } from './blog.component';
import { BlogDao } from './blog.dao';
import { BlogService } from './blog.service';

@NgModule({
	declarations: [BlogComponent, BlogPostComponent, BlogPostsComponent],
	imports: [CommonModule, BlogRoutingModule, BSBCommonModule, NgOptimizedImage],
	providers: [BlogDao]
})
export class BlogModule {
	static forRoot(): ModuleWithProviders<BlogModule> {
		return {
			ngModule: BlogModule,
			providers: [BlogService]
		};
	}
}
