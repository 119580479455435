/** @format */
import { Injectable } from '@angular/core';

import { ShopDao } from '../dao/shop.dao';
import { IAttributeInput, ICart, ICheckout, ICheckoutLineItemInput, IProduct, IProductVariant } from '../model/shop.model';
import { BSB_CONSTANTS } from '../properties/bsb-constants';

@Injectable({
	providedIn: 'root'
})
export class ShopService {
	// eslint-disable-next-line no-unused-vars
	constructor(private shopDao: ShopDao) {}

	/**
	 * @description
	 * Adds a Line Item to the Checkout
	 */
	public addLineItemsToCheckout$(checkoutID: string, lineItems: ICheckoutLineItemInput[]): Promise<ICart> {
		return this.shopDao.addLineItemsToCheckout$(checkoutID, lineItems);
	}

	/**
	 * @description
	 * Creates a Checkout
	 */
	public createCheckout$(): Promise<ICheckout> {
		return this.shopDao.createCheckout$();
	}

	/**
	 * @description
	 * Gets a response from Shopify and parses it to return an Array of IProduct objects
	 */
	public getAllProducts$(): Promise<IProduct[]> {
		return this.shopDao.getAllProducts$();
	}

	/**
	 * @description
	 * Iterates the variants and returns only available ones
	 *
	 * @param productVariants
	 */
	public getAvailableVariantsForProduct(productVariants: IProductVariant[]): IProductVariant[] {
		// Return variants
		const availableVariants: IProductVariant[] = [];

		// Ensure there is something to iterate
		if (Array.isArray(productVariants) && productVariants.length > 0) {
			// Iterate the variants
			for (let i = 0, length = productVariants.length; i < length; i += 1) {
				// Get the current variant
				const currentVariant = productVariants[i];

				// Check availability
				if (currentVariant.available) {
					availableVariants.push(currentVariant);
				}
			}
		}

		// Return the available variants
		return availableVariants;
	}

	/**
	 * @description
	 * Iterates the line items and returns only available ones
	 *
	 * @param lineItems
	 */
	public getAvailableLineItemsForCart(lineItems: ICheckoutLineItemInput[]): ICheckoutLineItemInput[] {
		// Return line items
		const availableLineItems: ICheckoutLineItemInput[] = [];

		// Ensure there is something to iterate
		if (Array.isArray(lineItems) && lineItems.length > 0) {
			// Iterate the line items
			for (let i = 0, length = lineItems.length; i < length; i += 1) {
				// Get the current line item
				const currentLineItem = lineItems[i];

				// Check availability
				if (currentLineItem.variant.available || !currentLineItem.variant.available) {
					availableLineItems.push(currentLineItem);
				}
			}
		}

		// Return the available variants
		return availableLineItems;
	}

	/**
	 * @description
	 * Gets a checkout by ID
	 *
	 * @param checkoutID
	 */
	public getCheckoutByID$(checkoutID: string): Promise<ICart> {
		return this.shopDao.getCheckoutByID$(checkoutID);
	}

	/**
	 * @description
	 * Calculates the total number of items in the Cart
	 *
	 * @param cart
	 */
	public getQuantityFromCart(cart: ICart): number {
		// Reset the Cart Quantity to 0 each time there is an update
		let cartQuantity = 0;

		// Check to see if there are any line items
		if (Array.isArray(cart?.lineItems) && cart.lineItems.length > 0) {
			// Iterate the line items
			for (let i = 0, length = cart.lineItems.length; i < length; i += 1) {
				// Increment the quantity of the current line item
				cartQuantity += cart.lineItems[i].quantity;
			}
		}

		// Return the Cart Quantity
		return cartQuantity;
	}

	/**
	 * @description
	 * Calculates an estimated shipping cost from the Cart by looking at the Cart quantity
	 * and doing some math based on how many bottles fit in each size package
	 *
	 * @param cart
	 */
	public getEstimatedShippingFromCart(cart: ICart): number {
		// Estimated shipping, reset to zero
		let estimatedShipping = 0;

		// Get the Cart quantity
		const cartQuantity = this.getQuantityFromCart(cart);

		// Number of bottles
		if (cartQuantity === 0) {
			// Force to zero
			estimatedShipping = 0;
		} else if (cartQuantity <= BSB_CONSTANTS.shopify.shipping.flatRateOptions.small.itemCountMax) {
			estimatedShipping += BSB_CONSTANTS.shopify.shipping.flatRateOptions.small.cost;
		} else if (cartQuantity <= BSB_CONSTANTS.shopify.shipping.flatRateOptions.medium.itemCountMax) {
			estimatedShipping += BSB_CONSTANTS.shopify.shipping.flatRateOptions.medium.cost;
		} else if (cartQuantity <= BSB_CONSTANTS.shopify.shipping.flatRateOptions.large.itemCountMax) {
			estimatedShipping += BSB_CONSTANTS.shopify.shipping.flatRateOptions.large.cost;
		} else if (cartQuantity <= BSB_CONSTANTS.shopify.shipping.flatRateOptions.large.itemCountMax + BSB_CONSTANTS.shopify.shipping.flatRateOptions.small.itemCountMax) {
			estimatedShipping += BSB_CONSTANTS.shopify.shipping.flatRateOptions.large.cost + BSB_CONSTANTS.shopify.shipping.flatRateOptions.small.cost;
		} else if (cartQuantity <= BSB_CONSTANTS.shopify.shipping.flatRateOptions.large.itemCountMax + BSB_CONSTANTS.shopify.shipping.flatRateOptions.medium.itemCountMax) {
			estimatedShipping += BSB_CONSTANTS.shopify.shipping.flatRateOptions.large.cost + BSB_CONSTANTS.shopify.shipping.flatRateOptions.medium.cost;
		} else if (cartQuantity <= BSB_CONSTANTS.shopify.shipping.flatRateOptions.large.itemCountMax + BSB_CONSTANTS.shopify.shipping.flatRateOptions.large.itemCountMax) {
			estimatedShipping += BSB_CONSTANTS.shopify.shipping.flatRateOptions.large.cost + BSB_CONSTANTS.shopify.shipping.flatRateOptions.large.cost;
		}

		// Return the estimated shipping
		return estimatedShipping;
	}

	/**
	 * @description
	 * Calculates the total in the Cart
	 *
	 * @param cart
	 */
	public getTotalFromCart(cart: ICart): number {
		// Cart total
		let cartTotal = 0;

		// Check to see if there is a subtotal
		if (typeof cart.subtotalPrice !== 'undefined') {
			// Check the property model type
			if (typeof cart.subtotalPrice === 'string') {
				// Add the subtotal and the current total
				cartTotal = +cart.subtotalPrice + cartTotal;
			} else {
				// Add the subtotal and the current total
				cartTotal = +cart.subtotalPrice.amount + cartTotal;
			}
		}

		// Check to see if there is any tax
		if (typeof cart.totalTax !== 'undefined') {
			// Check the property model type
			if (typeof cart.totalTax === 'string') {
				// Add the total tax and the current total
				cartTotal = +cart.totalTax + cartTotal;
			} else {
				// Add the total tax and the current total
				cartTotal = +cart.totalTax.amount + cartTotal;
			}
		}

		// Get the Shipping cost
		const shippingCost = this.getEstimatedShippingFromCart(cart);

		// Check to see if there is a Shipping cost
		if (shippingCost > 0) {
			cartTotal += shippingCost;
		}

		// Return the total
		return cartTotal;
	}

	/**
	 * @description
	 * Calls the Shopify API to get a Product in the Shop by handle
	 *
	 * @param handle
	 */
	public getProductByHandle$(handle: string): Promise<IProduct> {
		return this.shopDao.getProductByHandle$(handle);
	}

	/**
	 * @description
	 * Removes Line Items from Checkout
	 */
	public removeLineItemsFromCheckout$(checkoutID: string, lineItemIDs: string[]): Promise<ICart> {
		return this.shopDao.removeLineItemsFromCheckout$(checkoutID, lineItemIDs);
	}

	/**
	 * @description
	 * Updates a Line Item in Checkout
	 */
	public updateLineItemsInCheckout$(checkoutID: string, lineItems: IAttributeInput[]): Promise<ICart> {
		return this.shopDao
			.updateLineItemsInCheckout$(checkoutID, lineItems)
			.then((checkout: ICheckout) => {
				// Check to see if the Checkout is ready
				if (!checkout.ready) {
					/*
					 * The Checkout is not ready, we *have* to poll until the Cart is ready per the Shopify Buy SDK docs...so that means
					 * that we have to return a new Promise and then perform our polling operations within there.
					 *
					 * Linky: https://shopify.dev/docs/api/storefront/2023-04/objects/Checkout#field-checkout-ready
					 */
					return new Promise<any>((resolve, reject) => {
						// Set up an interval to poll the document.readyState value
						const interval = setInterval(() => {
							// Get the Checkout by ID; TODO: Add logic to prevent infinite interval and add a max loop
							this.getCheckoutByID$(checkoutID)
								.then((cart: ICart) => {
									// Check to see if ready is true
									if (cart.ready) {
										// Ready is now true, clear the polling interval
										clearInterval(interval);

										// Resolve the outer Promise
										resolve(cart);
									}
								})
								.catch((error) => {
									// Reject the outer Promise with the error so that it bubbles to the Component for an error message
									reject(error);
								});
						}, 1000);
					});
				}
			})
			.catch((error) => {
				// Just pass the error up to the Component for display
				return error;
			});
	}
}
