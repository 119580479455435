<div class="alert-message d-flex flex-row" [ngClass]="messageLevel" role="alert" *ngIf="displayable">
	<div class="alert-message-text-wrapper">
		<div class="alert-message-heading" *ngIf="messageTitle?.trim() !== '' && messageTitle !== undefined">{{ messageTitle }}</div>
		<span [innerHTML]="messageBody | sanitizeHtml"></span>
		<a class="alert-message-link ml-1 bold underline" [href]="supplementalLink" [target]="supplementalLinkTarget || '_blank'" *ngIf="supplementalLink">{{ supplementalLinkText }}</a>
	</div>
	<div class="alert-message-close" *ngIf="closable">
		<button class="action-icon" (click)="onCloseButtonClick()">
			<i class="ti ti-close"></i>
		</button>
	</div>
</div>
