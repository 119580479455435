<!-- @format -->
<!-- Content -->
<div id="content" class="home">
	<!-- Section: Hero -->
	<section class="section section-main bg-dark dark">
		<img class="d-block object-fit-cover" ngSrc="assets/img/professional/0A8A2012.jpg" alt="Plate of wings from The Original Big Shot Bob's House of Wings" priority fill sizes="100vw" />
		<div class="container v-center">
			<!-- ChowNow integration -->
			<div class="row no-gutters" *ngIf="environment.thirdParty.chowNow.enabled">
				<div id="chow-now" class="col-md-10 push-md-1">
					<app-chow-now></app-chow-now>
				</div>
			</div>

			<div class="row no-gutters mb-4">
				<div class="col-md-10 push-md-1 text-center">
					<h1 class="display-2">
						The <strong class="strongest">Original</strong> Big Shot Bob's House of Wings
					</h1>
					<div class="display-4">
						We do <strong class="strongest">the best wings</strong> in Pittsburgh
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- Section: About -->
	<section class="section section-bg-edge bg-light2 section-home-about">
		<div class="image right col-md-6 push-md-6">
			<img class="d-inline-block object-fit-cover" ngSrc="assets/img/professional/0A8A1767.jpg" alt="Plate of wings from The Original Big Shot Bob's House of Wings" priority="false" fill sizes="(max-width: 768px) 85vw, 50vw" />
		</div>
		<div class="container">
			<div class="col-md-6">
				<h2 class="display-2">Why <strong class="strongest">our</strong> Wings?</h2>
				<!-- Feature -->
				<div class="feature feature-1">
					<div class="feature-icon icon icon-primary">
						<i class="fa fa-trophy"></i>
					</div>
					<div class="feature-content">
						<h3 class="mb-2">Award Winning</h3>
						<p class="mb-0">
							BSBs has taken the cake multiple times over the years, from WPXI to King of the Wing
						</p>
					</div>
				</div>
				<!-- Feature -->
				<div class="feature feature-1">
					<div class="feature-icon icon icon-primary">
						<i class="fa fa-heart"></i>
					</div>
					<div class="feature-content">
						<h3 class="mb-2">Highly Rated</h3>
						<p class="mb-0">
							BSBs is highly rated on both Yelp and Google
						</p>
					</div>
				</div>
				<!-- Feature -->
				<div class="feature feature-1">
					<div class="feature-icon icon icon-primary">
						<i class="fa fa-clock-o"></i>
					</div>
					<div class="feature-content">
						<h3 class="mb-2">Made to Order</h3>
						<p class="mb-0">
							Every order is made <span class="bold">fresh to order</span>, guaranteed
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
<!-- Content / End -->

<!-- Section: Menu Carousel -->
<section class="section cover protrude pull-up-10" *ngIf="menuCategoriesActive && isAtLeastOneMenuCategoryActive && isDoneParsingMenuCategories">
	<div id="menu-carousel" class="menu-carousel carousel inner-controls" [ngClass]="{ unactivated: !menuCarouselImagesAreLoaded }">
		<div class="menu-carousel-slide" *ngFor="let menuCategory of menuCategoriesActive">
			<a class="d-block menu-carousel-slide-image-container" [href]="'menu#menu-category-' + menuCategory._id">
				<img class="object-fit-cover" ngSrc="{{ menuCategory.homeImage }}" [alt]="'A picture of ' + menuCategory.title" [priority]="isMobileBreakPoint" fill sizes="(max-width: 690px) 75vw, (max-width: 991px) 50vw, 25vw" (load)="onCarouselImageLoad()">
				<h2 class="title">{{ menuCategory.title }}</h2>
			</a>
		</div>
	</div>
</section>

<!-- Section: Menu Best Sellers & Weekly Picks -->
<section class="section right bg-light2" *ngIf="bestSellersCategory || weeklyPicksCategory">
	<div class="container">
		<div class="row mb-5 no-gutters justify-content-center best-sellers" *ngIf="bestSellersCategory?.menuItems?.length > 0">
			<div class="col-md-8" role="tablist">
				<h2 class="mb-6 text-center">
					Big Shot Bob's <strong class="strongest">{{ bestSellersCategory?.title }}</strong>
				</h2>
				<!-- Menu Category / Wings -->
				<div id="best-sellers" class="menu-category shadow">
					<div class="menu-category-title" role="tab" *ngIf="bestSellersCategory?.image">
						<img class="object-fit-cover" ngSrc="assets/img/professional/0A8A1850.jpg" alt="{{ bestSellersCategory?.title }}" priority="false" fill sizes="(max-width: 768px) 75vw, 25vw" />
					</div>
					<div id="best-sellers-content" class="menu-category-content">
						<!-- Menu Item -->
						<div class="menu-item menu-list-item bsb-picks" *ngFor="let menuItem of bestSellersCategory.menuItems">
							<div class="row align-items-center">
								<div class="col-sm-12 mb-2 mb-sm-0">
									<div class="display-6">{{ menuItem.title }}</div>
									<span class="d-block text-sm">{{ menuItem.description }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row no-gutters justify-content-center weekly-picks" *ngIf="weeklyPicksCategory?.menuItems?.length > 0">
			<div class="col-md-8" role="tablist">
				<h2 class="mb-6 text-center">
					Big Shot Bob's <strong class="strongest">{{ weeklyPicksCategory?.title }}</strong>
				</h2>
				<!-- Menu Category / Wings -->
				<div id="weekly-picks" class="menu-category shadow">
					<div class="menu-category-title" role="tab" *ngIf="weeklyPicksCategory?.image">
						<img class="object-fit-cover" ngSrc="assets/img/professional/0A8A1805.jpg" alt="{{ weeklyPicksCategory?.title }}" priority="false" fill sizes="(max-width: 768px) 85vw, 25vw" />
					</div>
					<div id="weekly-picks-content" class="menu-category-content">
						<!-- Menu Item -->
						<div class="menu-item menu-list-item bsb-picks" *ngFor="let menuItem of weeklyPicksCategory.menuItems">
							<div class="row align-items-center">
								<div class="col-sm-12 mb-2 mb-sm-0">
									<div class="display-6">{{ menuItem.title }}</div>
									<span class="text-sm">{{ menuItem.description }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Section: Wing Finder -->
<section class="section section-lg dark bg-dark wing-finder-section">
	<div>
		<img class="object-fit-cover" ngSrc="assets/img/professional/0A8A1683.jpg" alt="Close-up of wings from The Original Big Shot Bob's House of Wings" priority="false" fill sizes="100vw" />
	</div>

	<div class="container">
		<div class="row">
			<div class="col-md-6 push-md-3">
				<h2 class="display-2">
					Check our <strong class="strongest">Wing Finder</strong> out!
				</h2>
				<p class="mb-5">
					Find your favorite wings by using our Wing Finder!
				</p>
				<a href="wing-finder" class="btn btn-outline-primary btn-lg"><span>Find your flavor</span></a>
			</div>
		</div>
	</div>
</section>
