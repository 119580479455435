/** @format */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BlogPostComponent } from './blog-post/blog-post.component';
import { BlogPostsComponent } from './blog-posts/blog-posts.component';
import { BlogComponent } from './blog.component';

const routes: Routes = [
	{
		path: 'posts',
		component: BlogComponent,
		children: [
			{ path: '', component: BlogPostsComponent },
			{ path: ':postId', component: BlogPostComponent }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BlogRoutingModule {}
