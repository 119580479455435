/** @format */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { APP_ID, Inject, Injectable, Injector, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { LogService } from '@bsb/ui/core';
import { CustomHttpHeaders } from '@bsb/shared/schema/enum';
import { environment } from '@bsb/ui/env';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
	/**
	 * @description
	 * LogService singleton
	 */
	get logService(): LogService {
		return this.injector.get(LogService);
	}

	constructor(@Optional() @Inject(APP_ID) private appId: string, private injector: Injector) {}

	/**
	 * @description
	 * Function that acts as an Interceptor for all HTTP Requests.  It ensures that
	 * all necessary WCS Auth Tokens are set on every HTTP Request as needed and provides
	 * any common required Headers.
	 */
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Get the start time of the Request
		const started = Date.now();

		// Cloned headers
		let headers = request.headers;

		// Flag for log XHR
		let isLogRequest = false;

		/*
		 * TODO: HEADER HACK UNTIL HTTPCLIENT OPTIONS ARE IMPLEMENTED -- SEE `https://github.com/angular/angular/issues/18155`
		 */
		if (request.headers.has(CustomHttpHeaders.X_LOG_REQUEST)) {
			// Modify the headers accordingly
			headers = headers.delete(CustomHttpHeaders.X_LOG_REQUEST);

			// Set the flag to true
			isLogRequest = true;
		}

		// Clone the Request with new headers
		const clonedRequest = request.clone({
			headers
		});

		// Continue with the cloned request
		return next.handle(clonedRequest).pipe(
			finalize(() => {
				// Check to see if http logging is enabled
				if (environment.logging.http.enabled) {
					// Get the end time of the Request and find the elapsed time
					const elapsed = Date.now() - started;

					const logMessage = `${request.method} ${request.urlWithParams} completed in ${elapsed}ms`;

					// Check to see if the current request is for a log message, do not log these requests
					if (!isLogRequest) {
						this.logService.debug(logMessage);
					}
				}
			})
		);
	}
}
