/** @format */
import { ModuleWithProviders, NgModule } from '@angular/core';

import { MarketingDao } from './dao/marketing.dao';
import { MarketingService } from './service/marketing.service';

@NgModule({
	providers: [MarketingDao, MarketingService]
})
export class MarketingModule {
	static forRoot(): ModuleWithProviders<MarketingModule> {
		return {
			ngModule: MarketingModule,
			providers: [MarketingService]
		};
	}
}
