<!-- Page Content -->
<div class="page-content bg-light">
	<div class="container">
		<div class="row no-gutters">
			<div class="col-lg-10 push-lg-1">
				<!-- Post / Single -->
				<article class="post single mb-0">
					<div class="post-content" *ngIf="blogPost">
						<ul class="post-meta">
							<li>{{ blogPost.date | date : "mediumDate" }}</li>
							<li>by {{ blogPost.author }}</li>
						</ul>
						<h2 class="post-title">{{ blogPost.title }}</h2>
						<hr />
						<div class="post-content-text" [innerHTML]="blogPost.content | sanitizeHtml"></div>
					</div>
				</article>
			</div>
		</div>
	</div>
</div>

