/** @format */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CustomHttpHeaders, UtilityService } from '@bsb/ui/core';

import { AuthService } from '../service/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	/**
	 * @description
	 * AuthService Class.  Initialized manually due to circular dependency issue.
	 */
	private authService: AuthService;

	constructor(private injector: Injector, private utilityService: UtilityService) {}

	/**
	 * @description
	 * Function that acts as an Interceptor for all HTTP Requests.  It ensures that
	 * necessary Auth Token is set on every HTTP Request as needed
	 */
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Cloned headers
		let headers = request.headers;

		// Header flags
		let isAuthRequired = false;

		/*
		 * TODO: HEADER HACK UNTIL HTTPCLIENT OPTIONS ARE IMPLEMENTED -- SEE `https://github.com/angular/angular/issues/18155`
		 */
		if (request.headers.has(CustomHttpHeaders.X_AUTH_REQUIRED)) {
			// Modify the headers accordingly
			headers = headers.delete(CustomHttpHeaders.X_AUTH_REQUIRED);

			// Set the flag
			isAuthRequired = this.utilityService.isTruthy(request.headers.get(CustomHttpHeaders.X_AUTH_REQUIRED));
		}

		/*
		 * CONTINUE FORWARD AFTER REMOVING HEADERS DUE TO CORS RESTRICTIONS
		 */

		// Check to see if auth tokens are required
		if (isAuthRequired) {
			// Inject the AuthService to bypass circular dependency issue, only if needed
			this.authService = this.injector.get(AuthService);

			// Auth Tokens are required, attempt to get them
			const token = this.authService.getAuthToken();

			// Check to see if auth tokens exist
			if (typeof token === 'string' && token.trim() !== '') {
				// Auth tokens exist, set the appropriate headers
				headers = headers.set('Authorization', `Bearer ${token}`);

				// Clone the Request with new headers
				const clonedRequest = request.clone({
					headers
				});

				// Continue with the newly created request
				return next.handle(clonedRequest).pipe(
					// Catch any request errors
					catchError((error: HttpErrorResponse) => {
						// Check to see if error was returned
						if (error.status === 401 || error.status === 403) {
							// Return an authorization error message
							return throwError(`AuthorizationError -- Access is denied for URL '${clonedRequest.urlWithParams}'`);
						}

						// Return an error Observable with a user-facing error message
						return throwError(error);
					})
				);
			} else {
				// Issue getting the token, let the request go through anyway. Clone the Request with new headers
				const clonedRequest = request.clone({
					headers
				});

				// Continue with the newly created request
				return next.handle(clonedRequest);
			}
		} else {
			// Clone the Request with new headers
			const clonedRequest = request.clone({
				headers
			});

			// Continue with the newly created request
			return next.handle(clonedRequest);
		}
	}
}
