/** @format */

/* eslint-disable sonarjs/no-duplicate-string */
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { afterNextRender, AfterRenderPhase, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GuardsCheckEnd, GuardsCheckStart, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

import { BSB_CONSTANTS, CookieService, HttpService, ICart, SeoService, ShopService, UtilityService, WINDOW } from '@bsb/ui/core';
import { MarketingService } from '@bsb/ui/marketing';

import { environment } from '../environments';
import { AppState, AppStateProperties } from './app.state';
import { IBlogPost } from './blog/blog.interface';
import { BlogService } from './blog/blog.service';

declare const BSB_BUILD_VERSION: string;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	@ViewChild('cookieConsentContent')
	private cookieConsentContent: TemplateRef<any>;

	public blogPosts: IBlogPost[];

	public cartQuantity = 0;

	public cartTotal = 0;

	public currentUrl: string;

	public lastUpdatedDate: string;

	public email: string;

	public emailFormSubmitted = false;

	public emailSignupError = false;

	public emailSignupSuccess = false;

	public loading = false;

	public signingUp = false;

	private blogPostLimit = 3;

	private hasCookieConsentOpened = false;

	private cookieConsentModalOptions: NgbModalOptions = {
		backdrop: 'static',
		keyboard: false,
		ariaLabelledBy: 'cookie-consent-title'
	}; // not null!

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private cookieService: CookieService,
		@Inject(DOCUMENT) private document: HTMLDocument,
		@Inject(PLATFORM_ID) protected platformId: any,
		private blogService: BlogService,
		private httpService: HttpService,
		private marketingService: MarketingService,
		private modalService: NgbModal,
		private renderer: Renderer2,
		private router: Router,
		private seoService: SeoService,
		private shopService: ShopService,
		private state: AppState,
		private utilityService: UtilityService,
		@Inject(WINDOW) private window: Window
	) {
		afterNextRender(
			() => {
				this.doDynamicUpdatesAfterHydration();
			},
			{ phase: AfterRenderPhase.Read }
		);
	}

	public ngOnInit() {
		// Subscribe to the router events
		this.router.events.subscribe((event) => {
			if (event instanceof GuardsCheckStart) {
				this.loading = true;
			}

			if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
				this.loading = false;
			}

			// Listen for the NavigationStart event
			if (event instanceof NavigationEnd) {
				// Set the current URL
				this.currentUrl = this.httpService.getUrlPath();

				// Set the current URL in state
				this.state.set(AppStateProperties.CURRENT_URL, `${this.currentUrl}`);
			}
		});

		// Select current URL from the State
		this.state.selectDistinctKey(AppStateProperties.CURRENT_URL, true).subscribe((currentUrl: string) => {
			// Set page SEO
			this.setSeo(currentUrl);
		});

		if (isPlatformBrowser(this.platformId)) {
			/*
			 *  Only do this on the browser
			 */
			// Select Cart from the State
			this.state.select(AppStateProperties.CART, true).subscribe((cart: ICart) => {
				// Set the Cart Quantity
				this.cartQuantity = this.shopService.getQuantityFromCart(cart);

				// Set the Cart Total
				this.cartTotal = this.shopService.getTotalFromCart(cart);

				this.changeDetectorRef.detectChanges();
			});
		}

		// Configure third-parties
		this.configureThirdParties();

		// Check to see if this has been defined
		if (typeof BSB_BUILD_VERSION === 'string' && BSB_BUILD_VERSION.trim() !== '') {
			// Set the build version on the global window
			this.window.BSB_BUILD_VERSION = BSB_BUILD_VERSION;
			this.lastUpdatedDate = BSB_BUILD_VERSION;
		}

		// Get the blog posts to display
		this.blogService.getAllBlogPosts$(true, true).subscribe((blogPosts: IBlogPost[]) => {
			// Ensure there's posts to iterate
			if (Array.isArray(blogPosts) && blogPosts.length > 0) {
				// Initialize the array
				this.blogPosts = [];

				// Iterate the blog posts
				blogPosts.forEach((blogPost, index) => {
					// Check to see if we're at the post limit
					if (index < this.blogPostLimit) {
						// Add to the display list
						this.blogPosts.push(blogPost);
					}
				});
			}
		});
	}

	/**
	 * @description
	 * Signs an email address up for MailChimp
	 *
	 * @param form
	 */
	public onSignupClick(form: NgForm): void {
		// Set the flag to true
		this.emailFormSubmitted = true;

		// Check if the form is valid
		if (form.valid) {
			// Set the flag to true
			this.signingUp = true;

			// Sign the email address up
			this.marketingService
				.signUpForAudience$(form.form.value.email)
				.pipe(
					finalize(() => {
						// Set the flag to false
						this.signingUp = false;
					})
				)
				.subscribe((signedUp: boolean) => {
					this.emailSignupSuccess = signedUp;
				});
		}
	}

	private loadCoreJavaScript(): void {
		/*
		 * Core JS script
		 */
		// Create a script Element for the TagManager script
		const coreScript: HTMLScriptElement = this.document.createElement('script');

		// Set the `type` attribute
		this.renderer.setAttribute(coreScript, 'type', 'text/javascript');

		// Set the `defer` attribute
		this.renderer.setAttribute(coreScript, 'defer', '');

		// Set the `src` attribute
		this.renderer.setAttribute(coreScript, 'src', 'assets/js/core.871f43b46c89a6755ef06a97c00313e0.js');

		// Append to the body
		this.document.body.appendChild(coreScript);
	}

	private configureThirdParties(): void {
		/*
		 * Google Analytics
		 */
		if (environment.thirdParty.googleAnalytics.enabled) {
			/*
			 * TagManager script
			 */
			// Element ID
			const gaTagManagerScriptId = 'gtm-script';

			// Query the DOM for the presence of the script (e.g., ServiceWorker may not cache fully rendered server response)
			const gaTagManagerScriptElement = this.document.getElementById(gaTagManagerScriptId);

			// Check to see if it was present
			if (typeof gaTagManagerScriptElement === 'undefined' || gaTagManagerScriptElement === null) {
				// Create a script Element for the TagManager script
				const gaTagManagerScript: HTMLScriptElement = this.document.createElement('script');

				// Set the `id` attribute
				this.renderer.setAttribute(gaTagManagerScript, 'id', gaTagManagerScriptId);

				// Set the `type` attribute
				this.renderer.setAttribute(gaTagManagerScript, 'type', 'text/partytown');

				// Set the `defer` attribute
				this.renderer.setAttribute(gaTagManagerScript, 'async', '');

				// Set the `src` attribute
				this.renderer.setAttribute(gaTagManagerScript, 'src', `${environment.thirdParty.googleAnalytics.script.baseUrl}?id=${environment.thirdParty.googleAnalytics.property}`);

				// Append the script Element to the head
				this.document.head.appendChild(gaTagManagerScript);
			}

			/*
			 * GA setup
			 */
			// Element ID
			const gaTagSetupScriptId = 'ga-setup-script';

			// Query the DOM for the presence of the script (e.g., ServiceWorker may not cache fully rendered server response)
			const gaTagSetupScriptElement = this.document.getElementById(gaTagSetupScriptId);

			// Check to see if it was present
			if (typeof gaTagSetupScriptElement === 'undefined' || gaTagSetupScriptElement === null) {
				// Template string for GA property setup
				const gaPropertySetupTemplate = `
					window.dataLayer = window.dataLayer || [];
					window.gtag = function() { dataLayer.push(arguments); };
					window.gtag('js', new Date());
					window.gtag('config', '${environment.thirdParty.googleAnalytics.property}');
				`;

				// Create a Text node from the template string
				const inlineScript = this.document.createTextNode(gaPropertySetupTemplate);

				// Create a script Element for the TagManager script
				const gaTagSetupScript: HTMLScriptElement = this.document.createElement('script');

				// Set the `id` attribute
				this.renderer.setAttribute(gaTagSetupScript, 'id', gaTagSetupScriptId);

				// Set the `type` attribute
				this.renderer.setAttribute(gaTagSetupScript, 'type', 'text/partytown');

				// Append the Text node to the script
				this.renderer.appendChild(gaTagSetupScript, inlineScript);

				// Append the script Element to the head
				this.document.head.appendChild(gaTagSetupScript);
			}
		}
	}

	/**
	 * @description
	 * Dynamic updates that do DOM modifications / operations
	 * @private
	 */
	private doDynamicUpdatesAfterHydration(): void {
		// Ensure the Core script has been loaded
		this.utilityService.deferUntilCoreScriptLoads().subscribe((didCoreScriptLoad: boolean) => {
			// Check to see if the script has loaded successfully; NOTE: If the cart panel toggle Element is undefined, the script did not load successfully
			if (didCoreScriptLoad && !this.window.Core.didCoreInit) {
				// Initialize the Core script
				this.window.Core.init();
			}
		});

		// Select current URL from the State
		this.state.selectDistinctKey(AppStateProperties.CURRENT_URL, true).subscribe((currentUrl: string) => {
			// Only do this on the browser
			if (
				isPlatformBrowser(this.platformId) &&
				currentUrl?.toLowerCase() !== '/legal/cookies' &&
				!this.hasCookieConsentOpened &&
				!this.cookieService.check(BSB_CONSTANTS.privacy.cookies.consentDisplay.name)
			) {
				// Open the cookie consent modal
				this.openCookieConsentModal(this.cookieConsentContent, this.cookieConsentModalOptions);

				// Set flag to true
				this.hasCookieConsentOpened = true;
			}
		});
	}

	/**
	 * @description
	 * Opens up a modal to display cookie consent
	 */
	private openCookieConsentModal(textOrTpl: string | TemplateRef<any> | ElementRef, options: NgbModalOptions): void {
		// Open the cookie consent modal
		this.modalService.open(textOrTpl, options).result.then(
			() => {
				// Add the cookie so the modal does open again
				this.cookieService.set(
					BSB_CONSTANTS.privacy.cookies.consentDisplay.name,
					'bacon',
					30,
					BSB_CONSTANTS.privacy.cookies.consentDisplay.path,
					this.httpService.getHostName(),
					BSB_CONSTANTS.privacy.cookies.consentDisplay.secure
				);
			},
			() => {}
		);
	}

	/**
	 * @description
	 * Sets SEO data for the page
	 *
	 * @private
	 */
	private setSeo(url: string): void {
		// Get the SEO object
		const seoObject = BSB_CONSTANTS.seo.meta[url] || BSB_CONSTANTS.seo.meta['default'];

		// Get the full host string
		const fullHostString = this.httpService.getFullHostString();

		// Get the URL path
		let urlPath = this.httpService.getUrlPath();

		// Check if just base path and set as empty string if so
		urlPath = `${urlPath === '/' ? '' : urlPath}`;

		// Get the full URL
		const fullUrl = `${fullHostString.replace('http:', 'https:')}${urlPath}`;

		// Set the page title
		this.seoService.setPageTitle(seoObject.title);

		// Set canonical
		this.seoService.setCanonicalLink(fullUrl);

		// Set meta tags
		this.seoService.addTags([
			{
				name: 'apple-mobile-web-app-title',
				content: `${seoObject.title}`
			},
			{ name: 'description', content: `${seoObject.description}` },
			{ name: 'og:type', content: 'website' },
			{ name: 'og:url', content: `${fullUrl}` },
			{ name: 'og:site_name', content: `${seoObject.name}` },
			{ name: 'og:title', content: `${seoObject.title}` },
			{ name: 'og:description', content: `${seoObject.description}` },
			{
				name: 'og:image',
				content: `${fullHostString}/assets/img/White-Chicken-Blue-Circle-Transparent-400x400.png`
			}
		]);

		// Check if page is secure
		if (seoObject.secure) {
			// Add robots tag for secure pages so they are not indexed
			this.seoService.addTag({
				name: 'robots',
				content: 'noindex, nofollow'
			});
		}
	}
}
